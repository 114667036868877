
import React, { useEffect, useState } from "react";
import "./DataLoader.scss";
import { useNavigate } from "react-router-dom";
import DataLoaderUploadFile from "./DataLoaderUploadFile/DataLoaderUploadFile";
import DataLoaderUploadVoice from "./DataLoaderUploadVoice/DataLoaderUploadVoice";
// import img from '../../../../../public/assets/images/DashboardImages/icons/document.webp'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setKnowId } from "../../../../redux/action";
import {  toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DataLoader = ({ link, knowledgeID, onclose }) => {
  const navigate = useNavigate();
  const [loaderBoxActive, setLoaderBoxActive] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [Question, setQuestion] = useState(null);
  const [Answer, setAnswer] = useState(null);
  const [uploadedData, setUploadedData] = useState(null);
  const [uploadedAudio, setuploadedAudio] = useState(null);
  const [ContainerShow, setContainerShow] = useState(false);
  const [ContainerShowAudio, setContainerShowAudio] = useState(false);
  const [progress, setprogress] = useState(0);
  const [progressAudio, setprogressAudio] = useState(null);
  const dispatch = useDispatch();
  //getting data from Redux store
  // redux values

  const name = useSelector((state) => state.name);
  const file = useSelector((state) => state.file);
  const recordingFile = useSelector((state) => state.recordingFile);
  const load = useSelector((state) => state.load_file);
  // let knowledgeID = useSelector((state) => state.know_Id);

  console.log(`Loading`, knowledgeID);
  //saving the Useselector value
  const [loading, setLoading] = useState(load);

  const handleloaderBoxActive = (active) => {
    if (loading) return;
    setLoaderBoxActive(active);
  };

  const handledataloder = () => {
    if (progress < 100) {
      return toast.warning("File is uploading, please wait until it completes.");
    }
    setLoading(true);
  
    // Create the base payload object
    let payload = {
      ID: knowledgeID,
      Name: name,
    };
  
    // Conditionally add the file URL if it's not empty
    if (file) {
      payload.fileurl = [{ "Url": file }];
    }
  
    // Conditionally add the recording URL if it's not empty
    if (recordingFile) {
      payload.recordingurl = [{ "Url": recordingFile }];
    }
  
    // Send the request with the constructed payload
    axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/knowledege`,
      //  ` https://9fab-2407-d000-1a-9b5f-a053-45f3-53d6-f873.ngrok-free.app/customer/knowledege`,
       payload
       ,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // Handle success
        dispatch(setKnowId(response.data.id));
        setUploadedData("");
        setContainerShow("");
        setprogress(0);
        setuploadedAudio(null);
        setContainerShowAudio("");
        setprogressAudio(0);
        setLoading(false);
  
        if (link) {
          navigate(`${link}`);
        } else {
          onclose();
        }
  
        toast.success("Data uploaded successfully!", { toastId: 1 });
      })
      .catch((error) => {
        // Handle error
        console.log(error);
        toast.error("Please Upload Dataset", { toastId: 1 });
        setLoading(false);
        setUploadedData("");
        setContainerShow("");
        setprogress(0);
        setuploadedAudio(null);
        setContainerShowAudio("");
        setprogressAudio(0);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };
  

  useEffect(() => {
    // console.log("Updated faqs:", faqs);
  }, [faqs]);

  useEffect(() => {
    // console.log("New faqs:", Question,Answer);
  }, [Answer, Question]);

  const handleUploadData = (data) => {
    setUploadedData(data);
  };

  const handleUploadContainerShow = (data) => {
    setContainerShow(data);
  };
  const handleUploadProgress = (data) => {
    // console.log(" UPLOADED fILE ... .. .. .", data);
    setprogress(data);
  };

  const handleUploadAudio = (data) => {
    setuploadedAudio(data);
  };

  const handleUploadContainerShowAudio = (data) => {
    setContainerShowAudio(data);
  };

  const handleUploadProgressAudio = (data) => {
    setprogressAudio(data);
  };

  useEffect(() => {
    setuploadedAudio(null);
    setLoaderBoxActive("");
    setFaqs([]);
    setQuestion(null);
    setUploadedData(null);
    setAnswer(null);
  }, []);


  return (
    <div className="DataLoader-container">
      <h5 className="pb-3">Data Loader</h5>
      <div className="DataLoader-inner-container">
        <div
          className="DataLoader-box"
          onClick={() => handleloaderBoxActive("loaderFileupload")}
        >
          <div className="">
            <div className="flex justify-start gap-3">
              <span className="flex justify-center items-center">
                <img
                  src="../../../../../assets/images/DashboardImages/icons/document.webp"
                  alt=""
                />
              </span>
              <div className="" disabled={loading}>
                <h5>File</h5>
                <p>PDF, CSV, PowerPoint or Word documents</p>
              </div>
            </div>

            {loaderBoxActive === "loaderFileupload" && (
              <div>
                <DataLoaderUploadFile
                  onUpload={handleUploadData}
                  File={uploadedData}
                  ShowContainer={handleUploadContainerShow}
                  Conatiner={ContainerShow}
                  progressfile={handleUploadProgress}
                  progressUp={progress}
                />
              </div>
            )}
          </div>
        </div>
       
        <div
          className="DataLoader-box"
          onClick={() => handleloaderBoxActive("loaderRecoding")}
        >
          <div className="">
            <div className="flex justify-start gap-3">
              <span className="flex justify-center items-center">
                <img
                  src="../../../../../assets/images/DashboardImages/icons/recordcircle.webp"
                  alt=""
                />
              </span>
              <div className="">
                <h5>Recordings</h5>
                <p>Upload in the recordings</p>
              </div>
            </div>

            {loaderBoxActive === "loaderRecoding" && (
              <div>
                <DataLoaderUploadVoice
                  onUpload={handleUploadAudio}
                  File={uploadedAudio}
                  ShowContainer={handleUploadContainerShowAudio}
                  Conatiner={ContainerShowAudio}
                  progressfile={handleUploadProgressAudio}
                  progressUp={progressAudio}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <button
          className="back-btn mt-4"
          onClick={() =>
            {
              if (window.location.pathname === "/KnowledgeLibrary") {
                setuploadedAudio(null);
                setLoaderBoxActive("");
                setFaqs([]);
                setQuestion(null);
                setUploadedData(null);
                setAnswer(null);
                onclose();
                navigate("/KnowledgeLibrary");

                return;
            }else{
              setuploadedAudio(null);
              setLoaderBoxActive("");
              setFaqs([]);
              setQuestion(null);
              setUploadedData(null);
              setAnswer(null);
              navigate(-1)}
            }
          }
        >
          Back
        </button>
        {!loading && (
          <button
            className="theme-btn-light"
            onClick={() => handledataloder()}

            // onClick={() => navigate(`${link}`)}
          >
            Continue
          </button>
        )}
        {loading && (
          <div className="theme-btn-light" disabled>
            {" "}
            Loading...{" "}
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  );
};

export default DataLoader;
