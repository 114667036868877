import React, { useEffect, useRef, useState } from "react";
import "./DataLoaderUploadVoice.scss";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { setRecord, setload } from "../../../../../redux/action";
import axios from "axios";
import { toast } from "react-toastify";

const DataLoaderUploadVoice = ({ onUpload, File , ShowContainer, progressfile,progressUp,Conatiner }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [failedUploads, setFailedUploads] = useState([]);
  const [uploadCanceled, setUploadCanceled] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [currentFileSize, setCurrentFileSize] = useState("");
  const [currentFile, setCurrentFile] = useState("");
  const intervalState = useRef(null);

  const inputRef = useRef(null);

  // Redux values
  const dispatch = useDispatch();

  const handleFile = (files, UploadedFileProgress) => {
    const validFileTypes = [
      "audio/mpeg", // MP3
      "audio/wav",  // WAV
      "audio/ogg",  // OGG
      "audio/x-m4a", // M4A
      "audio/flac" // FLAC
    ];
    const fileList = [...files];
    const filteredFiles = fileList.filter((file) =>
      validFileTypes.includes(file.type)
    );
    if (filteredFiles.length === 0) {
      toast.warning(
        "Please upload valid documents (.pdf, .csv,  .docx, .txt)."
      );
      return;
    }

    console.log("Uploading i am here at handle");
    setSelectedFiles(fileList);
    setCurrentFileName(fileList[0].name);
    const fileSizeMB = (fileList[0].size / (1024 * 1024)).toFixed(2);
    setCurrentFileSize(`${fileSizeMB} MB`);
    fileList.forEach((file) => {
      uploadFile(file, UploadedFileProgress);
    });
  };

  const uploadFile = (file, FileProgress) => {
    const formData = new FormData();
    formData.append("file", file);
    setCurrentFile(file);
    ShowContainer(true)
    // Simulating upload progress
    let progress = FileProgress;
    if (progress === 100) {
      setUploadedFiles((prevState) => [...prevState, file.name]);
      progressfile(progress);
      // console.log("uploaded",showFileNameContainer, file ,uploadedFiles )
      return;
    }
    intervalState.current = setInterval(() => {
      dispatch(setload(null))

      // console.log(
      // "uploaded", FileProgress
      // );
      progress = Math.min(progress + Math.random() * 10, 100);
      progressfile(progress);
      // progressfile(progress)


      if (progress === 100) {
        clearInterval(intervalState.current);
        setUploadedFiles((prevState) => [...prevState, file.name]);
        setCurrentFileName("");
        onUpload({
          name: file.name,
          size: file.size,
          status: "failure",
          type: file.type,
        });
        ShowContainer(false)

      }
    }, 500);

    // Simulating failed upload for some files
    setTimeout(() => {
      if (!uploadCanceled && Math.random() < 0.5) {
        setFailedUploads((prevState) => [...prevState, file.name]);
      }
    }, 2000);
  };

  const cancelUpload = () => {
    clearInterval(intervalState.current);
    intervalState.current = "";
    inputRef.current.value = "";
    setUploadCanceled(false);
    ShowContainer(false)
    progressfile(0);
    setSelectedFiles([]);
    setUploadedFiles([]);
    dispatch(setload(false));
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFile(e.target.files, 0);
    }
  };

  useEffect(() => {
    // console.log(
    //   "i am here to check",
    //   uploadedFiles,
    //   progressUp,
    //   selectedFiles,
    //   intervalState.current
    // );
    // console.log("inputRef.current: ",inputRef.current);
  }, []);

  useEffect(() => {
    if (File) {
      
          // console.log(progressUp, "progressUp.....")
          clearInterval(intervalState.current);
          intervalState.current = "";
          handleFile([File], progressUp);
    }
   
    
  }, [File]);

  useEffect(() => {
    setSelectedFiles([]);
    setUploadedFiles([]);
    setFailedUploads([]);
  }, []);

  useEffect(() => {
    if (selectedFiles.length > 0 && selectedFiles.length) {
      dispatch(setload(true));
      if (progressUp === 100) {
        // console.log(selectedFiles);
        let formData = new FormData();

        // Append the file. Since selectedFiles is a FileList, access the first file as an example.
        formData.append("file", selectedFiles[0]);
        formData.append("fileName", selectedFiles[0].name);
        formData.append("fileType", selectedFiles[0].type);
        formData.append("fileFolderPath", "recording");

        axios
          .post(
            `${process.env.REACT_APP_API_URL2}/customer/aws-upload`,
            // `https://ab6a-2407-d000-1a-d56c-69f2-2da3-4548-c9e0.ngrok-free.app/customer/aws-upload`
            {
              file: selectedFiles[0],
              fileName: selectedFiles[0].name,
              fileType: selectedFiles[0].type,
              fileFolderPath: "recording",
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            //fileurl response
            const data = response.data.file_url;
            // console.log(data);
            // console.log("progressUp 100%");
            dispatch(setload(false));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [selectedFiles, progressUp]);

 



  return (
    <div>
      <div className="import-csv-content v-center flex-column">
        <form
          id="form-file-upload"
          className="v-center flex-column"
          onSubmit={(e) => e.preventDefault()}
        >
          <label id="label-file-upload" htmlFor="input-file-upload">
            <img
              src="../../../assets/images/DashboardImages/images/CircleIconContainer.png"
              alt=""
            />
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              accept=".mp3, .wav, .ogg, .m4a, .flac"
              onChange={handleChange}
            />
            <div>
              <button
                className="text-2xl font-bold button-purple-transparent px-3 py-3"
                onClick={onButtonClick}
              >
                Upload file
                <br />
                <p>Max 20MB .pdf .csv .pptx .docx .txt</p>
              </button>
            </div>
          </label>
        </form>
      </div>

      {Conatiner && (
        <div className="fileName-container">
          <h6 className="current-file-name"></h6>
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="progress">
              <div
                className="progress-bar"
                style={{ width: `${progressUp}%` }}
              ></div>
            </div>
            {progressUp > 0 && progressUp < 100 && (
              <button className="cancel-button" onClick={cancelUpload}>
                Cancel Upload
              </button>
            )}
          </div>
        </div>
      )}

      {selectedFiles.map((file) => (
        <div key={file.name} className="file-progress">
          <div className="flex justify-between items-center gap-2">
            <img src="" alt="" />
          </div>
          {uploadedFiles.includes(file.name) ? (
            <span className="status-icon">
              <Icon
                icon="mdi:tick"
                style={{ color: "#fff", marginTop: "2px" }}
              />
            </span>
          ) : failedUploads.includes(file.name) ? (
            <span className="status-icon status-icon-cancel">
              <Icon
                icon="iconoir:cancel"
                style={{ color: "#fff", marginTop: "2px" }}
              />
            </span>
          ) : null}
        </div>
      ))}
      {File && !Array.isArray(File) && (
        <>
          <p style={{ marginTop: 2, fontSize: 15, fontWeight: 400 }}>
            {" "}
            File Uploaded
          </p>
          <div key={File.name} className="file-progress">
            <div className="flex justify-between items-center gap-2">
              <img
                src="../../../../../../assets/images/DashboardImages/icons/docs.webp"
                alt=""
              />
              <p style={{ color: "#343434", fontSize: "14px" }}>{File.name}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DataLoaderUploadVoice;
