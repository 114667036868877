import React from 'react'
import './AIAssistCard.scss'

const AIAssistCard = ({ ul,img1,img2, h3, p, li1, li2, li3, h3t, pt, li4, li5, li6 }) => {
    return (
        <div className='aIAssist-inner-main-container'>
            <div className='aIAssist-inner-container'>
                <div className='aIAssist-left-box'>
                    <h3>{h3}</h3>
                    <p>{p}</p>
                    {ul === "true" && (
                        <ul>
                            <li><img src='../../../../assets/images/Check.svg.png' alt='' />{li1}</li>
                            <li><img src='../../../../assets/images/Check.svg.png' alt='' />{li2}</li>
                            <li><img src='../../../../assets/images/Check.svg.png' alt='' />{li3}</li>
                        </ul>
                    )}
                </div>
                <div className='aIAssist-right-box'>
                    <img src={img1} alt='' />
                </div>
            </div>
            <div className='aIAssist-inner-container aIAssist-inner-container-reverse'>
                <div className='aIAssist-left-box'>
                    <h3>{h3t}</h3>
                    <p>{pt}</p>
                    {ul === "true" && (
                        <ul>
                            <li><img src='../../../../assets/images/Check.svg.png' alt='' />{li4}</li>
                            <li><img src='../../../../assets/images/Check.svg.png' alt='' />{li5}</li>
                            <li><img src='../../../../assets/images/Check.svg.png' alt='' />{li6}</li>
                        </ul>
                    )}
                </div>
                <div className='aIAssist-right-box'>
                    <img src={img2} alt='' />
                </div>
            </div>
        </div>
    )
}

export default AIAssistCard