// import React, { useState } from "react";
// import "./subscription.scss";
// import { Icon } from "@iconify/react";
// import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// // import img from '../../../../public/assets/images/DashboardImages/icons/bxs_check_circle(2)1.webp'

// const stripePromise = loadStripe(
//  process.env.REACT_APP_API_StripePublishedKey
// );

// const Subscription = () => {
//   const data = [
//     {
//       h3: "Basic",
//       h6: "What You’ll Get",
//       li1: "Chat only",
//       li2: "Up to 250 chats/month",
//       li3: "Limited API access",
//       h5: 100,
//     },
//     {
//       h3: "Mid Tier",
//       h6: "What You’ll Get",
//       li1: "Chat + Voice",
//       li2: "500 chats/month",
//       li3: "500 voice minutes/month",
//       li4: "Full API access",
//       h5: 200,
//     },
//     {
//       h3: "Premium",
//       h6: "What You’ll Get",
//       li1: "Chat + Voice",
//       li2: "1000 chats/month",
//       li3: "1000 voice minutes/month",
//       li4: "Full API access",
//       h5: 350,
//     },
//     {
//       h3: "Enterprise",
//       h6: "What You’ll Get",
//       li1: "Unlimited API access",
//       li2: "Tailored usage limits",
//       li3: "Dedicated support",
//     }
//   ];

//   const [voiceTxt, setVoiceTxt] = useState("voice");
  

//   return (
//     <div className="Subscription-main-container">
//       <h5>Subscription</h5>
//       <p>
//         Choose the subscription plan that works best for you and gain access to
//         advanced features and more credits.
//       </p>

//       <div className="flex items-center gap-5 py-4">
//       <h6 className="font-extrabold">
//       Overage Fees:
//     </h6>
//       <span>
//       Additional $5 for every 50 extra chats or $10 per extra 50 voice minutes.
//       </span>
//       </div>

//       <div className="hr2"></div>

//       <div className="Subscription-tabs-container">
//         <div className="Subscription-tabs-box flex justify-center gap-2">
//           <button className="themeGlow-btn">Monthly</button>
//           <button className="theme-btn-tran">Yearly 2 Months Free</button>
//         </div>

//         <div className="Subscription-card-container">
//           {data.map((item, index) => (
//             <Elements stripe={stripePromise}>
//               <SubscriptionCard
//                 key={index}
//                 h3={item.h3}
//                 h6={item.h6}
//                 li1={item.li1}
//                 li2={item.li2}
//                 li3={item.li3}
//                 li4={item.li4}
//                 h5={item.h5}
//               />
//             </Elements>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Subscription;

import React, { useState, useEffect } from "react";
import "./subscription.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";

const stripePromise = loadStripe(process.env.REACT_APP_API_StripePublishedKey);

const Subscription = () => {
  // Subscription data
  const data = [
    {
      h3: "Basic",
      h6: "What You’ll Get",
      li1: "Chat only",
      li2: "Up to 250 chats/month",
      li3: "Limited API access",
      h5: 100,
      subscriptionType: "Basic",
    },
    {
      h3: "Mid Tier",
      h6: "What You’ll Get",
      li1: "Chat + Voice",
      li2: "500 chats/month",
      li3: "500 voice minutes/month",
      li4: "Full API access",
      h5: 200,
      subscriptionType: "Mid Tier",
    },
    {
      h3: "Premium",
      h6: "What You’ll Get",
      li1: "Chat + Voice",
      li2: "1000 chats/month",
      li3: "1000 voice minutes/month",
      li4: "Full API access",
      h5: 350,
      subscriptionType: "Premium",
    },
    {
      h3: "Enterprise",
      h6: "What You’ll Get",
      li1: "Unlimited API access",
      li2: "Tailored usage limits",
      li3: "Dedicated support",
      subscriptionType: "Enterprise",
    },
  ];

  const [customerSubscription, setCustomerSubscription] = useState(""); // Updated to use state
  const [loading, setLoading] = useState(true);

  // Fetch customer subscription details when component mounts
  const fetchCustomerData = async () => {
    try {
      // Assuming customer ID is available in local storage or passed via props
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL2}/customer/getCustomerById`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Setting content type as JSON
            Authorization: `Bearer ${token}`, // Passing token in Authorization header
          },
          body: JSON.stringify({}), // Since you're sending an empty body with POST request
        }
      );

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json(); // Parse the JSON response
      console.log(responseData);
      const { BillingSubscription } = responseData;

      console.log("Customer Subscription", BillingSubscription);
      setCustomerSubscription(BillingSubscription); // Update state with customer subscription
      setLoading(false); // Set loading to false once data is loaded
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Subscription-main-container">
      <h5>Subscription</h5>
      <p>
        Choose the subscription plan that works best for you and gain access to
        advanced features and more credits.
      </p>

      <div className="flex items-center gap-5 py-4">
        <h6 className="font-extrabold">Overage Fees:</h6>
        <span>
          Additional $5 for every 50 extra chats or $10 per extra 50 voice
          minutes.
        </span>
      </div>

      <div className="hr2"></div>

      <div className="Subscription-tabs-container">
        <div className="Subscription-tabs-box flex justify-center gap-2">
          <button className="themeGlow-btn">Monthly Plans</button>
         {
          //  <button className="theme-btn-tran">Yearly 2 Months Free</button>
         }
        </div>

        <div className="Subscription-card-container">
          {data.map((item, index) => (
            <Elements key={index} stripe={stripePromise}>
              <SubscriptionCard
                key={index}
                h3={item.h3}
                h6={item.h6}
                li1={item.li1}
                li2={item.li2}
                li3={item.li3}
                li4={item.li4}
                h5={item.h5}
                // Highlight the active subscription using state
                active={customerSubscription === item.subscriptionType}
              />
            </Elements>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
