import React, { useRef } from "react";
import "./Benefits.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Testimonial from "../testimonial";
import slider1 from "../../../../../src/images/sliderImg1.webp";
import slider2 from "../../../../../src/images/sliderImg2.png";
import slider3 from "../../../../../src/images/sliderImg3.png";
import img1 from "../../../../../src/images/image992.webp";
import arrowLeft from "../../../../../src/images/arrowLeft.png";

const Benefits = () => {
  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const newData = [
    {
      key: 1,
      img: img1,
      p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus neque tincidunt nulla porttitor ultricies. Sed dapibus, elit quis bibendum suscipit, ligula justo ullamcorper ligula, in malesuada ante mauris ut purus. Aliquam ac tortor tincidunt, varius nisl vitae, volutpat risus. Donec imperdiet tortor non erat efficitur, vitae scelerisque diam congue. Curabitur mi sapien, pellentesque eget volutpat eu, feugiat ut ipsum. Etiam scelerisque",
    },
    {
      key: 2,
      img: img1,
      p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus neque tincidunt nulla porttitor ultricies. Sed dapibus, elit quis bibendum suscipit, ligula justo ullamcorper ligula, in malesuada ante mauris ut purus. Aliquam ac tortor tincidunt, varius nisl vitae, volutpat risus. Donec imperdiet tortor non erat efficitur, vitae scelerisque diam congue. Curabitur mi sapien, pellentesque eget volutpat eu, feugiat ut ipsum. Etiam scelerisque",
    },
    {
      key: 3,
      img: img1,
      p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus neque tincidunt nulla porttitor ultricies. Sed dapibus, elit quis bibendum suscipit, ligula justo ullamcorper ligula, in malesuada ante mauris ut purus. Aliquam ac tortor tincidunt, varius nisl vitae, volutpat risus. Donec imperdiet tortor non erat efficitur, vitae scelerisque diam congue. Curabitur mi sapien, pellentesque eget volutpat eu, feugiat ut ipsum. Etiam scelerisque",
    },
    {
      key: 4,
      img: img1,
      p: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus neque tincidunt nulla porttitor ultricies. Sed dapibus, elit quis bibendum suscipit, ligula justo ullamcorper ligula, in malesuada ante mauris ut purus. Aliquam ac tortor tincidunt, varius nisl vitae, volutpat risus. Donec imperdiet tortor non erat efficitur, vitae scelerisque diam congue. Curabitur mi sapien, pellentesque eget volutpat eu, feugiat ut ipsum. Etiam scelerisque",
    },
  ];

  return (
    <div className="benefits-main-container">
      <div className="benefits-centered-container">
        {/* <h2>Testimonials</h2> */}
        <div className="flex justify-between items-center setFlex gap-8 benefit-inner">
          <div className="benefits-leftSilder-box">
            <Slider ref={sliderRef} {...settings} className="silder-comp">
              {newData.map((card) => (
                <div className="testemonials-card-box" key={card.key}>
                  <img src={card.img} alt="testimonial" />
                  <p>{card.p}</p>
                </div>
              ))}
            </Slider>
            <div className="d-none gap-3 mt-8 arrow-slidermobil-btns">
              <button className="prev-btn" onClick={handlePrev}>
                <img src={arrowLeft} alt="leftarrow" />
              </button>
              <button className="next-btn" onClick={handleNext}>
                <img src={arrowLeft} alt="leftarrow" />
              </button>
            </div>
          </div>
          <div className="benefits-rightTxt-box">
            <h5>Testimonials</h5>
            <h3>Real Stories and Real Success</h3>
            <div className="flex gap-3 mt-8 arrow-slider-btns">
              <button className="prev-btn" onClick={handlePrev}>
                <img src={arrowLeft} alt="leftarrow" />
              </button>
              <button className="next-btn" onClick={handleNext}>
                <img src={arrowLeft} alt="leftarrow" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
