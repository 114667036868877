import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import HeadingSection from "../../components/heading/headingSection";
import "./integrations.scss";
import smartImg from "../../../src/images/integration/smart.png";
import zapierImg from "../../../src/images/integration/zapier.png";
import { NavLink, useNavigate } from "react-router-dom";
import DryCleaning from "../Fabicare/DryCleaning/DryCleaning";
import img from '../../images/image1021.webp'
import Availability from "../Home/HomeSections/Availability/Availability";

import axios from "axios";
const Integrations = () => {
  const navigate = useNavigate()



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="integrationWrapper">
        <DryCleaning img={img} h5='Integrations' h3='Seamlessly Connect: Elevate Your Service With Integrations' p='Unlock endless possibilities by integrating our platform with your existing software, ensuring a streamlined workflow and enhanced customer experience.' />
        {/* <HeadingSection
          main="Integrations"
          sub="Seemlessly Connect: Elevate Your Service With Integrations"
          paragraph="Unlock endless possibilities by integrating our platform with your existing software, ensuring a streamlined workflow and enhanced customer experience."
          btnclass="demoo"
        /> */}
        <div className="inte-card-Container">
          <div className="integrationCard">
            <img src={smartImg} alt="" />
            <h6>Integration with SMRT Systems</h6>
            <p>
              Directly integrate with SMRT Systems for an unmatched efficiency
              in managing your dry cleaning and laundry services. Experience
              seamless data synchronization for orders, customer management, and
              billing.
            </p>
            <h6>Benefits</h6>
            <p>
              Enhance operational efficiency, reduce errors, and provide
              real-time updates to your customers.
            </p>
          </div>
          <div className="integrationCard">
            <img src={zapierImg} alt="" />
            <h6>Zapier Connectivity</h6>
            <p>
              Leverage the power of Zapier to connect our platform with over
              3,000 apps, automating workflows and data exchange across your
              entire software ecosystem."
            </p>
            <h6>Benefits</h6>
            <p>
              Automate repetitive tasks, ensure data consistency, and create
              custom workflows that fit your unique business needs.
            </p>
          </div>
        </div>
        <div className="tailoredSol">
          <h6>Need a tailored solution?</h6>
          <p className="mediumText">
            Our team is here to help you create custom integrations that
            perfectly align with your business requirements.
          </p>
          <p className="smallText">
            Reach out to our support team for personalized assistance with your
            integration needs.
          </p>
          {/* <NavLink to="/demo">
            <button className="demoBtn">Request Demo</button>
          </NavLink> */}
        </div>
      </div>
      <Availability />
      <Footer />
    </>
  );
};

export default Integrations;
