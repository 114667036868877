

import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Controls,
  reconnectEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./customNode"; // Ensure the correct path to your custom node component
import CustomEdge from "./CustomEdge"; // Ensure the correct path to your custom edge component
import axios from "axios";
import "./custom.css";
import dagre from "dagre";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.width || 172,
      height: node.height || 36,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    node.position = {
      x: nodeWithPosition.x - node.width / 2,
      y: nodeWithPosition.y - node.height / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const FlowChart = () => {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [id, setId] = useState(10);
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [Loading, SetLoading] = useState(true);
  const[State,setState] = useState(false);
  const { screenToFlowPosition } = useReactFlow();
  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();
  const edgeReconnectSuccessful = useRef(true);


  const onReconnectEnd = useCallback((_, edge) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeReconnectSuccessful.current = true;
  }, []);


  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge, newConnection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, []);




  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/GenerateAgentFlow`,
        // `https://678f-2407-d000-1a-2b4f-c5b4-4ff1-4c8d-27e5.ngrok-free.app/customer/GenerateAgentFlow`, 
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        SetLoading(false);
        setNodes((nds) =>
          nds.concat(
            response.data.chat.nodes.map((node) => ({
              ...node,
              type: "custom",
              data: {
                ...node.data,
                onLabelChange: handleLabelChange,
              },
            }))
          )
        );
        setEdges((eds) =>
          eds.concat(
            response.data.chat.edges.map((edge) => ({
              ...edge,
              type: "custom",
              label: edge.label, // Initial load, no label
              data: { onLabelChange: handleEdgeLabelChange },
            }))
          )
        );
        setId(getLastNodeId(response.data.chat.nodes));
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  }, []);

  const getLastNodeId = (nodes) => {
    if (nodes.length === 0) return 0; // Check if the array is empty
    const lastNode = nodes[nodes.length - 1];
    return parseInt(lastNode.id, 10) + 1;
  };

  const getId = () => {
    const newId = id;
    setId(id + 1);
    return `${newId}`;
  };

  const determineLabel = (sourceId) => {
    const previousEdge = edges.find((edge) => edge.target === sourceId);
    console.log("previousEdge:", previousEdge); // Proper logging
    return previousEdge && previousEdge.label === "answer" ? "question" : "answer";
  };

  const onConnect = useCallback(
    (params) => {
      connectingNodeId.current = null;

      const label = determineLabel(params.source);

      setEdges((eds) =>
        addEdge(
          {
            ...params,
            label: params.label || label,
            type: "custom",
            data: { onLabelChange: handleEdgeLabelChange },
          },
          eds
        )
      );
    },
    [setEdges, nodes, edges]
  );

  const handleLabelChange = (id, label) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, label } } : node
      )
    );
  };

  const handleEdgeLabelChange = (id, label) => {
    setEdges((eds) =>
      eds.map((edge) => (edge.id === id ? { ...edge, label } : edge))
    );
  };

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains("react-flow__pane");
      const label = determineLabel(connectingNodeId.current);
      if (targetIsPane) {
        const newId = getId();
        const newNode = {
          id: newId,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: { label: `${label}:`, onLabelChange: handleLabelChange },
          type: "custom",
        };

      

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({
            id: `e${newId}`,
            source: connectingNodeId.current,
            target: newId,
            label: label, // New edge with determined label
            type: "custom",
            data: { onLabelChange: handleEdgeLabelChange },
          })
        );
      }
    },
    [screenToFlowPosition, setNodes, setEdges, getId, determineLabel]
  );

  const onLayout = useCallback(
    (direction) => {
      const layouted = getLayoutedElements(nodes, edges, direction);

      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [nodes, edges, fitView]
  );

  const onsave = () => {
    axios.post(`${process.env.REACT_APP_API_URL2}/customer/updateagentflow/${params.id}`, {
      edges: edges,
      nodes: nodes
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((response) => {
      toast.success("AgentFlow saved successfully", { toastId: "generateFlowSuccess" });
      navigate(-1);
    }).catch((error) => {
      toast.error("Failed to save data");
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    });
  };

  const loaderStyle = {
    border: '4px solid #f3f3f3', // Light grey
    borderTop: '4px solid #3498db', // Blue
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    animation: 'spin 2s linear infinite'
  };

  const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height to center vertically
  };
 
  return (
    <div
      className="wrapper"
      ref={reactFlowWrapper}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "80vw",
        backgroundColor: "white",
        marginTop: "20px",
      }}
    >
      {!Loading ? (
        <>
        <div className="flow" style={{ color: "black" }}>
        <button className="themeGlow-btn" onClick={onsave}>
        Save
        </button>
        
        <p style={{marginTop:"20px", fontSize:"20"}}>Guidelines</p>
        <p> 1. Use Backspace for Deletion of Node or Edge</p>
        <p> 2. If defualt flow is delted please refresh the page</p>
        <p> 3. For saving any change click on the SAVE button </p>
        </div>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onReconnect={onReconnect}
            onReconnectStart={onReconnectStart}
            onReconnectEnd={onReconnectEnd}
            fitView
            snapToGrid
            fitViewOptions={{ padding: 2 }}
            nodeOrigin={[0.5, 0]}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
          >
            <Controls className="control-buttons">
              <button className="control-button" onClick={() => onLayout("TB")}>
                Vertical Layout
              </button>
              <button className="control-button" onClick={() => onLayout("LR")}>
                Horizontal Layout
              </button>
            </Controls>
          </ReactFlow>
        </>
      ) : (
        <div style={loaderContainerStyle}>
          <div style={loaderStyle}></div>
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <FlowChart />
  </ReactFlowProvider>
);
