import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { Icon } from "@iconify/react";
import { Button, Drawer } from "antd";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import IndustriesTabs from "./IndustriesTabs/IndustriesTabs";
import logo from "../../../src/images/logoFabric.svg";

const Navbar = () => {
  const [solutionShow, setsolutionShow] = useState(false);
  const [platformshow, setplatformshow] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [activeTab, setActiveTab] = useState("");
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showsolSubmenu, setShowsolSubmenu] = useState(false);
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  const handleplatformShow = () => {
    setplatformshow(true);
  };

  const handleplatformClose = () => {
    setplatformshow(false);
  };

  const handleSolutionShow = () => {
    setsolutionShow(true);
  };
  const handleSolutionClose = () => {
    setsolutionShow(false);
  };

  // const handleplatformShow = () => {
  //   if (platformshow && !solutionShow) {
  //     setplatformshow(false);
  //   } else {
  //     setplatformshow(true);
  //     setsolutionShow(false);
  //   }
  // };

  // const handleplatformClose = () => {
  //   if (!platformshow && !solutionShow) {
  //     setplatformshow(true);
  //   } else {
  //     setplatformshow(false);
  //     setsolutionShow(true);
  //   }
  //   // setplatformshow(false);
  // };

  // const handleSolutionShow = () => {
  // if (solutionShow && !platformshow) {
  //   setsolutionShow(false);
  // } else {
  //   setsolutionShow(true);
  //   setplatformshow(false);
  // }
  // };
  // const handleSolutionClose = () => {
  // if (!solutionShow && !platformshow) {
  //   setsolutionShow(true);
  // } else {
  //   setsolutionShow(false);
  //   setplatformshow(true);
  // }
  // setsolutionShow(false);
  //
  // };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleTabHover = (tab) => {
    setActiveTab(tab);
  };
  const handleTabLeave = () => {
    setActiveTab("");
  };

  const handleNavigation = (link) => {
    navigate(link);
    setShow(false);
  };
  const handleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };
  const handlesolutionSubmenu = () => {
    setShowsolSubmenu(!showsolSubmenu);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowWidth > 600) {
      onClose();
    }
  }, [windowWidth]);

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-inner-section">
          <div className="logo-container">
            <NavLink class="navbar-brand" to="/">
              <img src={logo} alt="" />
            </NavLink>
          </div>
          <div className="menu-container">
            <ul>
              <li>
                <NavLink exact to="/" activeClassName="active">
                  Home
                </NavLink>
              </li>

              <div
                style={{ position: "relative" }}
                onMouseEnter={() => handleplatformShow()}
                onMouseLeave={() => handleplatformClose()}
              >
                <li
                  className={`li-platform cursor-pointer ${
                    platformshow ||
                    location.pathname === "/platform" ||
                    location.pathname === "/work" ||
                    location.pathname === "/choose-us" ||
                    location.pathname === "/integrations"
                      ? "activeClass"
                      : ""
                  }`}
                >
                  <a className="v-center gap-1">
                    {" "}
                    Platform
                    <Icon
                      color="#FFF"
                      icon="mingcute:down-fill"
                      className="icon-rotate"
                    />
                  </a>
                </li>
                {platformshow && (
                  <div className="platform-main-outer">
                    <div className="platform-hover-container d-flex flex-column">
                      <NavLink to="/platform">
                        <div className="platform-items d-flex space-between ">
                          <div className="left d-flex v-center">
                            <img
                              src="../../../assets/images/platformicon.svg"
                              alt="Custom Icon 1"
                            />
                            <h6>Platform Overview</h6>
                          </div>

                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="right-cursor"
                          />
                        </div>
                      </NavLink>
                      <NavLink to="/work">
                        <div className="platform-items d-flex space-between ">
                          <div className="left d-flex v-center">
                            {" "}
                            <img
                              src="../../../assets/images/worksicon.svg"
                              alt="Custom Icon 1"
                            />
                            <h6>How it Works</h6>
                          </div>

                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="right-cursor"
                          />
                        </div>
                      </NavLink>
                      <NavLink to="/choose-us">
                        <div className="platform-items d-flex space-between ">
                          <div className="left d-flex v-center">
                            <img
                              src="../../../assets/images/chooseusicon.svg"
                              alt="Custom Icon 2"
                            />
                            <h6>Why Choose Us</h6>
                          </div>
                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="right-cursor"
                          />
                        </div>
                      </NavLink>
                      <NavLink to="/integrations">
                        <div className="platform-items d-flex space-between ">
                          <div className="left d-flex v-center">
                            <img
                              src="../../../assets/images/integicon.svg"
                              alt="Custom Icon 2"
                            />
                            <h6>Integration</h6>
                          </div>
                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="right-cursor"
                          />
                        </div>
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{ position: "relative" }}
                onMouseEnter={() => handleSolutionShow()}
                onMouseLeave={() => handleSolutionClose()}
              >
                <li
                  className={`li-solutions cursor-pointer ${
                    solutionShow || location.pathname === "/fabicare"
                      ? "activeClass"
                      : ""
                  }`}
                >
                  <a className="v-center gap-1">
                    {" "}
                    Solutions
                    <Icon
                      color="#FFF"
                      icon="mingcute:down-fill"
                      className="icon-rotate"
                    />
                  </a>
                </li>
                {solutionShow && (
                  <div className="solutions-hover-container ">
                    {/* <div className="solutionsmenu-left-box">
                      <h5>Meet your work companion</h5>

                      <div className="solutions-meet-box flex gap-3">
                        <div className="solutions-meetImg-box">
                          <img
                            src="../../../assets/images/wepik-export-20240217071646X7T4 (1) 1.png"
                            alt=""
                          />
                        </div>
                        <div className="solutions-meetTxt-box">
                          <p>
                            Transform contact center experiences and exceed
                            customer expectations with fully pre-trained AI
                            Agents
                          </p>
                          <h6>Explore AI Agents</h6>
                        </div>
                      </div>

                      <div className="solutions-contact-box">
                        <h6>By contact center use case</h6>
                        <ul>
                          <li>
                            <img
                              src="../../../assets/icons/telephone-call_3059502 1.png"
                              alt=""
                            />{" "}
                            Intelligent Routing
                          </li>
                          <li>
                            <img
                              src="../../../assets/icons/self-service_4923080 1.png"
                              alt=""
                            />
                            Smart self-service
                          </li>
                          <li>
                            <img
                              src="../../../assets/icons/call-center_4826754 1.png"
                              alt=""
                            />
                            agent assist
                          </li>
                        </ul>
                      </div>
                    </div> */}

                    <div className="solutionsmenu-right-box">
                      <h5>Industries we Target</h5>

                      <div className="Industries-options-conatainer">
                        <div
                          className="Industries-options-box"
                          onMouseEnter={() => handleTabHover("drycleaning")}
                          onMouseLeave={handleTabLeave}
                          onClick={() => setsolutionShow(false)}
                        >
                          <NavLink
                            to="/fabicare"
                            className="d-flex v-center w-100 space-between"
                          >
                            <div className="left d-flex v-center gap-2">
                              <img
                                src="../../../assets/images/drycleanIcon.svg"
                                alt="Custom Icon 2"
                              />
                              <h6>Dry Cleaning</h6>
                            </div>
                            <img
                              src="../../../assets/icons/Vector.png"
                              alt=""
                              className="sol-right-icon"
                            />
                          </NavLink>
                        </div>
                        <div className="gradient-line"></div>
                        <div
                          className="Industries-options-box"
                          onMouseEnter={() => handleTabHover("lifeinsurance")}
                          onMouseLeave={handleTabLeave}
                        >
                          <div className="left d-flex v-center gap-2">
                            <img
                              src="../../../assets/images/lifeInsIcon.svg"
                              alt="Custom Icon 2"
                            />
                            <h6>Life Insurance</h6>
                          </div>
                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="sol-right-icon"
                          />
                        </div>
                        <div
                          className="Industries-options-box"
                          onMouseEnter={() => handleTabHover("automobile")}
                          onMouseLeave={handleTabLeave}
                        >
                          <div className="left d-flex v-center gap-2">
                            <img
                              src="../../../assets/images/automobileIcon.svg"
                              alt="Custom Icon 2"
                            />
                            <h6>Auto Mobile</h6>
                          </div>

                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="sol-right-icon"
                          />
                        </div>
                        <div
                          className="Industries-options-box"
                          onMouseEnter={() => handleTabHover("education")}
                          onMouseLeave={handleTabLeave}
                        >
                          <div className="left d-flex v-center gap-2">
                            <img
                              src="../../../assets/images/educationIcon.svg"
                              alt="Custom Icon 2"
                            />
                            <h6>Education</h6>
                          </div>

                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="sol-right-icon"
                          />
                        </div>
                        <div
                          className="Industries-options-box"
                          onMouseEnter={() => handleTabHover("transportation")}
                          onMouseLeave={handleTabLeave}
                        >
                          <div className="left d-flex v-center gap-2">
                            <img
                              src="../../../assets/images/transportIcon.svg"
                              alt="Custom Icon 2"
                            />
                            <h6>Transportation</h6>
                          </div>

                          <img
                            src="../../../assets/icons/Vector.png"
                            alt=""
                            className="sol-right-icon"
                          />
                        </div>
                      </div>
                    </div>

                    {/* {activeTab === "drycleaning" && (
                      <IndustriesTabs
                        h5="Dry Cleaning"
                        p="Dry cleaning is a cleaning process that uses a chemical solvent instead of water. This method is effective for cleaning delicate fabrics that may be damaged by traditional washing methods. The solvent used in dry cleaning dissolves stains and dirt, leaving clothes clean without shrinking or fading. Dry cleaning is a popular choice for garments such as suits, dresses, and other items that require special care."
                      />
                    )}
                    {activeTab === "lifeinsurance" && (
                      <IndustriesTabs
                        h5="Life Insurance"
                        p="Dry cleaning is a cleaning process that uses a chemical solvent instead of water. This method is effective for cleaning delicate fabrics that may be damaged by traditional washing methods. The solvent used in dry cleaning dissolves stains and dirt, leaving clothes clean without shrinking or fading. Dry cleaning is a popular choice for garments such as suits, dresses, and other items that require special care."
                      />
                    )}
                    {activeTab === "automobile" && (
                      <IndustriesTabs
                        h5="Auto Mobile"
                        p="Dry cleaning is a cleaning process that uses a chemical solvent instead of water. This method is effective for cleaning delicate fabrics that may be damaged by traditional washing methods. The solvent used in dry cleaning dissolves stains and dirt, leaving clothes clean without shrinking or fading. Dry cleaning is a popular choice for garments such as suits, dresses, and other items that require special care."
                      />
                    )}
                    {activeTab === "education" && (
                      <IndustriesTabs
                        h5="Education"
                        p="Dry cleaning is a cleaning process that uses a chemical solvent instead of water. This method is effective for cleaning delicate fabrics that may be damaged by traditional washing methods. The solvent used in dry cleaning dissolves stains and dirt, leaving clothes clean without shrinking or fading. Dry cleaning is a popular choice for garments such as suits, dresses, and other items that require special care."
                      />
                    )}
                    {activeTab === "transportation" && (
                      <IndustriesTabs
                        h5="Transportation"
                        p="Dry cleaning is a cleaning process that uses a chemical solvent instead of water. This method is effective for cleaning delicate fabrics that may be damaged by traditional washing methods. The solvent used in dry cleaning dissolves stains and dirt, leaving clothes clean without shrinking or fading. Dry cleaning is a popular choice for garments such as suits, dresses, and other items that require special care."
                      />
                    )} */}
                  </div>
                )}
              </div>

              <li>
                <NavLink to="/pricing" activeClassName="active">
                  Pricing
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/" activeClassName="active">
                  Integrations
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/demo" activeClassName="active">
                  Demo
                </NavLink>
              </li> */}
            </ul>
          </div>
          <NavLink to="/demo">
            <div className="navbarbtn-container">
              <button onClick={() => navigate("/demo")}>Request a Demo</button>
            </div>
          </NavLink>
        </div>
      </div>

      <div className="mobile-navbar">
        <NavLink to="/">
          <img src="../../../assets/Logo.svg" alt="" className="mobileLogo" />
        </NavLink>
        <Button
          onClick={showDrawer}
          className="menu-btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <Icon
            color="black"
            icon="material-symbols:menu"
            className="menu-btn-icon"
          />
        </Button>
        <Drawer
          placement={placement}
          closable={false}
          onClose={onClose}
          open={open}
          key={placement}
          width="80%"
          className="mobile-menu-drawer"
        >
          <img src="../../../assets/Logo.svg" alt="" className="logostyle" />

          <ul className=" mt-5">
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Home
              </NavLink>
            </li>
            <NavLink>
              {" "}
              <li
                className={` ${
                  location.pathname === "/platform" ||
                  location.pathname === "/work" ||
                  location.pathname === "/choose-us" ||
                  location.pathname === "/integrations"
                    ? "activeClass"
                    : ""
                }`}
              >
                <a onClick={handleSubmenu} className="v-center mt-3">
                  Platform
                  <Icon
                    icon="mingcute:down-line"
                    color="#4B2E83"
                    width="20"
                    height="20"
                    className="ms-2"
                  />
                </a>
              </li>
            </NavLink>

            {showSubmenu ? (
              <>
                <ul className="mobil-dropdown ">
                  <li>
                    <NavLink
                      className="d-flex gap-2"
                      exact
                      to="/platform"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/platformicon.svg"
                        alt="Custom Icon 1"
                      />
                      Platform Overview
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className="d-flex gap-2"
                      exact
                      to="/work"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/worksicon.svg"
                        alt="Custom Icon 1"
                      />
                      How it Works
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-flex gap-2"
                      exact
                      to="/choose-us"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/chooseusicon.svg"
                        alt="Custom Icon 1"
                      />
                      Why Choose Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-flex gap-2"
                      exact
                      to="/integrations"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/integicon.svg"
                        alt="Custom Icon 1"
                      />
                      Integration
                    </NavLink>
                  </li>
                </ul>
              </>
            ) : (
              <></>
            )}
            <li
              className={` ${
                location.pathname === "/fabicare" || location.pathname === "/u"
                  ? "activeClass"
                  : ""
              }`}
            >
              <a onClick={handlesolutionSubmenu} className="v-center mt-3">
                Solutions
                <Icon
                  icon="mingcute:down-line"
                  color="#4B2E83"
                  width="20"
                  height="20"
                  className="ms-2"
                />
              </a>
            </li>
            {showsolSubmenu ? (
              <>
                <ul className="mobil-dropdown ">
                  <li>
                    <NavLink
                      className="d-flex  gap-2 "
                      exact
                      to="/fabicare"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      {" "}
                      <img
                        src="../../../assets/images/drycleanIcon.svg"
                        alt="Custom Icon 2"
                      />
                      Dry Cleaning
                      {/* <Icon
                        icon="mingcute:right-fill"
                        color="#4B2E83"
                        width="20"
                        height="20"
                      /> */}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-flex gap-2 "
                      exact
                      to="/life"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      {" "}
                      <img
                        src="../../../assets/images/lifeInsIcon.svg"
                        alt="Custom Icon 2"
                      />
                      Life Insurance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-flex gap-2 "
                      exact
                      to="/life"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/automobileIcon.svg"
                        alt="Custom Icon 2"
                      />
                      Auto Mobile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-flex gap-2 "
                      exact
                      to="/life"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/educationIcon.svg"
                        alt="Custom Icon 2"
                      />
                      Education
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="d-flex gap-2 "
                      exact
                      to="/life"
                      activeClassName="active"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <img
                        src="../../../assets/images/transportIcon.svg"
                        alt="Custom Icon 2"
                      />
                      Transportation
                    </NavLink>
                  </li>
                </ul>
              </>
            ) : (
              <></>
            )}
            <li className="my-3">
              <NavLink
                exact
                to="/pricing"
                activeClassName="active"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Pricing
              </NavLink>
            </li>
            {/* 
            <li className="my-3">
              <NavLink
                exact
                to="/demo"
                activeClassName="active"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Demo
              </NavLink>
            </li> */}
          </ul>

          <NavLink to="/demo">
            <button className="signupBtn  ">Demo</button>
          </NavLink>
        </Drawer>
      </div>
    </>
  );
};
export default Navbar;
