import React, { useState, useEffect, useRef } from 'react'
import './NameBox.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ModalOpen, setName } from '../../../../redux/action';
// import { toast } from 'react-toastify';
import axios from 'axios';
import { setFaqs, setFile, setRecord, setTags, } from '../../../../redux/action'
import { ToastContainer, toast } from 'react-toastify';

const NameBox = ({ link }) => {
    const navigate = useNavigate();
    const [Name, setname] = useState('')
    const [knowledgeData, setKnowledgeData] = useState(null);
    const [flag ,setflag] = useState()
    const [errors, setErrors] = useState(false);
    // if i click outside the modal it is to go back to original location
    const modalRef = useRef(); // Reference to the modal content

    //Useselecor 
  

    // use for Redux
    const dispatch = useDispatch();

    const name = useSelector((state) => state.name);


    useEffect(()=>{
        setname(name)
    },[name]);
    const handleName = (event) => {
        setname(event.target.value)
    }
    // console.log(Name)

    const handleSubmit = () => {
        if (Name) {
            console.log(Name + "Submit");
            dispatch(setName(Name))
            dispatch(setFaqs(null))
            dispatch(setTags(null))
            dispatch(setFile(null))
            dispatch(setRecord(null))
            navigate(`${link}`)
            setName('')
        } else {
            validate()
        }
    }

    const validate = () => {
        let tempErrors = {};
        if (!Name) tempErrors.agentName = "Name is required";

    
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
      };

    useEffect(()=>{
        if(flag === true){
            validate()
        }
    },[Name])
    const errorStyle = {
        color: "red",
        fontSize: "12px",
        marginTop: "4px",
      };
    return (
        <div className='NameBox-box' ref={modalRef}>
            <h5>Name</h5>
            <input type='text' placeholder='Nina' value={Name} onChange={handleName} />
            {errors.agentName && <p style={errorStyle}>{errors.agentName}</p>}
            <p>E.g.: you can give your data source a name to recall and find it easier</p>
            {/*<button className='theme-btn-light' onClick={() => navigate(`${link}`)}>Continue</button>*/}
            <button className='theme-btn-light' onClick={() => { setflag(true); handleSubmit()}}>Continue</button>
            <ToastContainer/> 
           </div>
    )
}

export default NameBox