

import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./TestIt.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const TestIt = () => {
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputValue3, setInputValue3] = useState(`{
    "input_text": "give me future appointments rmustafa.hafeez@gmail.com", // enter text
    "user_id": "0222" // Id of the chat 
  }`)
  const [isCreatedInbound, setIsCreatedInbound] = useState(true);
  const [isCreatedChat, setIsCreatedChat] = useState(true);
  const [Numbers, setNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [isModalOpenLink, setIsModalOpenLink] = useState(false);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedChat, setIsCheckedChat] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [payload , setpayload] = useState()

  const fetchWebhooks = () => {
    console.log("Fetching live transfer number");
    axios
      .get(`${process.env.REACT_APP_API_URL2}/customer/getAgent/${agentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (!response.data.response.livetransfer) {
          toast.warning("Add the Live Transfer number");
          navigate("/dashboard/CreateAgentBrief/Configuration");
        }
        setInputValue1(response.data.response.inboundCall);
        setInputValue2(response.data.response.chatWidgets);
        console.log("apis number setting",response.data.response.phoneNumber);
        
        setSelectedNumber(response.data.response.phoneNumber);

        if (response.data.response.inboundCall) {
          setIsCreatedInbound(false);
          setIsChecked(true);
        }
        if (response.data.response.chatWidgets) {
          setIsCreatedChat(false);
          setIsCheckedChat(true);
        }
      })
      .catch((err) => {
        console.error("Error fetching webhooks:", err);
        if (err.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };


  const fetchData = async () => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL2}/customer/getCustomerById`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.request(config);
      console.log("First Name:", response.data.firstName); // Log the first name
      setpayload(response.data); 
            // Set the fetched data in state
    } catch (error) {
      console.error("Error fetching data:", error); 
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }// Handle errors
    }
  };

  useEffect(() => {
    fetchWebhooks();
    fetchData();
  }, []);

 



 


  useEffect(() => {
   
    
    const Number = localStorage.getItem('selectedNumber');
    console.log('Number from localstorage', Number);
    setSelectedNumber(Number);
    if(Number){

      setIsCreatedInbound(true);
    }
  },[])

  useEffect(()=>{
console.log("Number hello", selectedNumber);
  },[selectedNumber])
  // Redux
  let agentId = useSelector((state) => state.agentId);

  if (!agentId) {
    console.log("agentId not found in state");
    agentId = localStorage.getItem("agentID");
  }

  const handleToggle = () => {

    // Toggle the checkbox state if the subscription is not Free or Basic
    setIsChecked(!isChecked);
  };

  const handleToggleChat = () => {
    setIsCheckedChat(!isCheckedChat);
  };

  const handleInputChange1 = (event) => {
    setInputValue1(event.target.value);
  };

  const handleInputChange2 = (event) => {
    setInputValue2(event.target.value);
  };

  const copyUrlToClipboard = () => {
    if (inputValue1.trim() === "") {
      alert("Please enter a URL first!");
      return;
    }
    const textarea = document.createElement("textarea");
    textarea.value = inputValue1;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("URL copied to clipboard!", { toastId: "1" });
  };

  const copyChatUrlToClipboard = () => {
    if (inputValue2.trim() === "") {
      alert("Please enter a URL first!");
      return;
    }
    const textarea = document.createElement("textarea");
    textarea.value = inputValue2;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("Chat URL copied to clipboard!", { toastId: "1" });
  };


  const copyChatPayloadUrlToClipboard = () => {
    if (inputValue3.trim() === "") {
      alert("Please enter a URL first!");
      return;
    }
    const textarea = document.createElement("textarea");
    textarea.value = inputValue3;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.success("Chat Payload URL copied to clipboard!", { toastId: "1" });
  };

  const createURLInbound = () => {
    console.log("Creating Inbound URL for agent:", agentId);
    if (!agentId || agentId === "null") {
      return toast.warning("Please create the agent first", { toastId: "1" });
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/InboundCallweb`,
        {
          inboundCall: `${process.env.REACT_APP_WebHook_Inbound}inbound_Call/${agentId}`,
          agentID: agentId,
          phoneNumber: selectedNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        
          setInputValue1(response.data.response.inboundCall);  
          setIsCreatedInbound(false);
      })
      .catch((error) => {
        console.error("Error updating phone number:", error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  const createURLChat = () => {
    if (!agentId) {
      toast.warning("Please create the agent first");
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/ChatWidget`,
        {
          chatWidgets: `${process.env.REACT_APP_API_URL}/Chat/${agentId}`,
          agentID: agentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setInputValue2(response.data.response.chatWidgets);
        setIsCreatedChat(false);
        console.log("Chat URL created:", response.data.response);
      })
      .catch((error) => {
        console.error("Error creating Chat URL:", error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };


  useEffect(() => {
    if (!inputValue1) {
      setIsCreatedInbound(true);
    } else {
      setIsCreatedInbound(false);
    }

    if (!inputValue2) {
      setIsCreatedChat(true);
    } else {
      setIsCreatedChat(false);
    }
  }, [inputValue1, inputValue2]);

  const closeModalLink = () => {
    setIsModalOpenLink(false);
  };

  const handleChange = (event) => {
    setSelectedNumber(event.target.value);
    localStorage.setItem('selectedNumber', event.target.value);
  };

  const ChangeNumber = () => {
    if (clicked) {
      return toast.warning("It is processing the request");
    }

    setClicked(true);

    if (!agentId || agentId === "null") {
      return toast.warning("Please create the agent first", { toastId: "1" });
    }
    console.log("Change Number:", selectedNumber);
    let data = JSON.stringify({
      phoneNumber: selectedNumber,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://nodejs-fabricare.octalooptechnologies.com/customer/configureWithTwillo/${agentId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Phone number change response:", JSON.stringify(response.data));
        closeModalLink();
        toast.success("Number changed successfully");
        navigate("/KnowledgeLibrary");
      })
      .catch((error) => {
        console.error("Error changing phone number:", error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      })
      .finally(() => {
        setClicked(false);
      });
  };

  const getNumbers = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL2}/customer/getagentNumbers`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setNumbers(response.data.Response);
      })
      .catch((error) => {
        console.error("Error fetching numbers:", error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  useEffect(() => {
    getNumbers();
  }, []);

  const handleSave = () => {
    if(isChecked)
    {
      if (!selectedNumber) return toast.warning("Please select a number");
      if (!isCreatedInbound) {
        setIsModalOpenLink(true);
      }
    }else{
      navigate("/KnowledgeLibrary");
    }

  };

  return (
    <div className="agentInfo-main-container">
      <div className="webhooks-container">
        <div className="Agent-details-container underline">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label>Inbound call web</label>
            <label className="switch" onClick={()=>{
              if (payload?.BillingSubscription === "Free" || payload?.BillingSubscription === "Basic") {
                toast.error(
                  "You are currently on a Free or Basic plan. Please upgrade to a Premium or Mid Tier plan to use live transfer."
                );
              }
            }}>
              <input
                type="checkbox"
                checked={payload?.BillingSubscription === "Free" || payload?.BillingSubscription === "Basic" ? false : isChecked} 
                 onChange={handleToggle}
                disabled={payload?.BillingSubscription === "Free" || payload?.BillingSubscription === "Basic"}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {(payload?.BillingSubscription !== "Free" && payload?.BillingSubscription !== "Basic" && isChecked) && (
            <div className="copyUrl-box mt-4">
              <input
                type="text"
                value={inputValue1}
                onChange={handleInputChange1}
                placeholder="chat.openai.com/c/9285bc28-ea18-4451-97f1-0d1b39fa72d5"
                disabled
              />
              {isCreatedInbound ? (
                <span
                  className="flex justify-center items-center gap-1"
                  onClick={createURLInbound}
                >
                  Create
                </span>
              ) : (
                <span
                  className="copy-item flex justify-center items-center gap-1"
                  onClick={copyUrlToClipboard}
                >
                  <img
                    src="../../../../../assets/icons/solar_copy-linear.png"
                    alt=""
                  />
                  Copy URL
                </span>
              )}
            </div>
          )}
          {(payload?.BillingSubscription !== "Free" && payload?.BillingSubscription !== "Basic" && isChecked) &&
            (isCreatedInbound ? null : (
              <select
                style={{ color: "black", margin: "20px" }}
                value={selectedNumber || "Select the number"}
                onChange={handleChange}
              >
                {!selectedNumber && (
                  <option value="Select the number" disabled>
                    Select the number
                  </option>
                )}
                {Numbers.map((phoneNumber, index) => (
                  <option key={index} value={phoneNumber}>
                    {phoneNumber}
                  </option>
                ))}
              </select>
            ))
          }
          
        </div>
        <div className="Agent-details-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label>Chat Widget</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={isCheckedChat}
                onChange={handleToggleChat}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {isCheckedChat && (
            <div className="copyUrl-box mt-4">
              <input
                type="text"
                value={inputValue2}
                onChange={handleInputChange2}
                placeholder="chat.openai.com/c/9285bc28-ea18-4451-97f1-0d1b39fa72d5"
                disabled
              />
            
              {isCreatedChat ? (
                <span
                  className="flex justify-center items-center gap-1"
                  onClick={createURLChat}
                >
                  Create
                </span>
              ) : (
                <span
                  className="copy-item flex justify-center items-center gap-1"
                  onClick={copyChatUrlToClipboard}
                >
                  <img
                    src="../../../../../assets/icons/solar_copy-linear.png"
                    alt=""
                  />
                  Copy URL
                </span>
              )}
            </div>
           
          )}

          {isCheckedChat && (
            <div className="copyUrl-box mt-4" >
           <textarea
           rows="4"
           cols="50"
           className="input-dash"
           type="text"
           disabled
           placeholder={`{
            "input_text": "give me future appointments rmustafa.hafeez@gmail.com", // enter text
            "user_id": "0222" // Id of the chat 
          }`}
          >
          </textarea>
          <button>
          <span
          className="copy-item flex justify-center items-center gap-1"
          onClick={copyChatPayloadUrlToClipboard}
          >
          <img
          src="../../../../../assets/icons/solar_copy-linear.png"
          alt=""
          />
          Copy 
          </span>
          </button>
          </div>
        )}
        </div>
        <button
          className="theme-btn-light ml-auto"
          onClick={() => {
            createURLInbound();
            handleSave();
          }}
        >
          Save
        </button>
      </div>

      <Modal
        open={isModalOpenLink}
        onCancel={closeModalLink}
        centered
        footer={null}
      >
        <div className="delete-modal v-center flex-column">
          <p className="text-center">
            Are you sure you want to configure inbound call for this number{" "}
            {selectedNumber}? The previous inbound configuration will be
            replaced.
          </p>
          <div className="d-flex gap-4 mt-5">
            <button
              onClick={closeModalLink}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                background: "#ccc",
                color: "#000",
              }}
            >
              No
            </button>
            <button
              onClick={() => ChangeNumber()}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                background: "#a890d3",
                color: "#fff",
              }}
              disabled={clicked}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default TestIt;


