import React, { useState,useEffect  } from "react";
import "./CreateAgentBrief.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
const CreateAgentBrief = () => {
  const navigate = useNavigate();
const [agentname,setAgentName ] = useState()
  const location = useLocation();
  const { pathname } = location;
  // Extract the query string
  const queryString = location.search;

  // Use URLSearchParams to parse the query string
  const searchParams = new URLSearchParams(queryString);

  // Extract the value (assuming you want the full string before the "a")
  const param = searchParams.toString(); // Gets the full query string
  let extractedValue = param.split('=')[0]; // Removes the trailing 'a' if needed

  const agentId = useSelector((state) => state.agentId);

  if (!extractedValue) {
    extractedValue = localStorage.getItem("agentID"); // Use the agentId if no query string is found
    console.log("No query string found, using agentId:", agentId);
  }
  useEffect(() => {
    fetchAgentData()
   },[pathname])


   const fetchAgentData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/getAgent/${extractedValue}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAgentName(response.data.response.AgentName)
      console.log(response.data.response.AgentName);
    } catch (error) {
      console.error("Error fetching agent data:", error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };
  console.log("Search", param, extractedValue)
  // console.log(location.pathname);
  return (
    <div className="CreateAgentBrief-main-container">
      <div className="CreateAgentBrief-centered-container">
    <h5> AgentName : {agentname}</h5>
        <div className="CreateAgentBrief-inner-container">
          <div className="nav-switch">
            <button
              className={
                pathname === "/updateAgentBrief/AgentInfo"
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/updateAgentBrief/AgentInfo");
                window.location.reload("/updateAgentBrief/AgentInfo");
              }}

            >
              Agent Info
            </button>
            <button
              className={
                pathname === "/updateAgentBrief/Script"
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/updateAgentBrief/Script");
              }}

            >
              Assign Agent Flow
            </button>
            <button
              className={
                pathname.includes("/updateAgentBrief/KnowledgeLibrary")
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/updateAgentBrief/KnowledgeLibrary");
              }}

            >
              Knowledge Library
            </button>

            <button
              className={
                pathname === "/updateAgentBrief/Configuration"
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/updateAgentBrief/Configuration");
              }}

            >
              Configuration
            </button>
            <button
              className={
                pathname === "/updateAgentBrief/Webhooks" ? "active" : ""
              }
              onClick={() => {
                navigate("/updateAgentBrief/Webhooks");
              }}

            >
              Webhooks
            </button>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CreateAgentBrief;
