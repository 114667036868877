
import React, { useEffect, useState,useContext } from "react";
import Assistants from "../Assistants/Assistants";
import Table from "../components/Table/Table";
import "./AITraining.scss";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal/Modal";
import axios from "axios";
import TableFlow from "../components/Table/TableFlow";
import { toast } from "react-toastify";
import { ModelContext } from "../../context/ModelContext";



const AITraining = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [Data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [DataFlow, setDataFlow] = useState([]);
  const { setIsOpen } = useContext(ModelContext);
  // const location = useLocation();
 
  //localStorage
  localStorage.setItem("agentID", "");


 
  const handleOpenModal = () => {
    navigate("/KnowledgeLibrary/NameBox");
    setModalOpen(true);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (!modalOpen) {
      setModalOpen(false);
    }
  }, [modalOpen]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL2}/customer/getAllKnowledgesBases`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log("data id given ", response.data);
      setData(response.data.Response);
    } catch (error) {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
    // console.log("fetchData", Data);
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/getAllagentflow`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDataFlow(response.data.Response);
    } catch (error) {
      console.log(error); if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
      
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  useEffect(() => {
    console.log("i am fetch");
    fetchData();
    fetchData2();
  }, []);

  const handleDeleteAllData = () => {
    fetchData();

  };

  const handleDeleteAllDataFlow = () => {
    fetchData2();
  };

  return (
    <div>
      <Assistants />

      <div className="KnowledgeLibrarySet-box">
        <div className="KnowledgeLibrary-main-container">
          <div className="KnowledgeLibrary-rightBtns-box">
            <div className="flex justify-between items-end w-full flexcol">
              <span>
                <h4 className="heading-4">Knowledge Library (datasets)</h4>
                <p className="para-med mt-1">
                  Manage, use, and share datasets within multiple agents.
                </p>
              </span>
              <button
                className="theme-btn-light gap-2"
                onClick={() => {
                  handleOpenModal();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.77649 3.92676C10.2244 3.92676 10.5875 4.28985 10.5875 4.73775V16.0916C10.5875 16.5395 10.2244 16.9026 9.77649 16.9026C9.3286 16.9026 8.9655 16.5395 8.9655 16.0916V4.73775C8.9655 4.28985 9.3286 3.92676 9.77649 3.92676Z"
                    fill="#111827"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.28857 10.4147C3.28857 9.96678 3.65167 9.60369 4.09956 9.60369H15.4534C15.9013 9.60369 16.2644 9.96678 16.2644 10.4147C16.2644 10.8626 15.9013 11.2257 15.4534 11.2257H4.09956C3.65167 11.2257 3.28857 10.8626 3.28857 10.4147Z"
                    fill="#111827"
                  />
                </svg>
                Create New
              </button>
            </div>
          </div>
          <br />
          <Table tabledata={Data} onDelete={handleDeleteAllData} />
        </div>

        <div className="KnowledgeLibrary-main-container">
          <div className="KnowledgeLibrary-rightBtns-box">
            <div className="flex justify-between items-end w-full flexcol">
              <span>
                <h4 className="heading-4">Flows</h4>
                <p className="para-med mt-1">
                  Manage, use, and share flows within multiple agents.
                </p>
              </span>
              <button
                className="theme-btn-light gap-2"
                onClick={() => {
                  navigate("/dashboard/CreateAgentBrief/Script");
                  handleOpenModal();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.77649 3.92676C10.2244 3.92676 10.5875 4.28985 10.5875 4.73775V16.0916C10.5875 16.5395 10.2244 16.9026 9.77649 16.9026C9.3286 16.9026 8.9655 16.5395 8.9655 16.0916V4.73775C8.9655 4.28985 9.3286 3.92676 9.77649 3.92676Z"
                    fill="#111827"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.28857 10.4147C3.28857 9.96678 3.65167 9.60369 4.09956 9.60369H15.4534C15.9013 9.60369 16.2644 9.96678 16.2644 10.4147C16.2644 10.8626 15.9013 11.2257 15.4534 11.2257H4.09956C3.65167 11.2257 3.28857 10.8626 3.28857 10.4147Z"
                    fill="#111827"
                  />
                </svg>
                Create New
              </button>
            </div>
          </div>
          <br />
          <TableFlow tabledata={DataFlow} onDelete={handleDeleteAllDataFlow} />
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} />
      {/* Modal  Knowledge base Create */} 
    </div>
  );
};

export default AITraining;

