import React, { useContext, useEffect, useState } from "react";
import "./KnowledgeLibrary.scss";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";
import KnowledgeTable from "./KnowledgeTable/KnowledgeTable";
import Modal from "../../../components/Modal/Modal";
import { ModelContext } from "../../../../context/ModelContext";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SetKnowledge, setKnowId } from "../../../../redux/action";

const KnowledgeLibrary = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { setIsOpen } = useContext(ModelContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [agentID, setAgentID] = useState();
  // Redux state
  const knowledgeID = useSelector((state) => state.knowledgeBase);
  const AgentID = useSelector((state) => state.agentId);
  const dispatch = useDispatch();
const location = useLocation();
  // Handle input change for the search field
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsOpen(false);
  };

  const openmodalfroupdate = () => {
    // console.log("open modalfroup date","i am here ")
    navigate("/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader");
    handleOpenModal();
  };

  useEffect(() => {
    if (!AgentID) {
      const id = localStorage.getItem("agentID");
      setAgentID(id);
    } else {
      setAgentID(agentID);
    }
  }, []);

  return (
    <div>
      <div className="KnowledgeLibraryTab-container mt-8">
        <div className="flex justify-between items-center flexCol gap-3 mb-4">
          <div className="KnowledgeLibraryTab-search-box">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Icon
              icon="octicon:search-16"
              className="search-icon"
              style={{ color: "#868687" }}
            />
          </div>
          <div className="btns flex items-center justify-end gap-3">
            <button className="theme-btn-tran">Assign Knowledge Base</button>
            <button
              className="theme-btn"
              onClick={() => {
                dispatch(setKnowId(""));
                navigate(
                  "/dashboard/CreateAgentBrief/KnowledgeLibrary/NameBox"
                );

                handleOpenModal();
              }}
            >
              Create New
            </button>
          </div>
        </div>

        <div className="border rounded-lg overflow-hidden">
          <KnowledgeTable
            searchTerm={searchTerm}
            openModal={openmodalfroupdate}
            agentID={agentID}
          />
        </div>
      </div>
      <div className="flex justify-end pt-5">
        <button
          className="theme-btn-light items-center gap-2"
          onClick={() => {

            if (location.pathname.includes("/updateAgentBrief")){

              navigate("/updateAgentBrief/Configuration");
            }
            else{
  
              navigate("/dashboard/CreateAgentBrief/Configuration");
            }
          }}
        >
          Next
          <Icon icon="tdesign:arrow-right" style={{ color: "#4B2E83" }} />
        </button>
      </div>

      {/* Modal */}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} />
      <ToastContainer />
    </div>
  );
};

export default KnowledgeLibrary;
