// Action Types
export const SET_AGENT_ID = 'SET_AGENT_ID';
export const SET_Name = 'SET_Name';
export const SET_file = 'SET_File';
export const SET_record = 'SET_Record';
export const SET_faqs = 'SET_faqs';
export const SET_modal='SET_modal';
export const SET_know_Id = 'SET_know_Id';
export const SET_load_file = 'SET_load_file';
export const SET_tags_Faqs = 'SET_tags_Faqs';
export const SET_Knowledge = 'SET_Knowledge';
export const SET_Agentkeys = 'SET_Agentkeys';
export const SET_AgentData = 'SET_AgentData';
// Action Creators
export function setAgentId(agentId) {
  return {
    type: SET_AGENT_ID,
    payload: agentId
  };
}
 
export function setName(Name) {
return { 
  type: SET_Name, 
  payload:Name
}
}

export function setFile(file) {
  console.log(file, "action set");
  return {
    type: SET_file,
    payload: file
  };
}

export function setRecord(record) {
  console.log(record, "action set");
  return {
    type: SET_record,
    payload: record
  };
}


export function setFaqs(faqs) {
  return {
    type: SET_faqs,
    payload: faqs
  };
}


export function ModalOpen(modal){
  // console.log(modal,"value changed");
  return {
    type: SET_modal,
    payload: modal
  };
}

export function setKnowId(knowId) {
  return {
    type: SET_know_Id,
    payload: knowId
  }; 
}

export function setload(load) {
  console.log('getiing loadeded state',load);
  return {
    type: SET_load_file,
    payload: load
  };
}


export function setTags(tags) {
  return {
    type: SET_tags_Faqs,
    payload: tags
  };
}


export function SetKnowledge(knowledge) {
  return {
    type: SET_Knowledge,
    payload: knowledge
  };
}

export function SetAgentkeys(agentkeys) {
  return {
    type: SET_Agentkeys,
    payload: agentkeys
  };
}

export function SetAgentData(agentdata) {
  return {
    type: SET_AgentData,
    payload: agentdata
  };
}