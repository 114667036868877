// import { Icon } from "@iconify/react";
// import React from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// const DashStatsCard = ({
//   total,
//   totalNum,
//   callNum,
//   chatNum,
//   span,
//   progressbar,
// }) => {
//   return (
//     <div className="dashboardMain-stats-box gap-2">
//       <div className="dashboardMain-divide-width bar-position w-6/12">
//         <h6>{total}</h6>
//         <h5 className="mt-2">
//           {totalNum}
//           <span>{span}</span> {progressbar}{" "}
//         </h5>
//       </div>

//       <div className="dashboardMain-divide-width flex justify-between">
//         <div className="">
//           <span className="flex items-center gap-2">
//             <div className="icon-phone-box">
//               <Icon
//                 icon="ph:phone-call-light"
//                 className="icon-phone"
//                 style={{ color: "#7D5BA6" }}
//               />
//             </div>
//             <h6>Calls</h6>
//           </span>
//           <h5 className="mt-1 back">
//             {callNum}
//             <span>{span}</span>
//           </h5>
//         </div>
//         <div>
//           <span className="flex items-center gap-2">
//             <div className="icon-phone-box">
//               <Icon
//                 icon="carbon:chat"
//                 className="icon-phone"
//                 style={{ color: "#7D5BA6" }}
//               />
//             </div>
//             <h6>Chat</h6>
//           </span>
//           <h5 className="mt-1 back">
//             {chatNum}
//             <span>{span}</span>
//           </h5>
//         </div>
//       </div>
//     </div>
//   );
// };


// export default DashStatsCard;
import React from "react";
import { Icon } from "@iconify/react";

const DashStatsCard = ({
  total,
  totalNum,
  callNum,
  chatNum,
  span,
  progressbar,
}) => {
  // Check if any of the key props are null or undefined


  return (
    <div className="dashboardMain-stats-box gap-2">
      <div className="dashboardMain-divide-width bar-position w-6/12">
        <h6>{total}</h6>
        <h5 className="mt-2">
          {totalNum}
          <span>{span}</span> {progressbar}{" "}
        </h5>
      </div>

      <div className="dashboardMain-divide-width flex justify-between">
        <div className="">
          <span className="flex items-center gap-2">
            <div className="icon-phone-box">
              <Icon
                icon="ph:phone-call-light"
                className="icon-phone"
                style={{ color: "#7D5BA6" }}
              />
            </div>
            <h6>Calls</h6>
          </span>
          <h5 className="mt-1 back">
            {callNum}
            <span>{span}</span>
          </h5>
        </div>

        {chatNum || chatNum === 0  ? (
          <div>
            <span className="flex items-center gap-2">
              <div className="icon-phone-box">
                <Icon
                  icon="carbon:chat"
                  className="icon-phone"
                  style={{ color: "#7D5BA6" }}
                />
              </div>
              <h6>Chat</h6>
            </span>
            <h5 className="mt-1 back">
              {chatNum}
              <span>{span}</span>
            </h5>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DashStatsCard;
