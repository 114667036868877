import React from "react";
import "./logstable.scss";

const Logsdetailtable = () => {
  return (
    <>
      <div class="logdetailtable-div relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right">
          <thead>
            <tr>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">State</th>
              <th scope="col">Zip</th>
              <th scope="col">Lists</th>
              <th scope="col">Status</th>
              <th scope="col"># of Attempts</th>
              <th scope="col">Pickups</th>
              <th scope="col">Upload Date</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>John</td>
              <td>Smith</td>
              <td>+9090423542</td>
              <td>lincoln@gmail.com</td>
              <td>TN</td>
              <td>38439</td>
              <td>TN Leads, Ins October</td>
              <td>New Lead</td>
              <td>6</td>
              <td>0</td>
              <td>12-03-2023</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>John</td>
              {/* <td>
                <a href="" className="tr-dark">
                  Mike Phillips
                </a>
              </td> */}
              <td>Smith</td>
              <td>+9090423542</td>
              <td>lincoln@gmail.com</td>
              <td>TN</td>
              <td>38439</td>
              <td>TN Leads, Ins October</td>
              <td>New Lead</td>
              <td>6</td>
              <td>0</td>
              <td>12-03-2023</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>John</td>
              {/* <td>
                <a href="" className="tr-dark">
                  Mike Phillips
                </a>
              </td> */}
              <td>Smith</td>
              <td>+9090423542</td>
              <td>lincoln@gmail.com</td>
              <td>TN</td>
              <td>38439</td>
              <td>TN Leads, Ins October</td>
              <td>New Lead</td>
              <td>6</td>
              <td>0</td>
              <td>12-03-2023</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td>John</td>
              {/* <td>
                <a href="" className="tr-dark">
                  Mike Phillips
                </a>
              </td> */}
              <td>Smith</td>
              <td>+9090423542</td>
              <td>lincoln@gmail.com</td>
              <td>TN</td>
              <td>38439</td>
              <td>TN Leads, Ins October</td>
              <td>New Lead</td>
              <td>6</td>
              <td>0</td>
              <td>12-03-2023</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Logsdetailtable;
