import React from 'react'
import './LiveChatBenefitsCard.scss'

const LiveChatBenefitsCard = ({h5, p, h3}) => {
    return (
        <div className='LiveChatBenefits-inner-box'>
            <div className='LiveChatBenefits-inner-left-box'>
                <h5>{h5}</h5>
                <p>{p}</p>
            </div>
            <div className='LiveChatBenefits-inner-right-box'>
                <h3>{h3}</h3>
            </div>
        </div>
    )
}

export default LiveChatBenefitsCard