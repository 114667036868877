import React from "react";

const EnhanceBox = (props) => {
  const { h5, h3, p, img } = props;

  return (
    <div className="quarter">
      <div className="flex justify-center items-center gap-8 setFlex">
        <div className="enhancing-left-container">
          <img src={img} alt="Enhancing Conversation" />
        </div>
        <div className="enhancing-right-container">
          <h5>{h5}</h5>
          <h3>{h3}</h3>
          <p>{p}</p>
        </div>
      </div>
    </div>
  );
};

export default EnhanceBox;
