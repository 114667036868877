import React from "react";
import "./Footer.scss";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-main-container">
      <div className="footer-centered-container">
        {/* <img src="../../../assets/images/footer-logo.svg" alt="" /> */}
        <img src="../../../assets/fabriccareLogoo.png" alt="" />
        {/* <ul>
          <Link to="/">
            <li>Home</li>
          </Link>
          <Link to="/pricing">
            <li>Pricing</li>
          </Link>
          <Link to="/choose-us">
            <li>Why Choose Us</li>
          </Link>

          <Link to="/work">
            <li>How it Works</li>
          </Link>

          <Link to="/integrations">
            <li>Integrations</li>
          </Link>
        
          <Link to="/demo">
            {" "}
            <li>Contact Us</li>
          </Link>
        </ul> */}
        <div className="socailMedia-footer-icon-box mt-10">
          <span className="socailMedia-footer-icon-link">
            <Icon
              className="footer-icon-"
              color="#FFF"
              icon="ri:facebook-fill"
            />
          </span>
          <span className="socailMedia-footer-icon-link">
            <Icon
              className="footer-icon-"
              color="#FFF"
              icon="lucide:instagram"
            />
          </span>
          <span className="socailMedia-footer-icon-link">
            <Icon className="footer-icon-" color="#FFF" icon="bi:whatsapp" />
          </span>
          <span className="socailMedia-footer-icon-link">
            <Icon
              className="footer-icon-"
              color="#FFF"
              icon="teenyicons:twitter-solid"
            />
          </span>
          {/* <Link to="" className="socailMedia-footer-icon-link"></Link> */}
        </div>
        <div className="footer-line"></div>
        <p>Copyright © 2024 Fabricare. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
