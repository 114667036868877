import React, { useEffect, useState } from "react";
import "./AgentInfo.scss";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { setAgentId, SetAgentData } from "../../../../redux/action";

// const RadioButton = ({ checked }) => (
//   <div className={`radio-button ${checked ? "checked" : ""}`}>
//     <div className="inner-circle"></div>
//   </div>
// );

const AgentInfo = () => {
  const navigate = useNavigate();
  // const [selected, setSelected] = useState([]);
  // INPUT tags values to set
  const [agentName, setAgentName] = useState("");
  const [personality, setPersonality] = useState("");
  const [agentTitle, setAgentTitle] = useState("");
  const [companyName, setCompanyName] = useState("Fabricare");
  const [brandVoice, setBrandVoice] = useState("");
  const [companyBusiness, setCompanyBusiness] = useState("Dry cleaning, stain removal and other fabric needs ");
  const [audience, setAudience] = useState("");
  const [greetings, setgreetings] = useState("");
  const [errors, setErrors] = useState({});
  const [ID, setID] = useState("");
  const [flag, setFlag] = useState("");

  //id from Url For Edit
  const location = useLocation();

  const validate = () => {
    let tempErrors = {};
    if (!agentName) tempErrors.agentName = "Agent Name is required";
    if (!personality) tempErrors.personality = "Personality is required";
    if (!agentTitle) tempErrors.agentTitle = "Agent Title is required";
    if (!companyName) tempErrors.companyName = "Company Name is required";
    if (!companyBusiness)
      tempErrors.companyBusiness = "Company Business is required";
    if (!brandVoice) tempErrors.brandVoice = "Brand Voice is required";
    if (!audience) tempErrors.audience = "Audience is required";
    if(!greetings) tempErrors.greetings = "Greetings is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  //Redux state
  const dispatch = useDispatch();
  const agentId = useSelector((state) => state.agentId);

  const fetchAgentData = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/getAgent/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setAgentName(response.data.response.AgentName);
      setCompanyName(response.data.response.CompanyName);
      setAgentTitle(response.data.response.AgentTitle);
      setBrandVoice(response.data.response.BrandVoice);
      setCompanyBusiness(response.data.response.CompanyBusiness);
      setAudience(response.data.response.Audience);
      setPersonality(response.data.response.Personality);
      setgreetings(response.data.response.greetings);
      dispatch(SetAgentData(response.data.response));
    } catch (error) {
      console.error("Error fetching agent data:", error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  useEffect(() => {
    const checkID = localStorage.getItem("agentID");
    // console.log("chceckID", checkID);
    if (!checkID) {
      const queryParams = new URLSearchParams(location.search);
      const params = {};
      queryParams.forEach((value, key) => {
        params[key] = value;
      });
      // Get the specific key
      const keys = Array.from(queryParams.keys());
      const specificKey = keys.length > 0 ? keys[0] : null;
      setID(specificKey);
      // console.log("id", ID);
      localStorage.setItem("agentID", ID);
      if (ID) {
        setID(checkID)
        fetchAgentData(ID);
      }
    } else {
      setID(checkID)
      // console.log("ID specified");
      fetchAgentData(ID);
    }
  }, [ID]);

  const notify = () => {
    // console.log("received");
    toast("Agent created", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: true,
      toastId: 2,
    });
    // console.log("show notification");
  };

  const handleSetAgentId = (id) => {
    dispatch(setAgentId(id));
    localStorage.setItem("agentID", id);
  };
useEffect(() => {
  console.log(location.pathname);
})
  async function fetchData(postData) {
    try {
      // console.log("HOI", postData);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/CreateAgent`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          // console.log("Data retrieved:", response);

          handleSetAgentId(response.data.data);
          //   notify()
        
          if (location.pathname.includes("/updateAgentBrief/AgentInfo")){

            navigate("/updateAgentBrief/Script");
          }
          else{

            navigate("/dashboard/CreateAgentBrief/Script");
          }
          // toast.success("Agent created successfully",{toastId:2});
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function UpdateData(postData) {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/updateAgent/${ID}`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          // console.log("Data retrieved:", response);
          // navigate("/dashboard/CreateAgentBrief/Script");
          // toast.success("Agent updated successfully",{toastId:2});
          if (location.pathname.includes("/updateAgentBrief")){

            navigate("/updateAgentBrief/Script");
          }
          else{

            navigate("/dashboard/CreateAgentBrief/Script");
          }
        
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (flag === true) {
      validate()
    }
  }, [
    agentName,
    audience,
    brandVoice,
    companyBusiness,
    companyName,
    personality,
    agentTitle,
    greetings,
  ]);

  const handleAgentName = (event) => {
    setAgentName(event.target.value);
  };

  const handlePersonality = (event) => {
    setPersonality(event.target.value);
  };

  const handleAgentTitle = (event) => {
    setAgentTitle(event.target.value);
  };

  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  const handleBrandVoice = (event) => {
    setBrandVoice(event.target.value);
  };

  const handleCompanyBusiness = (event) => {
    setCompanyBusiness(event.target.value);
  };

  const handleAudience = (event) => {
    setAudience(event.target.value);
  };

  const handleGreetings = (event) => {
    setgreetings(event.target.value);
  };
  const handleSubmit = () => {
    if (validate()) {
      const postData = {
        AgentName: agentName || "Agent",
        Personality: personality,
        AgentTitle: agentTitle,
        CompanyName: companyName,
        CompanyBusiness: companyBusiness,
        BrandVoice: brandVoice,
        Audience: audience,
       greetings:greetings
      };
      // console.log("postData", typeof(ID));
      if (!ID || ID === null || ID === "null") {
        // console.log("No ID");
        fetchData(postData);
      } 
    else {
      // console.log("Has ID:" , ID);
        UpdateData(postData);
      }
    } else {
      // window.location.reload();
      // console.log("empty postData");
      // toast.warning(`Please fill all the fields `,{toastId:3});
    }
  };



  const errorStyle = {
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  };

  return (
    <div className="agentInfo-main-container">
      <div className="agentInfo-temp-box flex justify-between items-start pt-4">
        <div className="agentInfo-tempTxt-box">
          <h6>Agent Info</h6>
          <p>Your AI Assistant</p>
        </div>
        {
          // <span>Templates</span>
        }
      </div>

      <div>
        <div className="Agent-details-container">
          <label>Agent Name</label>
          <input
            className="input-dash"
            type="text"
            value={agentName}
            onChange={handleAgentName}
          />
          {errors.agentName && <p style={errorStyle}>{errors.agentName}</p>}
        </div>


        <div className="Agent-details-container">
        <label>Greetings Message</label>
        <textarea
          rows="2"
          cols="50"
          className="input-dash"
          type="text"
          value={greetings}
          onChange={handleGreetings}
        ></textarea>
        {errors.greetings && <p style={errorStyle}>{errors.greetings}</p>}
      </div>



        <div className="Agent-details-container">
          <label>Personality</label>
          <input
            className="input-dash"
            type="text"
            value={personality}
            onChange={handlePersonality}
          />
          {errors.personality && <p style={errorStyle}>{errors.personality}</p>}
        </div>

        <div className="Agent-details-container">
          <label>Agent Title</label>
          <input
            className="input-dash"
            type="text"
            value={agentTitle}
            onChange={handleAgentTitle}
          />
          {errors.agentTitle && <p style={errorStyle}>{errors.agentTitle}</p>}
        </div>

        <div className="Agent-details-container">
          <label>Company Name</label>
          <input
            className="input-dash"
            type="text"
            value={companyName}
            onChange={handleCompanyName}
          />
          {errors.companyName && <p style={errorStyle}>{errors.companyName}</p>}
        </div>

        <div className="Agent-details-container">
          <label>Company Business</label>
          <textarea
            rows="4"
            cols="50"
            className="input-dash"
            type="text"
            value={companyBusiness}
            onChange={handleCompanyBusiness}
            disabled 
            placeholder="Dry cleaning, stain removal and other fabric needs "
          ></textarea>
          {errors.companyBusiness && (
            <p style={errorStyle}>{errors.companyBusiness}</p>
          )}
        </div>

        <div className="Agent-details-container">
          <label>Brand Voice</label>
          <textarea
            rows="2"
            cols="50"
            className="input-dash"
            type="text"
            value={brandVoice}
            onChange={handleBrandVoice}
          ></textarea>
          {errors.brandVoice && <p style={errorStyle}>{errors.brandVoice}</p>}
        </div>

        <div className="Agent-details-container">
          <label>Audience</label>
          <textarea
            rows="2"
            cols="50"
            className="input-dash"
            type="text"
            value={audience}
            onChange={handleAudience}
          ></textarea>
          {errors.audience && <p style={errorStyle}>{errors.audience}</p>}
        </div>



      </div>



      
      <div className="hr1"></div>
      <div className="flex justify-end">
        <button
          className="nextBtn"
          onClick={() => {
            setFlag(true);
            handleSubmit();
          }}
        >
          Next
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M13.666 7.81836L3.66602 7.81836"
              stroke="#4B2E83"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.63281 3.80132L13.6661 7.81732L9.63281 11.834"
              stroke="#4B2E83"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default AgentInfo;
