import React, { useEffect } from "react";
import "./Home.scss";
import Hero from "../../components/Hero/Hero";
import Navbar from "../../components/Navbar/Navbar";
import TalkSection from "./HomeSections/TalkSection/TalkSection";
import ConnectAI from "./HomeSections/ConnectAI/ConnectAI";
import Benefits from "./HomeSections/Benefits/Benefits";
import Statistics from "./HomeSections/Statistics/Statistics";
import RequestDemo from "./HomeSections/RequestDemo/RequestDemo";
import Footer from "../../components/Footer/Footer";
import DemoPage from "./HomeSections/demoPage";
import KeyFeatures from "./HomeSections/KeyFeatures/KeyFeatures";
import Enhancing from "./HomeSections/Enhancing/Enhancing";
import Availability from "./HomeSections/Availability/Availability";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-main-container overflow-x-hidden">
      <Navbar />
      <Hero />
      <TalkSection />
      <KeyFeatures />

      <ConnectAI />

      <Benefits />
      <Statistics
        h5="Why Choose Us"
        text1="Answer Every Call."
        text2="Delivering Availability"
        text3="That Delivers Results"
      />
      <Enhancing />
      {/* <RequestDemo btntxt="Send Request" marginminus="marginminus" /> */}
      {/* <DemoPage btntxt="Send Request" marginminus="marginminus" /> */}
      <Availability removepadding="removepadding" />
      <Footer />
    </div>
  );
};

export default Home;
