import React from "react";
import "./WhyFabriCareCard.scss";

const WhyFabriCareCard = ({ img, h5, p, animate, duration, delay }) => {
  return (
    <div
      className="WhyFabriCareCard-inner-box"
      data-aos={animate}
      data-aos-duration={duration}
      data-aos-delay={delay}
    >
      <div>
        <img src={img} alt="" />
        <h5>{h5}</h5>
      </div>
      <p>{p}</p>
    </div>
  );
};

export default WhyFabriCareCard;
