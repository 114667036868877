import React from "react";
import './notifications.scss'

const Notifications = () => {
  return (
    <div className="Subscription-main-container">
      <h5>Notifications & Others</h5>
      <div className="enable-box">
        <h6>Email Notifications</h6>
        <label class="switch">
          <input type="checkbox" />
            <span class="slider round"></span>
        </label>
      </div>
      <div className="enable-box">
        <h6>Disable Chat</h6>
        <label class="switch">
          <input type="checkbox" />
            <span class="slider round"></span>
        </label>
      </div>
    </div>
  )
};

export default Notifications;
