import React, { useState } from "react";
import "./Configuration.scss";
import { useNavigate, useLocation } from "react-router-dom";
import FetchVoice from "./FetchVoice/FetchVoice";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
const Configuration = () => {
  const livephonenumber = localStorage.getItem("phonenumberlive");

  const [Data, setData] = useState();
  const [fetchVoices, setFetchVoices] = useState(false);
  const [livetransfer, setLiveTransfer] = useState();
  const [elevenlabsapikey, setElevenlabsapikey] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selectedVoice, setSelectedVoice] = useState("");
  const [filteredVoices, setFilteredVoices] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  //REDUX state
  const agentId = useSelector((state) => state.agentId);
  const id = localStorage.getItem("agentID");

  //update the live transfer number and the audio id
  const fetchData = async () => {
    if (!agentId && !id) return toast.warning("Agent ID is required");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/UpdateTheLiveTransfer`,
        {
          agentID: agentId || id,
          livetransfer: livetransfer,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("data id given ", response.data);
        if (location.pathname.includes("/updateAgentBrief")) {
          navigate("/updateAgentBrief/Webhooks");
        } else {
          navigate("/dashboard/CreateAgentBrief/Webhooks");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log("Payment issue detected, redirecting to settings...");
          navigate("/settings"); // Ensure you're using string URL correctly
        }
      });
  };

  //eleven labs value added to Agent Keys
  const valueUpated = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
        {
          ElevenLabs: elevenlabsapikey,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("data id given ", response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log("Payment issue detected, redirecting to settings...");
          navigate("/settings"); // Ensure you're using string URL correctly
        }
      });
  };

  const handleLiveTransfer = (value) => {
    setLiveTransfer(value);

    if (value) {
      const phoneNumberObject = parsePhoneNumberFromString(value);

      if (phoneNumberObject && phoneNumberObject.isValid()) {
        setValidationMessage("");
        setCountryCode(phoneNumberObject.countryCallingCode);
      } else {
        setValidationMessage("Please enter a valid phone number");
        setCountryCode("");
      }
    } else {
      setValidationMessage("Please enter a phone number");
      setCountryCode("");
    }
  };

  const handleElevenlabs = (event) => {
    setElevenlabsapikey(event.target.value);
  };

  const next = () => {
    if (!livetransfer)
      return toast.warning("Add live transfer Number ", { toastId: 1 });
    fetchData();
    valueUpated();
    localStorage.setItem("phonenumberlive", "");
  };
  const fetchLiveTransferNumber = () => {
    console.log("Fetching live transfer number");
    if (!agentId && !id)
      return toast.warning("Agent ID is required", { toastId: 1 });

    axios
      .get(`${process.env.REACT_APP_API_URL2}/customer/getAgent/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setLiveTransfer(response.data.response.livetransfer);
        setSelectedVoice(response.data.response.audioId);
        const storedValue = localStorage.getItem("phonenumberlive");
        console.log("storedValue", storedValue);
        if (storedValue) {
          setLiveTransfer(storedValue);
        }
      })
      .catch((err) => {
        console.log("error", err);
        if (err.response?.data?.paymentIssue === true) {
          console.log("Payment issue detected, redirecting to settings...");
          navigate("/settings"); // Ensure you're using string URL correctly
        }
      });
    // console.log(livetransfer,"live");
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/getagentKeys`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("Data received:", response.data);
        setElevenlabsapikey(response.data.Response.ElevenLabs);

        axios
          .get("https://api.elevenlabs.io/v1/voices", {
            headers: { "xi-api-key": response.data.Response.ElevenLabs },
          })
          .then((response) => {
            // console.log("Data received:", response.data);
            setData(response.data);
          })
          .catch((err) => {
            console.error(err);
            if (err.response?.data?.paymentIssue === true) {
              console.log("Payment issue detected, redirecting to settings...");
              navigate("/settings"); // Ensure you're using string URL correctly
            }
          });
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    fetchLiveTransferNumber();
  }, []);

  const Voices = async () => {
    console.log(elevenlabsapikey);

    await axios
      .get("https://api.elevenlabs.io/v1/voices", {
        headers: { "xi-api-key": elevenlabsapikey },
      })
      .then((response) => {
        // console.log("Data received:", response.data);
        setData(response.data);
        setFetchVoices(true);
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });

    // console.log("Voices retirved:", Data);
  };

  useEffect(() => {}, []);
  return (
    <div className="agentInfo-main-container">
      <div className="agentInfo-temp-box flex justify-between items-start pt-4">
        <div className="agentInfo-tempTxt-box">
          <h6>Live Transfer</h6>
        </div>
      </div>

      <div>
        <div className="Agent-details-container">
          <label>Live Transfer</label>
          <PhoneInput
            className="input-dash"
            placeholder="Live Transfer Phone Number"
            international
            value={livetransfer}
            onChange={handleLiveTransfer}
            defaultCountry="US"
          />
          {validationMessage && (
            <p className="validation-message">{validationMessage}</p>
          )}
        </div>

        <h6 className="scheduling-h6">Voice Selection</h6>
      </div>
      <div style={{ color: "black" }}> Selected Voice :</div>
      {Data &&
        Data.voices
          .filter((item) => item.voice_id === selectedVoice)
          .map((item, index) => (
            <FetchVoice key={index} data={item} selectedVoice={selectedVoice} />
          ))}

      {!fetchVoices ? (
        <div className="FetchVoice-div flex justify-end mt-5">
          <div
            className="FetchVoice-div flex justify-end mt-2"
            style={{ color: "black", marginRight: "20px" }}
          >
            {" "}
            Select Voice :
          </div>
          <button
            className="theme-btn-light"
            onClick={() => {
              Voices();
            }}
          >
            Fetch Voices
          </button>
        </div>
      ) : (
        <div className="FetchVoice-div flex justify-end mt-5">
          <div
            className="FetchVoice-div flex justify-end mt-2"
            style={{ color: "black", marginRight: "20px" }}
          >
            {" "}
            Select Voice :
          </div>
          <button
            className="theme-btn-light"
            onClick={() => {
              setFetchVoices(false);
            }}
          >
            Hide Voices
          </button>
        </div>
      )}

      {fetchVoices && (
        <div className="fetchVoices-container">
          {Data &&
            Data.voices.map((item, index) => (
              <FetchVoice
                key={index}
                data={item}
                selectedVoice={selectedVoice}
                phonenumber={livetransfer}
              />
            ))}
        </div>
      )}

      <div className="hr2 mt-5"></div>

      <div className="flex justify-end mt-5">
        <button className="nextBtn" onClick={() => next()}>
          Next
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M13.666 7.81836L3.66602 7.81836"
              stroke="#4B2E83"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.63281 3.80132L13.6661 7.81732L9.63281 11.834"
              stroke="#4B2E83"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Configuration;
