import React from 'react'
import './LiveChat.scss'
import { Outlet } from 'react-router-dom'

const LiveChat = () => {
    return (

        <div>
            <Outlet />
        </div>

    )
}

export default LiveChat