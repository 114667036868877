import React,{useEffect, useState} from "react";
import "./Smrt.scss";
import TableSmrt from "./TableSmrt/TableSmrt";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SetAgentkeys } from "../../../redux/action";

const Smrt = () => {
  const navigate = useNavigate();
  const [SMRT, setSMRT] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const disptach = useDispatch();

  console.log(Smrt.SMARTkey)
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSMRT = (event) => {
    setSMRT(event.target.value);
  };



  useEffect(() => {
    // If Smrt is null or undefined, navigate to "/Integration" or handle appropriately
   let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://nodejs-fabricare.octalooptechnologies.com/customer/getagentKeys',
  headers: { 
    'Authorization': `Bearer ${localStorage.getItem("token")}`
  }
};

axios.request(config)
.then((response) => {

  setSMRT(response.data.Response.SMARTkey);
  disptach(SetAgentkeys(response.data.Response));
})
.catch((error) => {
  console.log(error);
});
    // If Smrt and SMARTkey exist, set it to the state
  }, []); 

  const handleSubmit = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
        {
          "SMARTkey" : SMRT
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("data id given ", response.data);
        disptach(SetAgentkeys(response.data.Response));
        console.log("data", response.data);
        navigate("/Integration/LLM");
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };
  return (
    <div className="Smrt-main-conatiner">
      <div className="Smrt-maininner-conatiner">
        <h5>SMRT API Key</h5>
        <p>
          The SMRT API Key allows interaction with REST API endpoints, Widgets,
          and Plugins.{" "}
          <span
            style={{
              color: "#7D5BA6",
              fontWeight: 600,
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Learn more.
          </span>
        </p>
        {/*<TableSmrt />*/}
        <div className="SMRT-input-box">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="•••••••••••••••••••••••"
            className="input-dash"
            alt=""
            value={SMRT}
            onChange={handleSMRT}
          />
          {showPassword ? (
            <Icon
              icon="mdi:eye-off"
              width="24"
              height="24"
              style={{ color: "#A890D3" }}
              onClick={togglePasswordVisibility}
            />
          ) : (
            <Icon
              icon="mdi:eye"
              width="24"
              height="24"
              style={{ color: "#A890D3" }}
              onClick={togglePasswordVisibility}
            />
          )}
        </div>
        <button
          style={{ padding: "14px 44px" }}
          className="theme-btn-light mt-7 mb-4"
          onClick={handleSubmit}
        >
         Save
        </button>
        <p>
          NOTE: Remember to keep your API token secure, as it grants access to
          your account and the associated resources. If you believe your token
          has been compromised, you can generate a new one.
        </p>
      </div>
    </div>
  );
};

export default Smrt;
