import React from "react";
import HeadingSection from "../../components/heading/headingSection";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./chooseUs.scss";
import ChooseCard from "./chooseCard";
import DryCleaning from "../Fabicare/DryCleaning/DryCleaning";
import img from "../../images/stock_market_investments_growing_with_businessman_investor_management_business_finance_background_concept_business_finance_investment_20231.webp";
import Availability from "../Home/HomeSections/Availability/Availability";
import ByTeam from "../../components/ByTeam/ByTeam";
import Enhancing from "../Home/HomeSections/Enhancing/Enhancing";

const ChooseUs = () => {
  return (
    <div className="" style={{ backgroundColor: "#000" }}>
      <Navbar />
      {/* <div className="chooseWrapper"> */}
      <DryCleaning
        setpadding="setpadding"
        img={img}
        h5="Why Choose us"
        h3="Revolutionizing Customer Service with Smart Automation "
        p="Empower your team and delight your customers with our AI-driven solutions that simplify complex challenges"
      />
      {/* <div className="choose-cards-container">
          <ChooseCard
            topHeading="The Challenge: Overcoming Service Hurdles"
            bottomPara="Facing the dual challenge of fluctuating call volumes and a shortage of skilled staff? Our platform turns these obstacles into opportunities, reducing wait times and operational costs."
          />
          <ChooseCard
            topHeading="Our Innovative Approach: Automation Meets Empathy"
            bottomPara="Discover how our no-code automation platform transforms customer service, blending AI efficiency with the personal touch of human empathy to elevate customer satisfaction."
          />
          <ChooseCard
            topHeading="Instant Support, Anytime"
            bottomPara="Say goodbye to hold times. Our platform ensures customers receive immediate, 24/7 support, enhancing quality scores from the first interaction."
          />
          <ChooseCard
            topHeading="Empowering Agents"
            bottomPara="By automating routine inquiries, our system frees up your staff to tackle more complex issues, boosting their productivity and job satisfaction."
          />
          <ChooseCard
            topHeading="Cost Efficiency Redefined"
            bottomPara="Enjoy a 50% lower cost per contact compared to traditional agents, with scalable pricing that matches your actual usage, ensuring you never overpay."
          />
          <ChooseCard
            topHeading="Transformative Results"
            bottomPara="Witness measurable improvements in your operation with significant reductions in customer wait times, operational costs, and a boost in call capacity."
          />
        </div>
      </div> */}
      <div style={{ backgroundColor: "#fff" }}>
        <Enhancing padding="padding" />
      </div>
      <ByTeam />
      <div style={{ backgroundColor: "#fff" }}>
        <Availability />
      </div>
      <Footer />
    </div>
  );
};

export default ChooseUs;
