
import React, { useEffect, useState } from 'react';
import './SubscriptionCard.scss';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Modal } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SubscriptionCard = ({ h3, h6, li1, li2, li3, li4, h5, active }) => {
  console.log("active subscription", active);
  const stripe = useStripe();
  const elements = useElements();
  const [showModal, setShowModal] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [Overfees, setOverfees] = useState({ totalCost: 0, customer: { OverageFees: 0 } });
  const navigate = useNavigate()
  const handleGetStarted = () => {
    setShowModal(true);

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/TwilloCallDuration`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        setOverfees(response.data);
      })
      .catch((error) => {
        console.error(error);
        if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
      });
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaymentProcessing(true);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement._complete) {
      alert('Please enter complete card details.');
      setPaymentProcessing(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error('[Payment Error]', error);
      alert('Payment failed: ' + error.message);
      setPaymentProcessing(false);
      return;
    }

    const amountInCents =
   // Convert h5 to cents
      parseFloat(Overfees.totalCost ?? 0) +  // Convert Overfees.totalCost to cents
      parseFloat(Overfees.customer.OverageFees ?? 0);

    console.log("amountInCents", amountInCents);

    const response = await fetch(`${process.env.REACT_APP_API_URL2}/customer/stripe-api`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        paymentMethodId: paymentMethod.id,
        amount: h5,
        overfees:  amountInCents// Default to 0 if undefined
      }),
    });

    const paymentResult = await response.json();

    console.log(paymentResult);
    if (paymentResult.error) {
      console.error('[Payment Error]', paymentResult.error);
    } else {
      toast.success("Payment successful");
    }

    setPaymentProcessing(false);
    setShowModal(false);
    window.location.reload();
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL2}/customer/stripe-api`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });

  //     const data = await response.json();
  //     console.log("Stripe API Data:", data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);


  const currentDate = new Date();
  // Calculate the date 30 days from now
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 30);
  // Format the date as YYYY-MM-DD
  const formattedDate = futureDate.toISOString().split('T')[0];


  return (
    <div className="Subscription-card-box">
      <div className={`${active ? "active-plan" : ""}`}>
        <h3>{h3}</h3>
        <h6>{h6}</h6>
        <ul>
          {li1 && (<li className="flex items-start gap-1">
            <img src="../../../../assets/images/DashboardImages/icons/bxs_check_circle(2)1.webp" alt="" />
            {li1}
          </li>)}
          {li2 && (<li className="flex items-start gap-1">
            <img src="../../../../assets/images/DashboardImages/icons/bxs_check_circle(2)1.webp" alt="" />
            {li2}
          </li>)}
          {li3 && (<li className="flex items-start gap-1">
            <img src="../../../../assets/images/DashboardImages/icons/bxs_check_circle(2)1.webp" alt="" />
            {li3}
          </li>)}
          {li4 && (<li className="flex items-start gap-1">
            <img src="../../../../assets/images/DashboardImages/icons/bxs_check_circle(2)1.webp" alt="" />
            {li4}
          </li>)}
        </ul>
        {h5 && (<h5>${h5}<span>/month</span></h5>)}
      </div>

      {/* Conditional button rendering */}
      {active ? (
        <button className="themeGlow-btn w-full disabled-btn" disabled>
          Subscribed
        </button>
      ) : (
        <button className="themeGlow-btn w-full" onClick={handleGetStarted}>
          Get Started
        </button>
      )}
      
      <Modal
        title="Complete Payment"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        {h5 ? (
          <form onSubmit={handlePaymentSubmit}>
            <div className="payment-details">
              <div className="card-element">
                <CardElement />
              </div>
              <h5>Amount to be paid:</h5>
              <div className="amount-row">
                <p>Plan Price: <strong>${h5}</strong></p>
              </div>
              <div className="amount-row">
                <p>Overfees For Chat: <strong>${Overfees.totalCost || 0}</strong></p>
              </div>
              <div className="amount-row">
                <p>Overfees For Call: <strong>${Overfees.customer.OverageFees || 0}</strong></p>
              </div>
              <div className="amount-row">
              <p>Expected Expire Date: <strong>{formattedDate}</strong></p>
            </div>
            </div>
            <button type="submit" className="themeGlow-btn w-full" disabled={!stripe || paymentProcessing}>
              {paymentProcessing ? 'Processing...' : 'Pay Now'}
            </button>
          </form>
        ) : (
          <>
            <h5>Custom pricing based on usage</h5>
            <p>Please contact our support team at <a href="mailto:ben@farbricare.com">ben@farbricare.com</a> for assistance.</p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default SubscriptionCard;
