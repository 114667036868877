import React from "react";
import "./BusinessBenefits.scss";
import BusinessBenefitsCard from "../BusinessBenefitsCard/BusinessBenefitsCard";

const BusinessBenefits = () => {
  return (
    <div className="businessBenefits-main-container d-flex flex-column">
      <h2>Business Benefits</h2>
      <div className="businessBenefits-centered-container">
        <BusinessBenefitsCard
          img="../../../../assets/images/Group 427318210.svg"
          h5="Boost Agent Productivity"
          p="Thanks to Agent Assist, human agents can focus on creating better customer experiences rather than wasting time looking for information in the script of other documents."
        />
        <BusinessBenefitsCard
          img="../../../../assets/images/Group 427318208.svg"
          h5="Reduce Mean Time to Resolve (MTTR) of Customer Issues"
          p="With AI agents assist in place, agents always know what to say. It leads to faster resolution of customer queries and lower MTTR."
        />
        <BusinessBenefitsCard
          img="../../../../assets/images/Group 427318212sss.svg"
          h5="Cut Down Customer Support Costs"
          p="Real-time agent assist saves a great deal of time, enabling human agents to attend to more customers and reducing the average cost per customer."
        />
      </div>
    </div>
  );
};

export default BusinessBenefits;
