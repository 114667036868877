import React from 'react'
import './BusinessBenefitsCard.scss'

const BusinessBenefitsCard = ({img, h5, p}) => {
    return (
        <div className='businessBenefits-inner-box'>
            <img src={img} alt='' />
            <h5>{h5}</h5>
            <p>{p}</p>
        </div>
    )
}

export default BusinessBenefitsCard