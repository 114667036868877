import { Icon } from '@iconify/react';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleParentExpand } from 'reactflow';
import axios from 'axios';
import {setTags} from "../../../../redux/action"
import { toast } from 'react-toastify';

const LoaderFAQ2 = ({ linkBack, linkNext }) => {
    const navigate = useNavigate();

    const [askQustion , setAskquestion] = useState();
    const [Answer, setAnswer] = useState();
    const [clciked , setClciked] = useState(false);
    const [tags, setTags] = useState(['Order', 'Delivery']);
    const [newTag, setNewTag] = useState('');
    //Redux Variable 
    const dispatch = useDispatch();
    const knowId = useSelector(state => state.know_Id)
    const recordingFile = useSelector(state => state.recordingFile);
    const know_Id = useSelector((state) => state.know_Id);
    const handlequsetion = (event) =>{
        setAskquestion(event.target.value)
    }

    const handleAnswer = (event) =>{
        setAnswer(event.target.value)
    }
    
    const handleSubmit = () =>{
        // console.log(knowId);
        if(!knowId)return toast.warning("knowledge ID not available")
        if(!askQustion)return toast.warning("Please enter Question");
        if(!Answer)return toast.warning("Please enter answer")
        if(clciked)return toast.warning("Please wait for it while it process")
        axios.post( `${process.env.REACT_APP_API_URL2}/customer/knowledege`,
        {
            "ID":knowId,
            "DryCleaningFAQ":[{
                "Question":askQustion,
                "Answer":Answer,
                "Tags":tags.map(tag => ({ Tag: tag }))
            }]    
        },
        {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        },
      
        ).then((response)=>{
            // console.log("Data retrieved:", response);
            fetchData()
            navigate('/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs')
        }).catch((error)=>{
            console.log(error);
            if (error.response?.data?.success === false) {
                // Redirect to login page
                navigate('/login');
              }
            if (error.response?.data?.paymentIssue === true) {
                console.log('Payment issue detected, redirecting to settings...');
                navigate('/settings'); // Ensure you're using string URL correctly
              }
        })
    }


    const fetchData = async () => {
 try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL2}/customer/getAllKnowledges`,
            {
              AgentID:know_Id ,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
        //   console.log("data id given ",response.data);
          dispatch(setTags(response.data));
        } catch (error) {
          console.log(error);
          if (error.response?.data?.success === false) {
            // Redirect to login page
            navigate('/login');
          }
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        }
      }; // Call fetchData function



      const addTag = () => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag('');
        }
        // console.log("tag added",tags);
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    return (
        <>
            <div className='flex justify-between items-center gap-3 mb-4'>
                <>
                    <div className='loaderFaq-box pt-5'>
                        <label>Question/Objection</label>
                        <br />
                        <input type='text' placeholder='Ask question' onChange={handlequsetion} value={askQustion}  />
                        <br />
                        <br />
                        <label>Tags</label>
                        <br />
                        <div className='tags-div flex gap-2'>
                            {tags.map((tag, index) => (
                                <div>
                                <span> <Icon icon="akar-icons:circle-minus-fill" style={{ color: 'red', cursor: 'pointer', marginBottom:"-10px", right: '-10px',}} onClick={() => removeTag(tag)} /></span>
                                <span key={index} className='theme-btn-light-pill'> {tag} </span>
                                </div>
                                
                       
                    ))}
                    <input type="text" placeholder="New tag" value={newTag} onChange={e => setNewTag(e.target.value)} />
                        </div>

                        <div className='flex justify-between items-center pt-3'>
                            <label>Answer/Response</label>
                            <p className='primaryClr flex items-center gap-2 cursor-pointer' onClick={addTag}><Icon icon="ic:baseline-plus" style={{ color: '4B2E83' }} />Add Tag</p>
                        </div>
                        <textarea rows="4" cols="50" type='text' placeholder="Your answer will appear here" value={Answer} onChange={handleAnswer}></textarea>
                    </div>
                </>
            </div>

            <div className='flex items-center gap-2'>
                <button className=' back-btn mt-4' onClick={() => navigate(`${linkBack}`)}>Back</button>
                <button className='theme-btn-light'
                onClick={() => {
                    handleSubmit()
                    setClciked(true)
                }}
                >Save</button>
            </div>
        </>
    )
}

export default LoaderFAQ2

