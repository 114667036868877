import {
  SET_AGENT_ID,
  SET_Name,
  SET_file,
  SET_record,
  SET_faqs,
  SET_modal,
  SET_know_Id,
  SET_load_file,
  SET_tags_Faqs,
  SET_Knowledge,
  SET_Agentkeys,
  SetAgentkeys,
  SET_AgentData,
} from "./action";

// Define the initial state
const initialState = {
  agentId: null,
  name: null,
  file: null,
  recordingFile: null,
  faqs: [],
  modal: null,
  know_Id: null,
  load_file: false,
  tags_Faqs: null,
  knowledgeBase: null,
  Agentkeys: null,
  agentData: null
};

// Define the reducer
function agentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AGENT_ID:
      return {
        ...state,
        agentId: action.payload,
      };
    case SET_Name:
      return {
        ...state,
        name: action.payload,
      };
    case SET_file:
      return {
        ...state,
        file: action.payload,
      };
    case SET_record:
      return {
        ...state,
        recordingFile: action.payload,
      };
    case SET_faqs:
      return {
        ...state,
        faqs: action.payload,
      };
    case SET_modal:
      return {
        ...state,
        modal: action.payload,
      };
    case SET_know_Id:
      return {
        ...state,
        know_Id: action.payload,
      };
    case SET_load_file:
      return {
        ...state,
        load_file: action.payload,
      };
    case SET_tags_Faqs:
      return {
        ...state,
        tags_Faqs: action.payload,
      };
    case SET_Knowledge:
      return {
        ...state,
        knowledgeBase: action.payload,
      };
    case SET_Agentkeys:
      return {
        ...state,
        Agentkeys: action.payload,
      };
    case SET_AgentData:
      return {
       ...state,
        agentData: action.payload,
      };
    case SetAgentkeys:
    default:
      return state;
  }
}

// Export the reducer
export default agentReducer;
