import React from 'react'
import './AIAgent.scss'
import AIHero from './AIHero/AIHero'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import AIAssist from './AIAssist/AIAssist'
import BusinessBenefits from './BusinessBenefits/BusinessBenefits'

const AIAgent = () => {
    return (
        <div className='AIAgent-main-container'>
            <Navbar />
            <AIHero />
            <AIAssist />
            <BusinessBenefits />
            <Footer />
        </div>
    )
}

export default AIAgent