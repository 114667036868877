


import React, { useEffect, useState } from "react";
import Calltable from "./logstables/calltable";
import { Outlet, useNavigate } from "react-router-dom";
import "./logs.scss";
import axios from "axios";

const Calllogs = () => {
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate()


  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/TwilloCalllogs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("Response data:", response.data);
        setData(response.data.calls);
        setFilteredData(response.data.calls);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        setLoading(false);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  }, []);

  useEffect(() => {
    if (data) {
      filterData();
    }
  }, [fromDate, toDate, searchTerm]);

  const filterData = () => {
    let filtered = data;

    if (fromDate) {
      filtered = filtered.filter(
        (call) => new Date(call.dateCreated) >= new Date(fromDate)
      );
    }

    if (toDate) {
      filtered = filtered.filter(
        (call) => new Date(call.dateCreated) <= new Date(toDate)
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(
        (call) =>
          call.toFormatted.includes(searchTerm) ||
          call.fromFormatted.includes(searchTerm) ||
          call.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredData(filtered);
  };

  return (
    <>
      <div className="logs-details-container">
        <div className="details-top-bar d-flex gap-4">
          <img
            src=".../../../assets/images/DashboardImages/images/robot.webp"
            alt="Bot"
            width={88}
            height={88}
          />
          <span className="h-center flex-column span-txt">
            <h3 className="heading-4 d-flex v-center gap-5">Sales Assistant</h3>
            Context-aware AI Sales Assis.
          </span>
        </div>

        <div className="calls-logs d-flex flex-column">
          <h5 className="heading-5 text-center">Call Logs</h5>

          <div className="filters d-flex gap-4 mb-3">
          <div className="search-bar">
          <input
            type="text"
            placeholder="Search by number or status"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
            <div>
              <label>From Date: </label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div>
              <label>To Date: </label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
           
          </div>

          {loading ? (
            <div className="loading-spinner">Loading...</div>
          ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
            <Calltable data={filteredData} />
          ) : (
            <div>No call logs available</div>
          )}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Calllogs;
