
import React, { useState, useRef, useEffect } from "react";
import "./chatbotcopy.scss";
import { Icon } from "@iconify/react";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import ReactMarkdown from 'react-markdown'; // Import react-markdown

import img from "../../images/Fabriccare-012.png";
import img1 from "../../images/Fabriccare-01.png";
import { useNavigate } from "react-router-dom";

const Chatbot = ({ Agent_id, onclose }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState("");
  const [ChatBot, setIsChatBot] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [isdisable, setIsdisable] = useState(false);
  const [fingerprint, setFingerprint] = useState("");
  const [callSid, setCallSid] = useState("");
  const chatMiddleRef = useRef(null);
  const fileInputRef = useRef(null);
 
  
  useEffect(() => {
    const scrollToBottom = () => {
      if (chatMiddleRef.current) {
        chatMiddleRef.current.scrollTop = chatMiddleRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [ChatBot]);

  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;
      setFingerprint(visitorId);
    };

    getFingerprint();
  }, []);

  async function fetchData(postData, index) {
    try {
      const response = await axios.post(
        `https://octa.octalooptechnologies.com/chat/${postData.Agent_id}`,
        postData
      );

      setIsLoadingResponse(false);
      setIsChatBot((prevChatBot) => [
        ...prevChatBot,
        { speaker: "ai", message: response.data.response },
      ]);
      setMessage("");
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  }

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    setTextLoading(event.target.value !== "");
  };

  const handleClick = () => {
    setTextLoading(false);
  };

  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  const handleCloseClick = () => {
    onclose();
    setTextLoading(false);
  };

  const clickonsend = () => {
    if (!message) return "";
    if (isdisable) {
      return;
    }
    if (isLoadingResponse) return;

    setIsChatBot((prevChatBot) => [
      ...prevChatBot,
      { speaker: "user", message: message },
    ]);

    setIsLoadingResponse(true);
    const postData = {
      input_text: message,
      user_id: fingerprint,
      Agent_id: Agent_id
    };
    
    fetchData(postData, ChatBot.length);
    setMessage("");
    setTextLoading(false);
    setShowEmojiPicker(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!message || message.trim() === "" || message === null || message === undefined) return;

    if (isdisable) {
      return;
    }
    if (isLoadingResponse) return;

    setIsChatBot((prevChatBot) => [
      ...prevChatBot,
      { speaker: "user", message: message },
    ]);

    setIsLoadingResponse(true);
    const postData = {
      input_text: message,
      user_id: fingerprint,
      Agent_id: Agent_id
    };
    fetchData(postData, ChatBot.length);
    setMessage("");
    setTextLoading(false);
  };

  const handleEmojiClick = (emojiData, event) => {
    setMessage(message + emojiData.emoji);
    setShowEmojiPicker(false);
    setTextLoading(false);
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
    setIsLoadingResponse(true);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // console.log("Selected file:", selectedFile);
  };

  return (
    <>
      <div className="chat-bot-container" onClick={handleClick}>
        <div className="chat-bot copy" onClick={handleInnerClick}>
          <div className="chat-top v-center space-between">
            <div className="left v-center gap-2">
              <img src={img} alt="" />
              <h6>AI Chat Assistance</h6>
            </div>
            <div className="right" onClick={handleCloseClick}>
              <Icon
                color="#fff"
                icon="maki:cross"
                width={12}
                height={12}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="chat-middle" ref={chatMiddleRef}>
            {ChatBot.length === 0 && (
              <p className="chatBot-welcomeTxt">
                Welcome to <span>Fabri care.</span>
                <br />
                How may I <span>Help</span> you Today
              </p>
            )}
            {ChatBot.map((msg, index) => {
              if (msg.speaker === "user") {
                return (
                  <div key={index} className="sender-txt-div v-center">
                    <p className="sender-txt">{msg.message}</p>
                  </div>
                );
              } else {
                return (
                  <div key={index} className="received-txt-div v-center gap-2">
                    <span>
                      <img src={img1} alt="" />
                    </span>
                    <p className={`received-txt ${index !== 0 ? "ml-5" : ""}`}>
                    <ReactMarkdown
                    components={{
                      a: ({node, ...props}) => (
                        <a
                          {...props}
                          style={{ color: '#1e90ff', textDecoration: 'none' }} // Change the color here
                        >
                          {props.children}
                        </a>
                      )
                    }}
                  >
                  {msg.message}
                  </ReactMarkdown>
                    </p>
                  </div>
                );
              }
            })}
            {isLoadingResponse && <div className="loader"></div>}
            <div className="flex justify-end">
              {textLoading && <div className="loader"></div>}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="chat-bottom v-center space-between gap-3">
              <input
                type="text"
                placeholder="Type your Message..."
                onChange={handleInputChange}
                value={message}
                disabled={isLoadingResponse}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                disabled={isLoadingResponse}
              />
              <div className="bottom-left v-center gap-2">
                <Icon
                  className="footer-icon"
                  color="#C3C3C3"
                  icon="ri-send-plane-fill"
                  width={20}
                  height={20}
                  onClick={clickonsend}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Chatbot;
