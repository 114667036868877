import React, { useState } from "react";
import "./CreateAgentBrief.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const CreateAgentBrief = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  // console.log(location.pathname);
  return (
    <div className="CreateAgentBrief-main-container">
      <div className="CreateAgentBrief-centered-container">
    <h5>Setup Your AI Calling and Chat Assistant </h5>
        <div className="CreateAgentBrief-inner-container">
          <div className="nav-switch">
            <button
              className={
                pathname === "/dashboard/CreateAgentBrief/AgentInfo"
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/dashboard/CreateAgentBrief/AgentInfo");
                window.location.reload("/dashboard/CreateAgentBrief/AgentInfo");
              }}

            >
              Agent Info
            </button>
            <button
              className={
                pathname === "/dashboard/CreateAgentBrief/Script"
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/dashboard/CreateAgentBrief/Script");
              }}

            >
              Assign Agent Flow
            </button>
            <button
              className={
                pathname.includes("/dashboard/CreateAgentBrief/KnowledgeLibrary")
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/dashboard/CreateAgentBrief/KnowledgeLibrary");
              }}

            >
              Knowledge Library
            </button>

            <button
              className={
                pathname === "/dashboard/CreateAgentBrief/Configuration"
                  ? "active"
                  : ""
              }
              onClick={() => {
                navigate("/dashboard/CreateAgentBrief/Configuration");
              }}

            >
              Configuration
            </button>
            <button
              className={
                pathname === "/dashboard/CreateAgentBrief/Webhooks" ? "active" : ""
              }
              onClick={() => {
                navigate("/dashboard/CreateAgentBrief/Webhooks");
              }}

            >
              Webhooks
            </button>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CreateAgentBrief;
