


import { Icon } from '@iconify/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LinkPill = ({ img, p, arrow, link, selected , onSelectAgent }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [showOptions, setShowOptions] = useState(false);

    const handlePillClick = () => {
        if (!arrow) {
            navigate(link);
        } else {
            setShowOptions(!showOptions);
        }
    };

 



    const handleReload = async () => {
        console.log("Reloading");
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL2}/customer/getAllAgent`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          ); 


          const agentsWithAllOption = [
            { _id: 'all', AgentName: 'All' },
            ...response.data.response,
          ];

          setData(agentsWithAllOption);
        } catch (error) {
          console.log(error);
          if (error.response?.data?.success === false) {
            // Redirect to login page
            navigate('/login');
          }
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        }
      };
      useState(() => {
        handleReload();
      }, []);


      const handleOptionClick = (option) => {
        onSelectAgent(option); // Pass the selected agent to the parent
        setShowOptions(false); // Close the dropdown after selection
    };

    return (
        <div 
            className={`linkPill ${selected ? 'selected' : ''}`} 
            onClick={handlePillClick}
        >
            <span>
                <img src={img} alt="" style={{ width: "30px", height: "30px" }} />
            </span>
            <p>{p}</p>
            {arrow && (
                <div className="linkPill-arrow-select">
                    <Icon 
                        icon="iconamoon:arrow-down-2-bold" 
                        className='mt-2' 
                        style={{ color: '#fff' }} 
                    />
                    {showOptions && (
                        <div className="linkPill-options">
                        {data.map((option, index) => (
                            <div 
                                key={index} 
                               value={option._id}
                               onClick={() => handleOptionClick(option)}
                            >
                                {option.AgentName}
                            </div>
                        ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
};

export default LinkPill;

