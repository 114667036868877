import React from "react";
import "./PricingCard.scss";

const PricingCard = ({
  pill,
  h5,
  p,
  li1,
  li2,
  li3,
  btn,
  bgshadow,
  pillcolor1,
  pillcolor2,
  pillcolor3,
}) => {
  return (
    <div className={`pricingCard-box ${bgshadow ? "bgshadow" : ""}`}>
      <div className="pricing-top">
        <div
          className={`price-pill ${pillcolor1 ? "pillcolor1" : ""}  ${
            pillcolor2 ? "pillcolor2" : ""
          }   ${pillcolor3 ? "pillcolor3" : ""}`}
        >
          {pill}
        </div>
        <h5>
          {h5}
          <span>/month</span>
        </h5>
        <p>{p}</p>
      </div>

      <hr />
      <div className="pricing-bottom">
        <ul>
          <li>
            <img src="../../../../assets/icons/Union.png" alt="" />
            {li1}
          </li>
          <li>
            <img src="../../../../assets/icons/Union.png" alt="" />
            {li2}
          </li>
          <li>
            <img src="../../../../assets/icons/Union.png" alt="" />
            {li3}
          </li>
        </ul>
        <button className="select-lite-btn">{btn}</button>
      </div>
    </div>
  );
};

export default PricingCard;
