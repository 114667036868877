import React from "react";
import "./trusted.scss";
import img1 from "../../../src/images/brandName1.png";
import img2 from "../../../src/images/brandName2.png";
import img3 from "../../../src/images/brandName3.png";
import img4 from "../../../src/images/brandName4.png";
import img5 from "../../../src/images/brandName5.png";
import img6 from "../../../src/images/brandName6.png";
import img7 from "../../../src/images/brandName7.png";

const Trusted = () => {
  return (
    <div className="trustedContainer">
      <h2>
        Trusted by {""}<span>Many</span>
      </h2>
      <div className="brandsName">
        <img src={img1} alt="" />
        <img src={img2} alt="" />

        <img src={img3} alt="" />
        <img src={img4} alt="" />

        <img src={img5} alt="" />

        <img src={img6} alt="" />

        <img src={img7} alt="" />

      

      </div>
    </div>
  );
};

export default Trusted;
