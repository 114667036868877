


import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Spin } from "antd"; // Ant Design Modal, Button, and Spin
import "./logstable.scss";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";
import { useNavigate } from "react-router-dom";

const Calltable = ({ data }) => {
  const [visibleCalls, setVisibleCalls] = useState(20);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCall, setSelectedCall] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [Data, setData] = useState(null);
  const [calllogs, setCalllogs] = useState([]); // Initialize calllogs as an empty array

  const audioPlayerRef = useRef(null); // Reference to the audio player


  const navigate = useNavigate()

  const loadMoreCalls = () => {
    setVisibleCalls((prevVisible) => prevVisible + 20);
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("Data given:", response.data);
        setData(response.data.Response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const fetchAudioUrl = async (call) => {
    if (!Data) {
      console.log(
        "Please set the Account SID and Auth Token in your environment variables."
      );
      return;
    }

    const { accountSID, authtoken } = Data;

    console.log("Fetching audio for Call SID:", call.sid);

    // Fetch the call logs and set the state
    let data = JSON.stringify({
      sid: call.sid,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/GetCallChat`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Call logs received:", response.data.data);
        setCalllogs(response.data.data); // Set the call logs properly
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });

    // Fetch audio data
    const fileFormat = "mp3";
    const listRecordingsUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Recordings.json?CallSid=${call.sid}`;

    try {
      const response = await axios({
        method: "get",
        url: listRecordingsUrl,
        auth: {
          username: accountSID,
          password: authtoken,
        },
      });

      const recordings = response.data.recordings;

      if (recordings.length === 0) {
        console.log("No recordings found for this Call SID.");
        setAudioUrl("");
        return;
      }

      const recordingSid = recordings[0].sid;
      const recordingUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSID}/Recordings/${recordingSid}.${fileFormat}`;

      setIsLoadingAudio(true);

      try {
        const responseAudio = await axios({
          method: "get",
          url: recordingUrl,
          auth: {
            username: accountSID,
            password: authtoken,
          },
          responseType: "arraybuffer",
        });

        const audioData = responseAudio.data;
        const audioBase64 = arrayBufferToBase64(audioData);
        const audioLink = `data:audio/${fileFormat};base64,${audioBase64}`;

        setAudioUrl(audioLink);
        setIsLoadingAudio(false);
      } catch (error) {
        console.error(`Failed to download recording: ${error.message}`);
        setAudioUrl("");
        setIsLoadingAudio(false);
      }
    } catch (error) {
      console.error(`Failed to retrieve recordings: ${error.message}`);
      setAudioUrl("");
      setIsLoadingAudio(false);
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  const showModal = async (call) => {
    setSelectedCall(call);
    await fetchAudioUrl(call);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.audioEl.current.pause(); // Stop the audio playback
    }
    setIsModalVisible(false);
    setAudioUrl(""); // Reset the audio URL
    setSelectedCall(null); // Clear the selected call
    setCalllogs([]); // Reset the call logs
  };

  return (
    <>
      <div className="logtable-div relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead>
            <tr>
              <th scope="col">To</th>
              <th scope="col">From</th>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
              <th scope="col">Status</th>
              <th scope="col">Duration (mins)</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.slice(0, visibleCalls).map((call, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="tr-dark">{call.toFormatted || "N/A"}</td>
                <td className="tr-dark">{call.fromFormatted || "N/A"}</td>
                <td>
                  {call.dateCreated
                    ? new Date(call.dateCreated).toLocaleDateString()
                    : "N/A"}
                </td>
                <td>
                  {call.dateCreated
                    ? new Date(call.dateCreated).toLocaleTimeString()
                    : "N/A"}
                </td>
                <td>{call.status || "N/A"}</td>
                <td>{call.duration ? formatDuration(call.duration) : "N/A"}</td>
                <td>
                  <button
                    className="theme-btn-light-pill"
                    onClick={() => showModal(call)}
                  >
                    See Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {visibleCalls < data.length && (
          <div className="load-more-container text-center m-4">
            <button onClick={loadMoreCalls} className="theme-btn-light-pill">
              Load More
            </button>
          </div>
        )}

        <Modal
        title="Call Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedCall && (
          <div className="call-details">
            <p>
              <strong>To:</strong> {selectedCall.toFormatted || "N/A"}
            </p>
            <p>
              <strong>From:</strong> {selectedCall.fromFormatted || "N/A"}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {selectedCall.dateCreated
                ? new Date(selectedCall.dateCreated).toLocaleDateString()
                : "N/A"}
            </p>
            <p>
              <strong>Time:</strong>{" "}
              {selectedCall.dateCreated
                ? new Date(selectedCall.dateCreated).toLocaleTimeString()
                : "N/A"}
            </p>
            <p>
              <strong>Status:</strong> {selectedCall.status || "N/A"}
            </p>
            <p>
              <strong>Duration:</strong>{" "}
              {selectedCall.duration
                ? formatDuration(selectedCall.duration)
                : "N/A"}
            </p>
      
            {/* Render Call Logs */}
            <strong> Call Transcription:</strong>
            <div className=" Messages-chatbot">
            {calllogs && calllogs.length > 0 && calllogs[0].transcription ? (
              calllogs[0].transcription.map((msg, index) =>
                msg.speaker === "human" ? (
                  <div key={index} className="message-container human-message">
                    <div className="message human">
                      <p>{msg.message}</p>
                    </div>
                  </div>
                ) : (
                  <div key={index} className="message-container ai-message">
                    <div className="message ai">
                      <p>{msg.message}</p>
                    </div>
                  </div>
                )
              )
            ) : (
              <p>No chat logs available for this call.</p>
              )}
              </div>
              
      
            {isLoadingAudio ? (
              <div className="audio-loading">
                <Spin size="large" />
              </div>
            ) : audioUrl ? (
              <p>
              <strong> Call Recording :</strong>
              <ReactAudioPlayer
              ref={audioPlayerRef} // Reference the audio player
              src={audioUrl}
              autoPlay
              controls
              />
              </p>
            ) : (
              <p>No audio available for this call.</p>
            )}
          </div>
        )}
      </Modal>
      
      </div>
    </>
  );
};

export default Calltable;
