import React from 'react'
import './LiveChatBenefits.scss'
import LiveChatBenefitsCard from '../LiveChatBenefitsCard/LiveChatBenefitsCard'

const LiveChatBenefits = () => {
    return (
        <div className='LiveChatBenefits-main-container'>
            <h2>Benefits</h2>
            <div className='LiveChatBenefits-centered-container'>
                <LiveChatBenefitsCard h5="Real-time Assistance" p="Live chat enables businesses to provide immediate support to customers, offering solutions to problems or answering questions without delay. This real-time interaction can lead to quicker issue resolution and a more positive customer experience. " h3="Real-time Assistance" />
                <LiveChatBenefitsCard h5="Convenience" p="Live chat is a convenient support option for customers as they can easily access assistance from anywhere with an internet connection. It eliminates the need for customers to wait on hold or send emails, providing a more efficient way to get help." h3="Convenience" />
                <LiveChatBenefitsCard h5="Cost-effective" p="Live chat can be more cost-effective for businesses compared to traditional phone support. Agents can handle multiple chats simultaneously, reducing the need for a large support team and lowering overall support costs." h3="Cost-effective" />
                <LiveChatBenefitsCard h5="Increase in sales" p="Live chat can help increase sales by providing customers with product information, assisting with the purchasing process, and addressing any concerns or objections that may be preventing a sale. " h3=" Increase in sales" />
                <LiveChatBenefitsCard h5="24/7 availability" p="Round-the-clock  24/7 availability can be particularly beneficial for businesses with customers in different time zones or those who require support outside of regular business hours." h3="24/7 availability" />
            </div>
        </div>
    )
}

export default LiveChatBenefits