import React, { useEffect } from "react";
import "./howItWork.scss";
import HeadingSection from "../../components/heading/headingSection";
import WorkCard from "./workCard";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { NavLink } from "react-router-dom";
import DryCleaning from "../Fabicare/DryCleaning/DryCleaning";
import img from "../../images/young_male_designer_using_graphics_tablet_while_working_with_com1.webp";
import Enhancing from "../Home/HomeSections/Enhancing/Enhancing";
import ByTeam from "../../components/ByTeam/ByTeam";
import Availability from "../Home/HomeSections/Availability/Availability";

const HowItWork = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="" style={{ backgroundColor: "#000" }}>
      <Navbar />
      <DryCleaning
        setpadding="setpadding"
        img={img}
        h5="How it Works"
        h3="Simplicity Meets Innovation: Tech Made Simple "
        p="Transform your customer service with AI. Simple, powerful, no tech expertise needed"
      />
      {/* <HeadingSection
          main="How it Works"
          sub="Simplicity Meets Innovation: Tech Made Simple"
          paragraph=" Transform your customer service with AI. Simple, powerful, no tech
          expertise needed"
        /> */}
      {/* <div className="howItWorkContainer">
          <WorkCard
            boldText="Effortless Integration, Infinite Possibilities"
            normalText="Seamlessly connect our AI platform with your existing software—enhancing capabilities without replacing your current setup."
          />
          <WorkCard
            boldText="Intuitive AI, Real Conversations"
            normalText="Empower your customers to interact using their own words, thanks to our platform's natural language understanding, ensuring each conversation is as real as it gets."
          />
          <WorkCard
            boldText="Instant Solutions, Happier Customers"
            normalText="Watch as our AI provides accurate answers in seconds, boasting a high accuracy rate that rivals human interaction."
          />
          <WorkCard
            boldText="Contextual Escalations, Smoother Transitions"
            normalText="Our AI smartly escalates complex issues to your staff, providing full context to avoid repetition and ensure a seamless service experience."
          />
          <WorkCard
            boldText="Automated Logging, Streamlined Operations"
            normalText="Efficiency redefined—every conversation and its outcome are auto-logged into your systems, keeping records up to date effortlessly."
          />
          <WorkCard
            boldText="Ongoing Optimization, Enhanced Performance"
            normalText="Leverage our powerful analytics to continually refine AI interactions, ensuring your customer service evolves with your clients' needs."
          />
        </div> */}
      {/* <div className="purpleDiv"></div>
        <NavLink to="/demo">   <button className="demoBtn">Request Demo</button></NavLink> */}
      <div style={{ backgroundColor: "#fff" }}>
        <Enhancing padding="padding" />
      </div>
      <ByTeam />
      <div style={{ backgroundColor: "#fff" }}>
        <Availability />
      </div>
      <Footer />
    </div>
  );
};

export default HowItWork;
