import React, { useState, useRef, useEffect } from "react";
import "./Script.scss";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import img from '../../../../../public/assets/icons/cross.png'
import { useDispatch, useSelector } from "react-redux";
import { setAgentId } from "../../../../redux/action";
import ScriptTable from "./ScriptTabe";

const Script = ({ link }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // input values
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [Data, setData] = useState("");
  const modalRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  // redux values
  let agentId = useSelector((state) => state.agentId);
  if (!agentId) {
    //  console.log("agentId");
    agentId = localStorage.getItem("agentID");
  }
console.log("agentId: " + agentId);
  const dispatch = useDispatch();
  //handle values change
  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  async function fetchData(postData) {
    try {
      console.log("HOI", postData);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/agentflow`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log("Data retrieved:", response);
          dispatch(setAgentId(agentId));
          toast.success("AgentFlow Created successfully",{toastId:1});
          navigate(`/dashboard/flowchart/${response.data.Response._id}`);
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleSubmit = (index) => {
    if(!Name) return toast.warning("Please enter a name",{toastId:"1"});
    if(!Description) return toast.warning("Please enter a Descripation ",{toastId:"1"});
     console.log(Name);
    console.log(Description);
    console.log(index);
    const postData = {
      Name: Name,
      Description: Description,
    };
    fetchData(postData);
  };

  const handleNEXT = (index) => {
    if (location.pathname.includes("/updateAgentBrief")){

      navigate("/updateAgentBrief/KnowledgeLibrary");
    }
    else{

      navigate("/dashboard/CreateAgentBrief/KnowledgeLibrary");
    }
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const getAllAgentFlows = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL2}/customer/getAllagentflow`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data.Response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };
  useEffect(() => {
    getAllAgentFlows();
  }, []);


  const handleEdit = (id) => {
    console.log("ID",id);
    dispatch(setAgentId(agentId));
    navigate(`/dashboard/flowchart/${id}`);
  };  


 

  return (
    <div className="Script-main-container">
      <div className="Agent-details-container">
        <ScriptTable searchTerm={agentId}  Edit={handleEdit}/>
      </div>
      <div className="mt-5 flex justify-between">
        <button className="theme-btn-light" onClick={() => handleOpenModal()}>
          Create Agent Flow
        </button>
        <button
          className="theme-btn-light flex justify-center items-center gap-2"
          onClick={() => handleNEXT()}
        >
          Next
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mt-1"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M13.666 8.3183L3.66602 8.3183"
              stroke="#4B2E83"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.63281 4.30126L13.6661 8.31726L9.63281 12.3339"
              stroke="#4B2E83"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div>
        {isOpen && (
          <div className="modal-backdrop" onClick={(e) => e.stopPropagation()}>
            <div className="Agent-details-container">
              <div
                className="modal"
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
              >
                <div>
                  <div className="Agent-details-container">
                    <label>Name</label>
                    <input
                      className="input-dash mt-3"
                      type="text"
                      value={Name}
                      onChange={handleName}
                    />
                  </div>
                  <div className="Agent-details-container">
                    <label>Descriptions</label>
                    <textarea
                      rows="4"
                      cols="50"
                      className="input-dash mt-3"
                      type="text"
                      value={Description}
                      onChange={handleDescription}
                    ></textarea>
                  </div>
                  <div className="mt-5 flex justify-between">
                    <button
                      className="theme-btn-light"
                      onClick={() => handleSubmit("1")}
                    >
                      Generate Flow
                    </button>
                    <button
                      className="theme-btn-light"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    
    </div>
  );
};

export default Script;
