import React, { useEffect, useState } from "react";
import "./ChatWithAgent.scss";
import { Icon } from "@iconify/react";
import AgentChatBot from "./AgentChatBot/AgentChatBot";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import img from '../../../../public/assets/images/DashboardImages/icons/tabler_filter.webp'

const ChatWithAgent = () => {
  const [Data, setData] = useState("");
  const [ChatData, setChatData] = useState("");
  const [loading, setLoading] = useState(true);
  const [graphql, setGraphQl] = useState("");
  const [combinedata, setCombinedata] = useState("");
  const [selecteddata, setselectedData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const queryString = window.location.search.slice(1); // Removes the "?"
  // const url = "https://apitesting.smrtapp.com/graphql";

  const navigate = useNavigate()


  const handleSubmit = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("Data given:", response.data);
        setData(response.data.Response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  const getChatData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/GetChats`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data.data));
        const filteredData = response.data.data.filter(
          (chat) => chat.agent_id === queryString
        );

        // Update the state with the filtered chat data
        setChatData(filteredData);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  useEffect(() => {
    handleSubmit()
      .then(() => getChatData())
      .then(() => setLoading(false));
  }, []);

  async function executeGraphQLQueryByEmail(email, details) {
    // console.log(email, details);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL2}/customer/graphql-api`,
        {
          email: email,
          SmrtToken: Data.SMARTkey,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (response.status === 200) {
        // Access the correct path to customer details
        const customerData = response.data.response.business.getCustomer;
        setGraphQl(customerData);
  
        // Update chat data with the fetched customer details
        const updatedChatData = ChatData.map((chat) => {
          if (chat._id === details._id) {
            return {
              ...chat,
              customerDetails: customerData, // Correctly use the fetched data here
            };
          }
          return chat;
        });
  
        console.log("email", email, "updatedChatData", updatedChatData);
  
        setCombinedata((prevData) => {
          const safePrevData = Array.isArray(prevData) ? prevData : [];
  
          // Check if the chat entry already exists in the prevData
          const existingChatIndex = safePrevData.findIndex(
            (chat) => chat._id === details._id
          );
  
          if (existingChatIndex > -1) {
            // Update the existing chat with new data
            const updatedPrevData = [...safePrevData];
            updatedPrevData[existingChatIndex] = updatedChatData.find(
              (chat) => chat._id === details._id
            );
            return updatedPrevData;
          } else {
            // Add the new chat entry
            return [...safePrevData, ...updatedChatData];
          }
        });
  
        return;
      } else {
        console.error(`Error: Received status code ${response.status}`);
      }
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  
    return null;
  }
  
  useEffect(() => {
    // Check if ChatData is valid and not empty
    if (!ChatData || !Array.isArray(ChatData) || ChatData.length === 0) {
      console.log("ChatData is empty or not an array:", ChatData);
      return;
    }
  
    const emailPromises = []; // Store promises for all emails to ensure they're all processed
    
    ChatData.forEach((step) => {
      // Check for intermediate steps in each step
      if (step?.intermediate_steps && Array.isArray(step.intermediate_steps)) {
        step.intermediate_steps.forEach((intermediateStep) => {
          const email = intermediateStep?.tool_input?.email || intermediateStep?.tool_input?.emailaddress;
  
          // If an email is found, trigger the GraphQL query
          if (email) {
            console.log("Processing email:", email); // Add logging to ensure each email is processed
  
            // Store the promise from executeGraphQLQueryByEmail
            const promise = executeGraphQLQueryByEmail(email, step);
            emailPromises.push(promise); // Add the promise to the array
          }
        });
      }
    });
  
    // Wait for all promises to resolve (ensure no email is missed)
    Promise.all(emailPromises)
      .then((results) => {
        console.log("All emails processed successfully:", results);
      })
      .catch((error) => {
        console.error("Error processing emails:", error);
      });
  
  }, [ChatData]);
  


  const handleChats = (data) => {
    // console.log("data",data);
    setselectedData(data);
  };



  return (
    <div className="ChatWithAgent-main-container mt-8">
      <div className="flex justify-between items-end gap-5 w-full flexcol">
        <div className="w-full">
          <h5>Live Chat</h5>
          <label>
            <span className="primaryClr">My Conversation |</span> All
            Conversations
          </label>
          <div className="ChatWithAgent-input-container mt-2">
            {
            //     <img
            //   src="../../../../assets/images/DashboardImages/icons/circle+iconcontainer.webp"
            //   alt=""
            // />
        }
            <div className="ChatWithAgent-input-box">
              <input type="text" placeholder="Search" />
              <Icon
                icon="mynaui:search"
                className="search-icon"
                style={{ color: "#868687", width: "25px", height: "25px" }}
              />
            </div>
          </div>
        </div>

 
        </div>

      <div className="ChatWithAgent-mainChat-container gap-5">
        <div className="ChatWithAgent-InnerChat-container">
          <div className="ChatWithAgent-InnerChat-head flex justify-end">
            <img
              src="../../../../assets/images/DashboardImages/icons/tabler_filter.webp"
              alt=""
            />
          </div>
          <div
            className="ChatWithAgent-chat-list"
            style={{ overflowY: "scroll" }}
          >
            {Array.isArray(combinedata) && combinedata.length > 0 ? (
              combinedata
                .filter((chatEntry) => chatEntry.updated_at) // Ensure there's a date
                .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)) // Sort by timestamp (latest first)
                .map((chatEntry, index) => {
                  const lastMessage = chatEntry?.chat_history?.length
                    ? chatEntry.chat_history[chatEntry.chat_history.length - 1]
                        ?.content || "No messages"
                    : "No messages";

                  const customerName =
                    chatEntry?.customerDetails?.fullName || "Unknown Customer";

                  const timestamp = chatEntry.updated_at
                    ? new Date(chatEntry.updated_at).toLocaleString()
                    : "Unknown Date";

                  return (
                    <div
                      key={chatEntry._id || `chat-${index}`}
                      className="ChatWithAgent-agent-box"
                      onClick={() => handleChats(chatEntry)}
                    >
                      <img
                        src="../../../../assets/images/DashboardImages/icons/circle+iconcontainerss.webp"
                        alt="customer icon"
                      />
                      <span>
                        <h5>{customerName}</h5>
                        <p>{timestamp}</p>
                      </span>
                    </div>
                  );
                })
            ) : (
              <p>No chats available</p>
            )}
          </div>
        </div>

        <AgentChatBot Data={selecteddata} />
        <div className="ChatWithAgent-InnerChat-container-center">
          <div className="ChatWithAgent-InnerChat-head flex items-center gap-6">
            <h5>SMRT Info</h5>
          </div>



          <div className="ChatWithAgent-box-center">
            <h5>Customer Info</h5>
            <ul>
              <li className="flex items-center">
                <span className="set-wid">Customer Name</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.name || "Unknown Customer"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">Customer ID</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.id || "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">Phone</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.activePhone || "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">Email</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.email || "N/A"}
                </span>
              </li>
            </ul>
          </div>

          <div className="ChatWithAgent-box-center">
            {
              // <h5>SMRT</h5>
            }
            <ul>
              <li className="flex items-center">
                <span className="set-wid">Address</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.email || "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">locker</span>
                <span className="flex justify-start items-center">
              
                  {selecteddata?.customerDetails?.settings?.isLockers
                    ? "true"
                    : "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">company Name</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.companyName || "N/A"}
                </span>
              </li>
            </ul>
          </div>

          <div className="ChatWithAgent-box-center">
            <ul>
              <li className="flex items-center">
                <span className="set-wid">Driver Instructions</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.driverInstructions || "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">Store ID </span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.store?.id || "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">Store Name</span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.store?.name || "N/A"}
                </span>
              </li>
              <li className="flex items-center">
                <span className="set-wid">Store Address </span>
                <span className="flex justify-start items-center">
                  {selecteddata?.customerDetails?.store?.address +
                    "\t" +
                    selecteddata?.customerDetails?.store?.city || "N/A"}
                </span>
              </li>
            </ul>
          </div>
        </div>

       <div className="ChatWithAgent-InnerChat-container-history">
  <div className="ChatWithAgent-InnerChat-head flex items-center gap-6">
    <h5>History</h5>
  </div>

  {/* Set a fixed height and enable scroll */}
  <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
    <div className="ChatWithAgent-box-center">
      <h5>Future Appointments</h5>
      <ul>
        {selecteddata?.customerDetails?.futureAppointments?.map(
          (appointment, index) => (
            <React.Fragment key={index}>
              <li className="flex items-center w-full mt-1">
                <h5 className="w-full">Appointment : {index + 1}</h5>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Status</span>
                <span className="flex items-center gap-2 w-full">
                  {appointment.status || "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Locker Code</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.lockerCode || "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Scheduled At</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.scheduledAt || "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Cleaning Instructions</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.cleaningInstructions|| "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Driver Instructions</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.driverInstructions|| "N/A"}
                </span>
              </li>
            </React.Fragment>
          )
        )}
      </ul>
    </div>

    <div className="ChatWithAgent-box-center">
      <h5>Customer Booking</h5>
      <ul>
        {selecteddata?.customerDetails?.customerBookings?.map(
          (appointment, index) => (
            <React.Fragment key={index}>
              <li className="flex items-center w-full mt-1">
                <h5 className="w-full">Appointment : {index + 1}</h5>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Status</span>
                <span className="flex items-center gap-2 w-full">
                  {appointment.status || "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Booking Date</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.bookingDate || "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Pickup Date Display</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.pickupDateDisplay || "N/A"}
                </span>
              </li>
              <li className="flex items-center w-full mt-1">
                <span className="w-full">Pickup Date Title</span>
                <span className="flex justify-between items-center w-full">
                  {appointment.pickupDateTitle}
                </span>
              </li>
             
            
            </React.Fragment>
          )
        )}
      </ul>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default ChatWithAgent;
