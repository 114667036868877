import React from "react";
import "./settings.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  return (
    <>
      <div className="CreateAgentBrief-main-container Integration-main-container Setting-main">
        <h5>Settings</h5>
        <div className="integration-tabs-container">
     {  
      /*   <div className="nav-switch">
            <button
              className={pathname === "/settings" ? "active" : ""}
              onClick={() => {
                navigate("/settings");
              }}
            >
              Subscription
            </button>
            <button
              className={pathname === "/settings/Notifications" ? "active" : ""}
              onClick={() => {
                navigate("/settings/Notifications");
              }}
            >
              Notifications & Others
            </button>
          </div>*/
        }
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
