import React from "react";
import "./video.scss";
import { Icon } from "@iconify/react";

const Video = (props) => {
  return (
    <div className="left-section">
      {/* <div className="top-bar v-center space-between ">
        <span className="v-center gap-1">
          <li></li>
          <li></li>
          <li></li>
        </span>
        <button className={`d-flex v-center h-center gap-2 ${props.hide}`}>
          <Icon
            icon="carbon:play-outline"
            width="25"
            height="25"
            className="play-icon"
            color="white"
          />
          Request demo
        </button>
      </div> */}
      <div className="video-sec">
        <img src="../../../assets/images/message-box.png" alt="" />
        <div className={` play-video-btn ${props.hide}`}></div>
      </div>
    </div>
  );
};

export default Video;
