import React, { useEffect } from "react";
import "./KnowledgeTable.scss";
import { useState } from "react";
import axios from "axios";
import { SetKnowledge } from "../../../../../redux/action";
import { useDispatch } from "react-redux";
import { Modal, Popconfirm, Table, Button,Select,Tooltip } from "antd";
import { setKnowId } from "../../../../../redux/action";
import {  useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddFAQ from "../../../../components/Table/AddFAQ";
import EditFAQ from "../../../../components/Table/EditFAQ";
import { useNavigate } from "react-router-dom";


const { Option } = Select;

const KnowledgeTable = ({ searchTerm , openModal , agentId}) => {
  const [data, setData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null); // State to track the selected row
  const [ID, setID] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedKnowledgeId, setSelectedKnowledgeId] = useState(null);
  const [agentID, setAgentID] = useState()
  const [Question, setQuestion] = useState(null);
  const [Answer, setAnswer] = useState(null);
  const [isModalVisible2, setIsModalVisible2] = useState(false)
  const [faqs, setFaqs] = useState([]);
  const[Edit, setEdit] = useState("")
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [assignmentStatus, setAssignmentStatus] = useState({});
  //Redux the state
  const disptasch = useDispatch();

  const navigate = useNavigate()


  const AgentID = useSelector((state) => state.agentId);
  useEffect(()=>{
    if(!AgentID)
      {
      console.log("Agent")
         const id = localStorage.getItem("agentID");
        setAgentID(id)
      }
      else{
        setAgentID(agentID)
      }
  },[])

const showModalVisable2 = () => setIsModalVisible2(true);
const handleCloseisModalVisible2 = () =>{
  setIsModalVisible2(false) 
}


const showModal = (id) => {
    setSelectedKnowledgeId(id);
    setIsModalVisible(true);
    
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedKnowledgeId(null);
  };

  const handleRowClick = (index, id) => {
    setSelectedIndex(index);
    disptasch(SetKnowledge(id)); // Update the selected index
  };

  const fetchData = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/getAllKnowledgesBases`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("data id given ", response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
    console.log("fetchData", data);
  }; // Call fetchData function

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0 && agentID) {
      const status = {};
      data.forEach((item, index) => {
        status[index] = item.AgentID.some((agent) => agent.id === agentID);
      });
      setAssignmentStatus(status);
    }
  }, [data, agentID]);
  

  const open = (ID) => {
    setIsModalOpenDelete(true);
    setID(ID);
  };
  const closeModal = () => {
    setIsModalOpenDelete(false);
  };

 

  const handleDeleteAgentID = async (id, index) => {
    try {
      if (!id) return toast.warning("Agent ID is required");
      await axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/updateEditAgent`,
          { id, agentID },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          fetchData();
          toast.success("Agent has been removed from the knowledge base successfully!");
          setAssignmentStatus((prevStatus) => ({
            ...prevStatus,
            [index]: false,
          }));
        })
        .catch((error) => {
          console.error(error);
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  

  /********* Modal ***********************/
  const [knowledgeData, setKnowledgeData] = useState(null);

  useEffect(() => {
    console.log("Modal" ," i am here" );
    if (selectedKnowledgeId) {
      fetchKnowledgeData();
    }
  }, [selectedKnowledgeId]);

  const fetchKnowledgeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/SpecficKnowledgesBases/${selectedKnowledgeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setKnowledgeData(response.data.Response);
      console.log("Data knowlede base :", response.data);
    } catch (error) {
      console.log(error.message);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
    }
  };



  const handleDelete = async (type, index) => {
    console.log("Type",type, "_id",index);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL2}/customer/deletefromArrayKnowledge`,
        {
          id:selectedKnowledgeId,
          arrayField: type,
          index:index,
        },
        {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
      });

      fetchKnowledgeData();
    } catch (error) {
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
    }
  };

  const handleEdit = (record ,type) => {
    // Set the data to edit in your state, could be a form or another component state
    // function to display modal or form for editin
    setEdit({
      ...record, // Spread the record to flatten the structure
      type // Include the type directly into the state
  });
    setIsModalVisibleEdit(true);
 
  };

  const columnsFaqs = (type) => [
    {
      title: "Question",
      dataIndex: "Question",
      key: "Question",
    },
    {
      title: "Answer",
      dataIndex: "Answer",
      key: "Answer",
    },

    {
      title: "Tags",
      dataIndex: "Tags",
      key: "Tags",
      render: (tags) => (
        <>
          {tags.map((tagObject, index) => (
            <span
              key={tagObject._id}
              style={{
                display: "inline-block",
                margin: "0 8px 8px 0",
                padding: "5px 10px",
                background: "#f0f0f0",
                borderRadius: "10px",
              }}
            >
              {tagObject.Tag}
            </span>
          ))}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        < div style={{display:"flex"}}>
              <Tooltip title="Edit">
              <img
              className="cursor-pointer"
              src="../../../../assets/icons/tabler_edit.png"
              alt=""
              onClick={() => handleEdit(record, type)}
              />
              </Tooltip>
       
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDelete("FAQS", record._id)}
            okText="Yes"
            cancelText="No"
            overlayClassName="custom-popconfirm"
          >
               <Tooltip title="Delete">
               <img
               className="cursor-pointer"
               src="../../../../assets/icons/material-symbols_delete-outline.png"
               alt=""
               />
               </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ]

 const handleCloseisModalVisibleEDit = () => {
    console.log("handleCloseisModalVisible2 hit ");
    setIsModalVisibleEdit(false);
    setIsModalVisible(true);
    fetchKnowledgeData();
  };

  const handleTagChange = (value) => {
    setSelectedTag(value);
  };

  const getUniqueTags = (faqData) => {
    if (!faqData) return [];
    return [...new Set(faqData.flatMap((faq) => faq.Tags?.map((tag) => tag.Tag)))];
  };

  const filterDataByTag = (data) => {
    if (!data) return [];
    return selectedTag ? data.filter((faq) => faq.Tags?.some((tag) => tag.Tag === selectedTag)) : data;
  };

const addAgent = async (knowledgeID, index) => {
  const id = localStorage.getItem("agentID");
  if (!knowledgeID) {
    toast.warning("Select the knowledge base", { toastId: 1 });
    return;
  }
  if (!AgentID && !id) {
    toast.warning("Create an agent First", { toastId: "agentError" });
    return;
  }

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL2}/customer/addagentID`,
      { id: knowledgeID, agentID: AgentID || id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
       setSelectedIndex(index);
    fetchData();
    toast.success("Knowledge base has been successfully assigned to the agent", { toastId: 1 });
    setAssignmentStatus((prevStatus) => ({
      ...prevStatus,
      [index]: true,
    }));
  } catch (error) {
    toast.error("Failed to add agent", { toastId: 1 });
    console.error(error);
     if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
        if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
  }
};


    const deleteData = async (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/deleteKnowledges`,
      data: {
        "id": id,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
        toast.success("Knowledge base has been deleted successfully.", { toastId: 1 });
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Failed to delete data", { toastId: 1 });
        console.log(error);
            if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
      });
  };

  return (
    <div class="table-div relative overflow-x-auto sm:rounded-lg">
      <table class="w-full text-sm text-left rtl:text-right">
        <thead class="uppercase">
          <tr>
            <th scope="col" class="px-6 py-3">
              Name
            </th>

            <th scope="col" class="px-6 py-3">
              AGENTS
            </th>
            <th scope="col" class="px-6 py-3">
              Date
            </th>
            <th scope="col" class="px-6 py-3 flex justify-end">
              ACTION
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.Response?.filter((item) => {
              // If searchTerm is empty, return all items
              if (searchTerm.trim() === "") {
                return true;
              }

              // Otherwise, filter by the search term
              return item.Name.toLowerCase().includes(searchTerm.toLowerCase());
            }).map((item, index) => {
              console.log("item.AgentID:", item.AgentID);
              console.log("AgentID:",agentID )
              console.log("agentId:", item.AgentID.find(agent => agent.id === agentID));
              const isSelected = selectedIndex === index || item.AgentID.find(agent => agent.id === agentID);
              return (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  // onClick={() => handleRowClick(index, item._id )}
                >
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    style={{
                      backgroundColor:
                      isSelected ? "#e2e8f0" : "initial", // Change this color to your preferred selected color
                    }}
                  >
                    {item.Name}
                  </th>
                  <td
                    class="px-6 py-4"
                    style={{
                      backgroundColor:
                      isSelected ? "#e2e8f0" : "initial", // Change this color to your preferred selected color
                    }}
                  >
                    {item.AgentID.length || 0}
                  </td>
                  <td
                    class="px-6 py-4"
                    style={{
                      backgroundColor:
                      isSelected ? "#e2e8f0" : "initial",// Change this color to your preferred selected color
                    }}
                  >
                    {item.updatedAt}
                  </td>
                  <td
                    class="px-6 py-4"
                    style={{
                      backgroundColor:
                      isSelected ? "#e2e8f0" : "initial", // Change this color to your preferred selected color
                    }}
                  >
                    <span
                      className="flex justify-end gap-2"
                      style={{
                        backgroundColor:
                        isSelected ? "#e2e8f0" : "initial", // Change this color to your preferred selected color
                      }}
                    >

                       {isSelected ? (
              <Tooltip title="Remove From Agent">
                <button
                  className="cursor-pointer"
                  style={{ color: "black", fontSize: "25px", cursor: "pointer" }}
                  onClick={() => handleDeleteAgentID(item._id, index)}
                >
                  -
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Add to Agent">
                <button
                  className="cursor-pointer"
                  style={{ color: "black", fontSize: "25px", cursor: "pointer" }}
                  onClick={() => addAgent(item._id, index)}
                >
                  +
                </button>
              </Tooltip>
            )}
                         <Tooltip title="Edit">
                         <img
                         className="cursor-pointer"
                         src="../../../../assets/icons/tabler_edit.png"
                         alt=""
                         onClick={() => showModal(item._id)}
                         />
                         </Tooltip>
                       <Tooltip title="Remove">
                       <img
                       className="cursor-pointer"
                       src="../../../../assets/icons/material-symbols_delete-outline.png"
                       onClick={() => {
                        open(item._id);
                        }}
                        alt=""
                        />
                        </Tooltip>
                        </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Modal
        open={isModalOpenDelete}
        onCancel={closeModal}
        centered
        footer={null}
      >
        <div className="delete-modal v-center flex-column ">
          <p className="text-center">
            Are you sure you want to delete ?
          </p>
          <div className="d-flex gap-4 mt-5">
            <button
              onClick={closeModal}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                background: "#ccc",
                color: "#000",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => deleteData(ID)}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                background: "#a890d3",
                color: "#fff",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      {
        // this for edit
       <Modal
          visible={isModalVisible}
          onCancel={handleClose}
          footer={null}
          width={1000}
        >
          {knowledgeData && (
            <>
              <h2 style={{ fontSize: "25px" }}>{knowledgeData.Name}</h2>
              {knowledgeData.fileurl && knowledgeData.fileurl.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>File</h3>
                  <Table
                    dataSource={knowledgeData.fileurl}
                    columns={[
                      {
                        title: "File",
                        dataIndex: "Url",
                        key: "Url",
                        render: (text) => (
                          <a href={text} target="_blank" rel="noopener noreferrer">
                            {text}
                          </a>
                        ),
                      }
                    ]}
                    
                  />
                </>
              )}

              {knowledgeData.AgentID && knowledgeData.AgentID.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>Agent IDs</h3>
                  <Table
                    dataSource={knowledgeData.AgentID}
                    columns={[
                      {
                        title: "ID",
                        dataIndex: "id",
                        key: "id",
                      },
                      {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Action",
                        key: "action",
                        render: (text, record) => (
                          <Popconfirm
                            title="Are you sure to delete?"
                            onConfirm={() => handleDelete("AgentID", record.index)}
                            okText="Yes"
                            cancelText="No"
                            overlayClassName="custom-popconfirm"
                          >
                           <Tooltip title="Delete">
               <img
               className="cursor-pointer"
               src="../../../../assets/icons/material-symbols_delete-outline.png"
               alt=""
               />
               </Tooltip>
                          </Popconfirm>
                        ),
                      },
                    ]}
                    
                  />
                </>
              )}

              {knowledgeData.FAQS && knowledgeData.FAQS.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>FAQs</h3>
                  <Select
                    placeholder="Select a tag to filter FAQs"
                    style={{ width: 200, marginBottom: 16 }}
                    onChange={handleTagChange}
                    allowClear
                  >
                    {getUniqueTags(knowledgeData.FAQS).map((tag) => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    dataSource={filterDataByTag(knowledgeData.FAQS)}
                    columns={columnsFaqs("FAQS")}
                  
                  />
                </>
              )}

              {knowledgeData.DryCleaningFAQ && knowledgeData.DryCleaningFAQ.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>Dry Cleaning FAQs</h3>
                  <Select
                    placeholder="Select a tag to filter Dry Cleaning FAQs"
                    style={{ width: 200, marginBottom: 16 }}
                    onChange={handleTagChange}
                    allowClear
                  >
                    {getUniqueTags(knowledgeData.DryCleaningFAQ).map((tag) => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    dataSource={filterDataByTag(knowledgeData.DryCleaningFAQ)}
                    columns={columnsFaqs("DryCleaningFAQ")}
                 
                  />
                </>
              )}

              {knowledgeData.PDfFAQ && knowledgeData.PDfFAQ.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>File FAQs</h3>
                  <Select
                    placeholder="Select a tag to filter FAQs"
                    style={{ width: 200, marginBottom: 16 }}
                    onChange={handleTagChange}
                    allowClear
                  >
                    {getUniqueTags(knowledgeData.PDfFAQ).map((tag) => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    dataSource={filterDataByTag(knowledgeData.PDfFAQ)}
                    columns={columnsFaqs("PDfFAQ")}
                   
                  />
                </>
              )}

              {knowledgeData.recordingFAQ && knowledgeData.recordingFAQ.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>Recording FAQs</h3>
                  <Select
                    placeholder="Select a tag to filter Recording FAQs"
                    style={{ width: 200, marginBottom: 16 }}
                    onChange={handleTagChange}
                    allowClear
                  >
                    {getUniqueTags(knowledgeData.recordingFAQ).map((tag) => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    dataSource={filterDataByTag(knowledgeData.recordingFAQ)}
                    columns={columnsFaqs("recordingFAQ")}
                   
                  />
                </>
              )}
            </>
          )}

          <div style={{ display: "flex", gap: "20px" }}>
            <button
              className="theme-btn-light items-center gap-2"
              onClick={() => {
                setIsModalVisible(false);
                showModal();
              }}
            >
              Add FAQ's
            </button>
            <button
              className="theme-btn-light items-center gap-2"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              Save
            </button>
          </div>
        </Modal>
      }


     {/* ****** add Faqs*********** */}
<Modal
          visible={isModalVisible2}
          onCancel={handleCloseisModalVisible2}
          footer={null}
          width={500}
        >
          <AddFAQ linkBack={handleCloseisModalVisible2} />
        </Modal>

        {/* ****** Edit Faqs*********** */}

      <Modal
          visible={isModalVisibleEdit}
          onCancel={handleCloseisModalVisibleEDit}
          footer={null}
          width={500}
        >
          <EditFAQ linkBack={handleCloseisModalVisibleEDit} Edit={Edit}  knowId={selectedKnowledgeId}   />
        </Modal>

    </div>
  );
};

export default KnowledgeTable;
