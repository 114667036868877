import React from "react";
import "../HomeSections/testimonial.scss";

const Testimonial = ({ cardData }) => {
  // Check if cardData is defined and not empty before mapping
  if (!cardData || cardData.length === 0) {
    return <p></p>;
  }

  return (
    <>
      {cardData.map((card, index) => (
        <div key={index} className="testimonialCard">
          <div className="leftArea">
            <img src={card.imgs} alt="" />
          </div>
          <div className="rightArea">
            <h5>{card.name}</h5>
            <p className="grayPara">{card.designation}</p>
            <p className="pt-2">{card.detail}</p>
            <br />
            <p className="grayPara2">{card.date}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Testimonial;
