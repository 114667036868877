import React, { useEffect, useState } from "react";
import "./LiveChatSelect.scss";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu } from "antd";
import axios from "axios";

const LiveChatSelect = () => {
  //Add the following
  const [Data, setdata] = useState();
  const [selected, setSelected] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate()


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL2}/customer/getAllAgent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setdata(response.data.response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  }, []);

  const item = Data?.map((agent) => ({
    key: agent._id,
    // eslint-disable-next-line no-template-curly-in-string
    label: (
      <Link to={`/livechat/agent?${agent._id}`}>
        {agent.AgentName} - {agent.CompanyName}
      </Link>
    ),
  }));

  const handleMenuClick = (e) => {
    const agent = Data.find((agent) => agent._id === e.key);
    console.log(`Selected agent ID: ${e.key}`);
    setSelected(agent);
  };
  const handleDropdownVisibleChange = (flag) => {
    setDropdownOpen(flag);
  };
  return (
    <div className="LiveChat-main-container">
      <div className="top">
        <h5>Live Chat</h5>
        <label>Select agent to chat</label>
      </div>
      <div className="bottom">
        <Dropdown
          overlay={<Menu onClick={handleMenuClick} items={item} />}
          trigger={["click"]}
          onVisibleChange={handleDropdownVisibleChange}
          className="live-chat-dropdown"
        >
          <a>
            <Space>
              Select agent to chat
              <DownOutlined
                className={`dropdown-icon ${dropdownOpen ? "open" : ""}`}
              />
            </Space>
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default LiveChatSelect;
