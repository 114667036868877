import React from "react";
import "./DryCleaning.scss";
import { NavLink, useLocation } from "react-router-dom";

const DryCleaning = ({ img, h5, h3, p, setpadding, setfont }) => {
  const location = useLocation();

  return (
    <div
      className={`dryCleaning-main-container ${setpadding}`}
      style={{
        backgroundColor:
          location.pathname === "/integrations" ? "transparent" : "",
      }}
    >
      <div className="dryCleaning-centered-container">
        <div className="dryCleaning-left-container">
          <img src={img} alt="" />
        </div>
        <div className="dryCleaning-right-container">
          <h2 className={`${setfont}`}>{h5}</h2>
          <h3>{h3}</h3>
          <p>{p}</p>
          <NavLink to="/demo">
            <button className="demoBtn">Request Demo</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default DryCleaning;
