import React from 'react'
import './AIHero.scss'

const AIHero = () => {
    return (
        <div className='AIHero-main-container'>
            <div className='AIHero-shadow-container'></div>
            <div className='AIHero-text-centered-container'>
                <h1>AI Agents Assist</h1>
                <p>AI Agent Assist bot tells your agents what they should say to customers during support or sales calls (or chats). The AI-powered bot listens to calls, understands the sentiments and intents of customers, and suggests real-time answers to human agents.</p>
            </div>
        </div>
    )
}

export default AIHero