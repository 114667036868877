import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import "./App.css";
import Demo from "./Pages/RequestDemo/Demo";
import Pricing from "./Pages/Pricing/Pricing";
import Fabicare from "./Pages/Fabicare/Fabicare";
import AIAgent from "./Pages/AIAgent/AIAgent";
import LiveChatPlatform from "./Pages/LiveChatPlatform/LiveChatPlatform";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import DashboardMain from "./Dashboard/DashboardMain/DashboardMain";
import CreateAgentBrief from "./Dashboard/DashboardMain/CreateAgentBrief/CreateAgentBrief";
import UpdateAgentBrief from "./Dashboard/DashboardMain/CreateAgentBrief/UpdateAgentBrief";
import AgentInfo from "./Dashboard/DashboardMain/CreateAgentBrief/AgentInfo/AgentInfo";
import Script from "./Dashboard/DashboardMain/CreateAgentBrief/Script/Script";
import KnowledgeLibrary from "./Dashboard/DashboardMain/CreateAgentBrief/KnowledgeLibrary/KnowledgeLibrary";
import Configuration from "./Dashboard/DashboardMain/CreateAgentBrief/Configuration/Configuration";
import TestIt from "./Dashboard/DashboardMain/CreateAgentBrief/TestIt/TestIt";
import DashboardOutlet from "./Dashboard/DashboardMain/DashboardOutlet";
import Logs from "./Dashboard/Logs/logs";
import LogsDetails from "./Dashboard/Logs/logsDetails";
import Integration from "./Dashboard/Integration/Integration";
import Smrt from "./Dashboard/Integration/Smrt/Smrt";
import LLM from "./Dashboard/Integration/LLM/LLM";
import Twilio from "./Dashboard/Integration/Twilio/Twilio";
import ElevenLabs from "./Dashboard/Integration/ElevenLabs/ElevenLabs";
import Calllogs from "./Dashboard/Logs/calllogs";
import Settings from "./Dashboard/Settings/settings";
import Subscription from "./Dashboard/Settings//Subscription/subscription";
import Notifications from "./Dashboard/Settings/Notifications/notifications";
import LiveChat from "./Dashboard/LiveChat/LiveChat";
import Analytics from "./Dashboard/Analytics/Analytics";
import AITraining from "./Dashboard/AITraining/AITraining";
import AccountSetting from "./Dashboard/AccountSetting/AccountSetting";
import LiveChatSelect from "./Dashboard/LiveChat/LiveChatSelect/LiveChatSelect";
import ChatWithAgent from "./Dashboard/LiveChat/ChatWithAgent/ChatWithAgent";
import Platform from "./Pages/Platform/platform";
import HowItWork from "./Pages/howItWork/howItWork";
import ChooseUs from "./Pages/ChooseUs/chooseUs";
import Integrations from "./Pages/Integrations/integrations";
import NameBox from "./Dashboard/components/Modal/NameBox/NameBox";
import DataLoader from "./Dashboard/components/Modal/DataLoader/DataLoader";
import LoaderFaqs from "./Dashboard/components/Modal/LoaderFaqs/LoaderFaqs";
import LoaderFAQ1 from "./Dashboard/components/Modal/LoaderFaqs/LoaderFAQ1";
import LoaderFAQ2 from "./Dashboard/components/Modal/LoaderFaqs/LoaderFAQ2";
import FlowChart from "./Dashboard/DashboardMain/CreateAgentBrief/Script/flowChart";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModelContext } from "./context/ModelContext";
import { useDispatch } from "react-redux";
import { SetAgentkeys } from "./redux/action";
import Login from "./Dashboard/Login";
import Signup from "./Dashboard/signup";








const AppLaout = () => {
  const [isOpen, setIsOpen] = useState(false);

 

  const disptach = useDispatch();


  useEffect(() => {
    disptach(SetAgentkeys(localStorage.getItem("agentID")));
  });

  return (
    <>
 <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <ModelContext.Provider value={{ isOpen, setIsOpen }}>
        <RouterProvider router={appRouter} />
       
      </ModelContext.Provider>
    </>
  );
};

const AppNew = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const appRouter = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/demo", element: <Demo /> },
  {path: "/login", element: <Login />},
  {path: "/signup", element: <Signup />},
  { path: "/platform", element: <Platform /> },
  {
    path: "/work",
    element: <HowItWork />,
  },
  {
    path: "/choose-us",
    element: <ChooseUs />,
  },
  {
    path: "/integrations",
    element: <Integrations />,
  },
  { path: "/pricing", element: <Pricing /> },
  { path: "/fabicare", element: <Fabicare /> },
  { path: "/aIAgent", element: <AIAgent /> },
  { path: "/liveChatPlatform", element: <LiveChatPlatform /> },

 {
    path: "/",
    element: <Dashboard />,
    children: [
{
            path: "updateAgentBrief",
            element: <UpdateAgentBrief />,
            children: [
              {
                path: "AgentInfo",
                element: <AgentInfo />,
              },
              {
                path: "Script",
                element: <Script />,
              },
              {
                path: "KnowledgeLibrary",
                element: <KnowledgeLibrary />,
                children: [
                  {
                    path: "NameBox",
                    element: (
                      <NameBox link="/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader" />
                    ),
                  },
                  {
                    path: "Dataloader",
                    element: (
                      <DataLoader link="/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs" />
                    ),
                  },
                  {
                    path: "Loaderfaqs",
                    element: <LoaderFaqs />,
                    children: [
                      {
                        path: "",
                        element: (
                          <LoaderFAQ1
                            linkBack="/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader"
                            linkNext="/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs/Loaderfaqstags"
                          />
                        ),
                      },
                      {
                        path: "Loaderfaqstags",
                        element: (
                          <LoaderFAQ2 linkBack="/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs" />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "Configuration",
                element: <Configuration />,
              },
              {
                path: "Webhooks",
                element: <TestIt />,
              },
            ],
}]},

          

  // DashBoard
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/dashboard",
        element: <DashboardOutlet />,
        children: [
          {
            path: "/dashboard",
            element: <DashboardMain />,
          },
          {
            path: "flowchart/:id",
            element: <FlowChart />,
          },
          {
            path: "CreateAgentBrief",
            element: <CreateAgentBrief />,
            children: [
              {
                path: "AgentInfo",
                element: <AgentInfo />,
              },
              {
                path: "Script",
                element: <Script />,
              },
              {
                path: "KnowledgeLibrary",
                element: <KnowledgeLibrary />,
                children: [
                  {
                    path: "NameBox",
                    element: (
                      <NameBox link="/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader" />
                    ),
                  },
                  {
                    path: "Dataloader",
                    element: (
                      <DataLoader link="/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs" />
                    ),
                  },
                  {
                    path: "Loaderfaqs",
                    element: <LoaderFaqs />,
                    children: [
                      {
                        path: "",
                        element: (
                          <LoaderFAQ1
                            linkBack="/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader"
                            linkNext="/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs/Loaderfaqstags"
                          />
                        ),
                      },
                      {
                        path: "Loaderfaqstags",
                        element: (
                          <LoaderFAQ2 linkBack="/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs" />
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "Configuration",
                element: <Configuration />,
              },
              {
                path: "Webhooks",
                element: <TestIt />,
              },
            ],
          },
        ],
      },
      {
        path: "/livechat",
        element: <LiveChat />,
        children: [
          {
            path: "",
            element: <LiveChatSelect />,
          },
          {
            path: "/livechat/agent",
            element: <ChatWithAgent />,
          },
        ],
      },
      {
        path: "/KnowledgeLibrary",
        element: <AITraining />,
        children: [
                  {
                    path: "NameBox",
                    element: (
                      <NameBox link="/KnowledgeLibrary/Dataloader" />
                    ),
                  },
                  {
                    path: "Dataloader",
                    element: (
                      <DataLoader link="/KnowledgeLibrary/Loaderfaqs" />
                    ),
                  },
                  {
                    path: "Loaderfaqs",
                    element: <LoaderFaqs />,
                    children: [
                      {
                        path: "",
                        element: (
                          <LoaderFAQ1
                            linkBack="/KnowledgeLibrary/Dataloader"
                            linkNext="/KnowledgeLibrary/Loaderfaqs/Loaderfaqstags"
                          />
                        ),
                      },
                      {
                        path: "Loaderfaqstags",
                        element: (
                          <LoaderFAQ2 linkBack="/KnowledgeLibrary/Loaderfaqs" />
                        ),
                      },
                    ],
                  },
                  
                ]
      },
      {
        path: "/analytics",
        element: <Analytics />,
        // analytics
      },
      {
        path: "/Integration",
        element: <Integration />,
        children: [
          {
            path: "/Integration",
            element: <Smrt />,
          },
          {
            path: "LLM",
            element: <LLM />,
          },
          {
            path: "Twilio",
            element: <Twilio />,
          },
          {
            path: "ElevenLabs",
            element: <ElevenLabs />,
          },
        ],
      },
      {
        path: "/callLogs",
        element: <Logs />,
        children: [
          {
            path: "/callLogs",
            element: <Calllogs />,
          },
          {
            path: "logsdetails",
            element: <LogsDetails />,
          },
        ],
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            path: "/settings",
            element: <Subscription />,
          },
          {
            path: "Notifications",
            element: <Notifications />,
          },
        ],
      },
      {
        path: "/accountsettings",
        element: <AccountSetting />,
        children: [
          {
            path: "/accountsettings",
            // element: <Subscription />,
          },
          {
            path: "Notifications",
            // element: <Notifications />,
          },
        ],
      },
    ],
  },
]);

export default AppLaout;
