



import React, { useState, useEffect } from "react";
import "./AccountSetting.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Icon } from "@iconify/react";
import { Modal, Button } from "antd"; // Importing Ant Design Modal

const initialFormData = {
  userName: "",
  phoneNumber: "",
  businessName: "",
  email: "",
  address: "",
  password: "********",
  BillingSubscription: "Free", // Default value for billing
};

const AccountSetting = () => {
  const [formData, setFormData] = useState(initialFormData); // Agent data
  const [isEditable, setIsEditable] = useState(false); // Toggle edit mode
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state
  const [modalVisible, setModalVisible] = useState(false); // Antd modal visibility

  const navigate = useNavigate();

  // Fetch agent data when the component mounts
  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL2}/customer/getCustomerById`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        const response = await axios.request(config);
        console.log("password", response.data.firstName); // Log the response to check the structure

        setFormData({
          userName: response.data.firstName || "",
          businessName: response.data.BusinessName || "",
          email: response.data.email || "",
          address: response.data.address || "", // Don't fetch the actual password
          BillingSubscription: response.data.BillingSubscription || "Free",
        });

        setLoading(false);
      } catch (error) {
        setError("Failed to load agent data.");
        setLoading(false);
        
        if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          // navigate('/settings'); // Ensure you're using string URL correctly
        }
      }
    };

    fetchAgentData();
  }, []);

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form save
  const handleSave = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL2}/customer/updateCustomer`, // Update customer API endpoint
        {
          firstName: formData.userName,
          phone: formData.phoneNumber,
          BusinessName: formData.businessName,
          email: formData.email,
          address: formData.address,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIsEditable(false); // Disable edit mode after save
    } catch (error) {
      setError("Failed to update agent data.");
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        // navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  // Toggle password visibility
  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  // Show confirmation modal for canceling the subscription
  const showCancelSubscriptionModal = () => {
    setModalVisible(true);
  };

  // Handle subscription cancellation
  const handleCancelSubscription = async () => {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ cancel: false })  // Wrap the 'false' value in a JSON object
    };
  
    fetch(`${process.env.REACT_APP_API_URL2}/customer/cancelPayment`, options)
      .then(response => {
        // Check if response is valid JSON
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        console.log(data); 
        setModalVisible(false)
        window.location.reload() // Log the response data
      })
      .catch(err => {
        console.error('Error canceling subscription:', err);  // Handle errors
      });
  };
  

  if (loading) {
    return <div>Loading agent data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="CreateAgentBrief-main-container Integration-main-container">
      <h5>Account Settings</h5>

      <div className="AccountSetting-main-container">
        <div className="AccountSetting-userImg-box">
          <span>
            <img
              src="../../../assets/images/DashboardImages/images/group1000004507.webp"
              alt=""
            />
          </span>
          <h5>{formData.userName}</h5>
        </div>

        <form>
        <label style={{ opacity: !isEditable ? "0.5" : "1" }}>
          UserName:
          <input
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </label>
        <label style={{ opacity: !isEditable ? "0.5" : "1" }}>
          Business Name:
          <input
            type="text"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </label>
        <label style={{ opacity: !isEditable ? "0.5" : "1" }}>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </label>
        <label style={{ opacity: !isEditable ? "0.5" : "1" }}>
          Address:
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </label>
      
        <div className="flex justify-between items-center mt-5">
    <h5 style={{ fontSize: "22px" }}>Subscription</h5>

    <div className="flex items-center">
      {/* Display Current Subscription */}
      <button
        className="theme-btn-tran py-1"
        style={{
          padding: "5px 20px",
          color: "#4B2E83",
          fontSize: "14px",
          marginRight: "10px",
          gap: "5px",
        }}
        type="button"
        disabled={formData.BillingSubscription === "Free"} // Disable if subscription is Free
      >
        {formData.BillingSubscription}
      </button>
    </div>

    {/* Manage Subscription Button (aligned right) */}
    
  </div>
     
      </form>
      
      </div>

      <div className="AccountSetting-btn-container flex justify-end gap-2">
        {isEditable ? (
          <div className="flex gap-3">
            <button
              className="theme-btn-tran"
              type="button"
              onClick={() => setIsEditable(false)} // Discard changes
            >
              Discard
            </button>
            <button
              className="theme-btn-light"
              type="button"
              onClick={handleSave} // Save changes
            >
              Save
            </button>
          </div>
        ) : (
          <button
            className="flex items-center gap-1 primaryClr"
            type="button"
            onClick={() => setIsEditable(true)} // Enable edit mode
          >
            <img
              src="../../../assets/images/DashboardImages/icons/tabler_edit.webp"
              alt=""
            />
            Edit Info
          </button>
        )}
        <button
      className="theme-btn-tran py-1"
      style={{
        padding: "5px 20px",
        backgroundColor: "#4B2E83",
        color: "#fff",
        fontSize: "14px",
        marginLeft: "auto", // Ensures the button is aligned to the right
      }}
      type="button"
      onClick={showCancelSubscriptionModal} // Function to handle subscription management
    >
      Manage Subscription
    </button>
      </div>

      {/* Ant Design Modal for canceling subscription */}
      <Modal
      title={<h3 style={{ fontSize: "20px", fontWeight: "bold", color: "#4B2E83" }}>Subscription Management</h3>}
      visible={modalVisible}
      onCancel={() => setModalVisible(false)} // Close the modal without performing any action
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", width: "100%" }}>
          <button
            key="upgrade"
            className="themeGlow-btn" // Custom gradient class for the "Upgrade" button
            style={{
              padding: "10px 20px",
              background: "linear-gradient(45deg, #4B2E83, #8E44AD)",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => { navigate("/settings") }} // Function to handle the upgrade action
          >
            Upgrade
          </button>
    
          <button
            key="cancel"
            className="theme-btn-tran py-1" // Custom styling for the "Cancel" button
            style={{
              padding: "10px 20px",
              // backgroundColor: "rgb(75, 46, 131)",
              color: "rgb(75, 46, 131)",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              border: "2px solid rgb(75, 46, 131)", 
            }}
            onClick={handleCancelSubscription} // Function to handle the cancel action
          >
            Cancel Subscription
          </button>
        </div>
      }
    >
      <p style={{ fontSize: "16px", color: "#666", marginBottom: "20px" }}>
        Would you like to upgrade your subscription or cancel it?
      </p>
    </Modal>
    
    
    

    
    </div>
  );
};

export default AccountSetting;
