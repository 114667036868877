import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios for API call
import './login&signup.css';

const Signup = () => {
  const navigate = useNavigate(); // To navigate after successful signup
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '', // Added confirmPassword field
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  // Handle input changes for all form fields
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    const { email, password, confirmPassword } = formData;

    // Validate that password and confirmPassword match
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      // Make POST request to signup API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL2}/customer/register`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response.data);
      setSuccess(true);
      
      // Optionally, navigate after a short delay to show success message
      setTimeout(() => {
        navigate("/");
      }, 2000); // 2-second delay
    } catch (err) {
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      // Handle errors from API
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('Signup failed. Please try again.');
      }
      console.error('Error:', err);
    }
  };

  return (
    <div className="wrapper signUp">
      <div className="form">
        <div className="heading">CREATE AN ACCOUNT</div>
        
        {/* Display error if exists */}
        {error && <p className="error">{error}</p>}
        
        {/* Display success message */}
        {success && <p className="success">Account created successfully! Redirecting to login...</p>}
        
        <form onSubmit={handleSubmit}>
          {/* Removed Name Field */}
          
          <div>
            <label htmlFor="email">E-Mail</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              required
            />
          </div>
          
          {/* Added Confirm Password Field */}
          <div>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Re-enter your password"
              required
            />
          </div>
          
          <button type="submit" className='submitBtn'>Submit</button>
        </form>
        
        <p>
          Have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
}

export default Signup;
