import React, { useEffect, useState } from "react";
import "./Table.scss";
import { Pagination, Modal, Popconfirm, Table, Button, Select,Tooltip } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import AddFAQ from "./AddFAQ";
import EditFAQ from "./EditFAQ";
import { useDispatch } from "react-redux";
import { setKnowId } from "../../../redux/action";
import DataLoader from "../Modal/DataLoader/DataLoader";
import { Icon } from "@iconify/react/dist/iconify.js";
import plusIcon from "@iconify/icons-mdi/plus";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const Tableknow = ({ tabledata, onDelete }) => {
  const [ID, setID] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedKnowledgeId, setSelectedKnowledgeId] = useState(null);
  const [knowledgeData, setKnowledgeData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [Question, setQuestion] = useState(null);
  const [Answer, setAnswer] = useState(null);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [Edit, setEdit] = useState("");
  const [isModalVisibleAddFile, setIsModalVisibleAddFile] = useState(false);

  const dispatch = useDispatch();


  const navigate = useNavigate()

  const showModal = () => setIsModalVisible2(true);

  const handleCloseisModalVisible2 = () => {
    setIsModalVisible2(false);
    setIsModalVisible(true);
    fetchKnowledgeData();
  };

  const handleCloseisModalVisibleEdit = () => {
    setIsModalVisibleEdit(false);
    setIsModalVisible(true);
    fetchKnowledgeData();
  };

  const handleCloseisModalVisibleAddFile = () => {
    setIsModalVisibleAddFile(false);
    setIsModalVisible(true);
    fetchKnowledgeData();
  };

  const totalRows = 5;
  const data = tabledata?.map((item, index) => ({
    id: index + 1,
    name: item.Name,
    agents: item.AgentID.length.toString(),
    date: new Date(item.createdAt).toLocaleString(),
    id: item._id,
  }));

  const handleChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = data?.length;
  const totalPages = Math.ceil(totalItems / totalRows);
  const startIndex = (currentPage - 1) * totalRows;
  const endIndex = Math.min(startIndex + totalRows, totalItems);
  const currentPageData = data?.slice(startIndex, endIndex);

  const deletedata = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL2}/customer/deleteKnowledges`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            id: id,
          },
        }
      );
      setID("");
      onDelete();
    } catch (error) {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  const open = (ID) => {
    setIsModalOpenDelete(true);
    setID(ID);
  };

  const closeModal = () => {
    setIsModalOpenDelete(false);
  };

  const confirmDelete = (id) => {
    deletedata(id);
    setIsModalOpenDelete(false);
  };

  const fetchKnowledgeData = async () => {
    if (!selectedKnowledgeId) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/SpecficKnowledgesBases/${selectedKnowledgeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setKnowledgeData(response.data.Response);
    } catch (error) {
      console.log(error.message);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  const handleDelete = async (type, index) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL2}/customer/deletefromArrayKnowledge`,
        {
          id: selectedKnowledgeId,
          arrayField: type,
          index: index,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchKnowledgeData();
    } catch (error) {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  const handleEdit = (record, type) => {
    setEdit({
      ...record,
      type,
    });
    setIsModalVisibleEdit(true);
  };

  const columnsFaqs = (type) => [
    {
      title: "Question",
      dataIndex: "Question",
      key: "Question",
    },
    {
      title: "Answer",
      dataIndex: "Answer",
      key: "Answer",
    },
    {
      title: "Tags",
      dataIndex: "Tags",
      key: "Tags",
      render: (tags) => (
        <>
          {tags?.map((tagObject, index) => (
            <span
              key={index}
              style={{
                display: "inline-block",
                margin: "0 8px 8px 0",
                padding: "5px 10px",
                background: "#f0f0f0",
                borderRadius: "10px",
              }}
            >
              {tagObject.Tag}
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex" }}> 
        <Tooltip title="Edit">
        <img
        className="cursor-pointer"
        src="../../../../assets/icons/tabler_edit.png"
        alt=""
        onClick={() => handleEdit(record, type)}
        />
        </Tooltip>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDelete(type, record._id)}
            okText="Yes"
            cancelText="No"
            overlayClassName="custom-popconfirm"
          >
             <Tooltip title="Delete">
             <img
             className="cursor-pointer"
             src="../../../../assets/icons/material-symbols_delete-outline.png"
             alt=""
             />
             </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedKnowledgeId(null);
  };

  useEffect(() => {
    if (selectedKnowledgeId) {
      fetchKnowledgeData();
    }
  }, [selectedKnowledgeId]);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleAnswerChange = (event) => {
    setAnswer(event.target.value);
  };

  const AddTheFaqs = () => {
    if (!Question || !Answer) {
      console.error("Question or Answer is null or undefined.");
      return;
    }
    handleSubmit();
  };

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/knowledege`,
        {
          ID: selectedKnowledgeId,
          FAQS: [
            {
              Question: Question,
              Answer: Answer,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setQuestion("");
        setAnswer("");
        setFaqs([]);
        fetchKnowledgeData();
        toast.success("New FAQs added successfully");
        handleCloseisModalVisible2();
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTagChange = (value) => {
    setSelectedTag(value);
  };

  const getUniqueTags = (faqData) => {
    if (!faqData) return [];
    return [
      ...new Set(faqData.flatMap((faq) => faq.Tags?.map((tag) => tag.Tag))),
    ];
  };

  const filterDataByTag = (data) => {
    if (!data) return [];
    return selectedTag
      ? data.filter((faq) => faq.Tags?.some((tag) => tag.Tag === selectedTag))
      : data;
  };

  return (
    <>
      <div className="table-div relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="uppercase">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Agents
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3 flex justify-end">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPageData?.map((item) => (
              <tr
                key={item.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.name}
                </th>

                <td className="px-6 py-4">{item.agents}</td>
                <td className="px-6 py-4">{item.date}</td>
                <td className="px-6 py-4">
                  <span className="flex justify-end gap-2">
                   <Tooltip title="Edit">
                    <img
                      className="cursor-pointer"
                      src="../../../../assets/icons/tabler_edit.png"
                      alt=""
                      onClick={() => {
                        setSelectedKnowledgeId(item.id);
                        setIsModalVisible(true);
                      }}
                    />
                    </Tooltip>
                     <Tooltip title="Delete">
                     <img
                     className="cursor-pointer"
                     src="../../../../assets/icons/material-symbols_delete-outline.png"
                     alt=""
                     onClick={() => open(item.id)}
                     />
                     </Tooltip>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          open={isModalOpenDelete}
          onCancel={closeModal}
          centered
          footer={null}
        >
          <div className="delete-modal v-center flex-column ">
            <p className="text-center">
              Are you sure you want to delete ?
            </p>
            <div className="d-flex gap-4 mt-5">
              <button
                onClick={closeModal}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  background: "#ccc",
                  color: "#000",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => confirmDelete(ID)}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  background: "#a890d3",
                  color: "#fff",
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          visible={isModalVisible}
          onCancel={handleClose}
          footer={null}
          width={1000}
        >
          {knowledgeData ? (
            <>
              <div>
                <h2 style={{ fontSize: "25px" }}>{knowledgeData.Name}</h2>
              </div>

              <div className="fileURL-container">
                <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>File</h3>
                <div style={{ display: "flex" }}>
                  <Icon
                    icon={plusIcon}
                    width="24"
                    height="24"
                    onClick={() => {
                      setIsModalVisibleAddFile(true);
                    }}
                  />
                </div>
              </div>
              {knowledgeData.fileurl && knowledgeData.fileurl.length > 0 && (
                <>
                  <Table
                    dataSource={knowledgeData.fileurl}
                    columns={[
                      {
                        title: "File",
                        dataIndex: "Url",
                        key: "Url",
                        render: (text) => (
                          <a
                            href={text}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {text}
                          </a>
                        ),
                      }
                    ]}
                    
                  />
                </>
              )}

              {knowledgeData.AgentID && knowledgeData.AgentID.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Agent IDs
                  </h3>
                  <Table
                    dataSource={knowledgeData.AgentID}
                    columns={[
                      {
                        title: "ID",
                        dataIndex: "id",
                        key: "id",
                      },
                      {
                        title: "Name",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Action",
                        key: "action",
                        render: (text, record) => (
                          <Popconfirm
                            title="Are you sure to delete?"
                            onConfirm={() =>
                              handleDelete("AgentID", record.index)
                            }
                            okText="Yes"
                            cancelText="No"
                            overlayClassName="custom-popconfirm"
                          >
                               <Tooltip title="Delete">
                               <img
                               className="cursor-pointer"
                               src="../../../../assets/icons/material-symbols_delete-outline.png"
                               alt=""
                               />
                               </Tooltip>
                          </Popconfirm>
                        ),
                      },
                    ]}
                    
                  />
                </>
              )}

              {knowledgeData.FAQS && knowledgeData.FAQS.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>FAQs</h3>
                  <Select
                    placeholder="Select a tag to filter FAQs"
                    style={{ width: 200, marginBottom: 16 }}
                    onChange={handleTagChange}
                    allowClear
                  >
                    {getUniqueTags(knowledgeData.FAQS).map((tag) => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    dataSource={filterDataByTag(knowledgeData.FAQS)}
                    columns={columnsFaqs("FAQS")}
                    
                  />
                </>
              )}

              {knowledgeData.DryCleaningFAQ &&
                knowledgeData.DryCleaningFAQ.length > 0 && (
                  <>
                    <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Dry Cleaning FAQs
                    </h3>
                    <Select
                      placeholder="Select a tag to filter Dry Cleaning FAQs"
                      style={{ width: 200, marginBottom: 16 }}
                      onChange={handleTagChange}
                      allowClear
                    >
                      {getUniqueTags(knowledgeData.DryCleaningFAQ).map(
                        (tag) => (
                          <Option key={tag} value={tag}>
                            {tag}
                          </Option>
                        )
                      )}
                    </Select>
                    <Table
                      dataSource={filterDataByTag(knowledgeData.DryCleaningFAQ)}
                      columns={columnsFaqs("DryCleaningFAQ")}
                      
                    />
                  </>
                )}

              {knowledgeData.PDfFAQ && knowledgeData.PDfFAQ.length > 0 && (
                <>
                  <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                    File FAQs
                  </h3>
                  <Select
                    placeholder="Select a tag to filter FAQs"
                    style={{ width: 200, marginBottom: 16 }}
                    onChange={handleTagChange}
                    allowClear
                  >
                    {getUniqueTags(knowledgeData.PDfFAQ).map((tag) => (
                      <Option key={tag} value={tag}>
                        {tag}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    dataSource={filterDataByTag(knowledgeData.PDfFAQ)}
                    columns={columnsFaqs("PDfFAQ")}
                    
                  />
                </>
              )}

              {knowledgeData.recordingFAQ &&
                knowledgeData.recordingFAQ.length > 0 && (
                  <>
                    <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Recording FAQs
                    </h3>
                    <Select
                      placeholder="Select a tag to filter Recording FAQs"
                      style={{ width: 200, marginBottom: 16 }}
                      onChange={handleTagChange}
                      allowClear
                    >
                      {getUniqueTags(knowledgeData.recordingFAQ).map((tag) => (
                        <Option key={tag} value={tag}>
                          {tag}
                        </Option>
                      ))}
                    </Select>
                    <Table
                      dataSource={filterDataByTag(knowledgeData.recordingFAQ)}
                      columns={columnsFaqs("recordingFAQ")}
                     
                    />
                  </>
                )}
            </>
          ) : (
            <div>Loading...</div>
          )}

          <div style={{ display: "flex", gap: "20px" }}>
            <button
              className="theme-btn-light items-center gap-2"
              onClick={() => {
                if (knowledgeData) {
                  dispatch(setKnowId(knowledgeData._id));
                  setIsModalVisible(false);
                  showModal();
                }
              }}
            >
              Add FAQ's
            </button>
            <button
              className="theme-btn-light items-center gap-2"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              Save
            </button>
          </div>
        </Modal>

        <Modal
          visible={isModalVisible2}
          onCancel={handleCloseisModalVisible2}
          footer={null}
          width={500}
        >
          <AddFAQ linkBack={handleCloseisModalVisible2} />
        </Modal>

        <Modal
          visible={isModalVisibleEdit}
          onCancel={handleCloseisModalVisibleEdit}
          footer={null}
          width={500}
        >
          <EditFAQ
            linkBack={handleCloseisModalVisibleEdit}
            Edit={Edit}
            knowId={selectedKnowledgeId}
          />
        </Modal>

        <Modal
          visible={isModalVisibleAddFile}
          onCancel={handleCloseisModalVisibleAddFile}
          footer={null}
          width={500}
        >
          <DataLoader
            onclose={handleCloseisModalVisibleAddFile}
            Edit={Edit}
            knowledgeID={knowledgeData?._id}
          />
        </Modal>
      </div>

      <Pagination
        current={currentPage}
        onChange={handleChange}
        total={totalItems}
        pageSize={totalRows}
        className="mt-5"
      />
    </>
  );
};

export default Tableknow;
