import React from 'react';

const KeyFeaturesBox = ({ title, description }) => {
    return (
        <div className='KeyFeatures-inner-box'>
            <h5>{title}</h5>
            <p>{description}</p>
        </div>
    );
}

export default KeyFeaturesBox;
