import { Icon } from "@iconify/react";
import React from "react";
import ReactMarkdown from "react-markdown"; // Import react-markdown

const AgentChatBot = ({ Data }) => {
  // console.log("Data",Data);
  return (
    <div className="ChatWithAgent-InnerChat-container-chat">
      <div className="ChatWithAgent-InnerChat-head flex items-center gap-6">
        <img
          src="../../../../assets/images/DashboardImages/icons/calling (1).webp"
          alt=""
        />
        <h5>{Data?.customerDetails?.name || "Unknown Customer"}</h5>
      </div>

      <div className="chat-middle" style={{ overflowY: "scroll" }}>
        {Data.chat_history &&
          Data.chat_history.map((chat_history, index) => (
            <div
              key={index}
              className={`v-center ${
                chat_history.type === "ai"
                  ? "received-txt-div gap-2"
                  : "sender-txt-div"
              }`}
            >
              {chat_history.type === "ai" ? (
                <>
                  <img
                    src="../../../assets/icons/assistanceIcon.png"
                    alt="Assistance Icon"
                  />
                  <p className="received-txt" style={{ color: "black" }}>
                    <ReactMarkdown>{chat_history.content}</ReactMarkdown>
                  </p>
                </>
              ) : (
                <p className="sender-txt">
                  <ReactMarkdown>{chat_history.content}</ReactMarkdown>
                </p>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AgentChatBot;
