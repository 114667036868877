import React, { useEffect } from "react";
import "./Demo.scss";
import RequestDemo from "../Home/HomeSections/RequestDemo/RequestDemo";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Benefits from "../Home/HomeSections/Benefits/Benefits";
import Trusted from "./trusted";
import ByTeam from "../../components/ByTeam/ByTeam";

const Demo = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div >
      <Navbar />
      <div className="demo-main-container">
        <div className="demotop">
          <RequestDemo shadow="shadow" btntxt="Send Request" />
        </div>
      </div>
      <Trusted />
      <ByTeam />
      {/* <Benefits/> */}
      <Footer />
    </div>
  );
};

export default Demo;
