
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './login&signup.css';
import { useDispatch } from 'react-redux';
import { SetAgentkeys } from '../redux/action';
import { Icon } from '@iconify/react/dist/iconify.js';
import { toast } from 'react-toastify';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      let id = "";
      await axios
      .post(`${process.env.REACT_APP_API_URL2}/customer/login`, {
        email: formData.email,
        password: formData.password,
      })
      .then((response) => {
        // Store the token in localStorage
        id = response.data.token;
        localStorage.setItem('token', id);
        if(response.data.token){
          // navigate('/dashboard');
          toast.success("Login successful");
        }
        if (response && response.data && response.data.email === "User Not Found") {
          toast.warn("User not found. Please check your email.");
        } 
        // Navigate to the dashboard or another protected route
      })
      .catch((error) => {
        console.log(error);
      });

  

  
  
       if(id) {
            axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/getagentKeys`,
          {},
          {
            headers: {
              Authorization: `Bearer ${id}`,
            },
          }
        )
        .then((response) => {
          dispatch(SetAgentkeys(response.data.Response));
          navigate('/dashboard');
        })
        .catch((err) => {
          console.log(err);
          navigate('/dashboard');
        });
        
      }
    } catch (err) {
      setError('Login failed. Please check your email or password.');
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="login-container">
	<div className="login-circle"></div>
	<div className='header-div'>
<img src='/dashlogo.svg' alt='...' className='logo' />
<h2 className="login-heading">LOGIN TO FABRICARE</h2>

</div>
      <div className="login-box">
        {
        //   <div className="social-login ">
        //   <button className="social-button google ">Login with Google</button>
        // </div>

        // <div className="divider">
        //   <span>OR</span>
        // </div>
      }

        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit} className="login-form">
		<div className="input-group-main" >  
		<div className="input-group">
		<label htmlFor="email">Email</label>
		<input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Email"
              required
			  />
			  </div>
			  <div className="input-group">
			  <label htmlFor="password">Password</label>
        <div className="LLM-input-box">
			  <input
             type={showPassword ? "text" : "password"}
              id="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter Password"
              required
			  />
        {
          showPassword ?
        <Icon  icon="mdi:eye-off" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
      :
        <Icon icon="mdi:eye" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
      }
        </div>
			  </div>
			  </div>
			  <div className="form-actions">
			  <div>
				{
					// <Link to="/forgot-password">Forgot Password?</Link>
				}
			  </div> 
			  <div>
				<button type="submit" className="submitBtn" selectable>Sign In</button>
			  </div>
			</div>
        </form>
        <div className="form-footer">
         {
		// 	 <Link to="/signup" className="signup-link">
        //     Not a Member? Sign Up
        //   </Link>
		}
        </div>
      </div>
    </div>
  );
};

export default Login;
