import React, { useEffect, useState } from "react";
import last from "../../logo.svg";
import "./Hero.scss";
import Chatbot from "../chatbot/chatbot";

const Hero = () => {
  return (
    <div className="hero-main-container d-flex flex-column">
      <div className="hero-pattern d-flex"></div>
      <Chatbot />
      <div className="hero-centered-container">
        <div className="hero-left-container">
          <div className="light-pill">Chatbot</div>
          <h1>
            Revolutionize Your <span class="spanClr-dark">Customer </span>{" "}
            <span class="spanClr-dark text-Lpurple">Service </span>{" "}
            <span class="spanClr-light"></span> with AI-Powered Voice Chat
          </h1>
          <p>
            {" "}
            Enhance customer experience with real-time, intelligent voice, text
            and chat assistance that integrates seamlessly with your business.
          </p>
          <button className="themeGlow-btn2">Get Started</button>
        </div>
        <div className="hero-right-container">
          <img src="../../../assets/images/chatImg.webp" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
