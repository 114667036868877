import React,{ useEffect, useState} from 'react';
import "./Analytics.scss";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Analytics = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [averageCallLength, setAverageCallLength] = useState(0);
    const [numCallsPerPeriod, setNumCallsPerPeriod] = useState(0);
    const [callsCompletedByAI, setCallsCompletedByAI] = useState(0);
    const [callTransferredRate, setCallTransferredRate] = useState(0);
    const [totalCallDurationToday, setTotalCallDurationToday] = useState(0);
    const [TransferCall, setTransferCall] = useState(0);
    const [QuestionAnswer, setQuestionAnswer] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL2}/customer/TwilloCalllogs`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
    
        axios
          .request(config)
          .then((response) => {
            console.log("Response data:", response.data);
            setData(response.data.calls || []);  // Set an empty array if no calls are returned
            calculateMetrics(response.data.calls || []);  // Calculate metrics with the data
            setLoading(false);  // Stop loading
         
          })
          .catch((error) => {
            console.log("Error fetching data:", error);
            setLoading(false);
            if (error.response?.data?.paymentIssue === true) {
              console.log('Payment issue detected, redirecting to settings...');
              navigate('/settings'); // Ensure you're using string URL correctly
            }
          });
          getCallTransfer()
      }, []);


      const handleQuestionClick = (index) => {
        // If the clicked question is already active, close it by setting activeIndex to null
        if (activeIndex === index) {
          setActiveIndex(null);
        } else {
          // Otherwise, set the clicked question as the active one
          setActiveIndex(index);
        }
      };
    

const getCallTransfer = () =>{


    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL2}/customer/Gettransfercalls`,
        headers: { 
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      };
      
      axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setTransferCall(response.data.data)
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
      });
      
}


const calculateMetrics = (calls) => {
    if (!calls || calls.length === 0) {
        setAverageCallLength(0);
        setNumCallsPerPeriod(0);
        setCallsCompletedByAI(0);
        setCallTransferredRate(0);
        return;
    }

    // 1. Calculate Average Call Length
    const completedCalls = calls.filter(call => call.status === 'completed');
    const totalDuration = completedCalls.reduce((sum, call) => sum + parseInt(call.duration, 10), 0);
    const avgCallLength = completedCalls.length > 0 ? totalDuration / completedCalls.length : 0;
    setAverageCallLength(avgCallLength.toFixed(2));

    // 2. Calculate Number of Calls per Period (assuming a day)
    const today = new Date().toISOString().split('T')[0];
    const callsToday = calls.filter(call => call.dateCreated.startsWith(today));
    setNumCallsPerPeriod(callsToday.length);

    //total time per period 
    const totalDurationToday = callsToday.reduce((sum, call) => sum + parseInt(call.duration, 10), 0);
    setTotalCallDurationToday(totalDurationToday);


    // 3. Count Calls Completed by AI (Assuming AI calls have a specific identifier)
    // This depends on how AI-completed calls are marked. Assuming a field `answeredBy: "AI"` exists:
    const aiCompletedCalls = calls.filter(call => call.answeredBy === null);
    setCallsCompletedByAI(aiCompletedCalls.length);

    // 4. Calculate Call Transferred Rate (Assuming transferred calls are identified)
    // Assuming a specific `status` or `event` marks a transferred call:
    const transferCallsCount = Array.isArray(TransferCall) ? TransferCall.length : 0;

    // Ensure calls is also an array and not undefined or null
    const totalCallsCount = Array.isArray(calls) ? calls.length : 0;
    
    // Calculate the transfer rate only if there are calls
    const transferRate = totalCallsCount > 0 ? (transferCallsCount / totalCallsCount) * 100 : 0;
    
    console.log("Transfer calls percentage:", totalCallsCount, "transferred:", (transferCallsCount / totalCallsCount) * 100);
    
    // Update the state with the calculated transfer rate
    setCallTransferredRate(Math.round(transferRate.toFixed(2)));
};




    const AnalyticsData = [
        {
            id: 1,
            h6: 'Average Call Length',
            h3: averageCallLength,
            mins: 'secs',
            bar: 'true',
            per: (averageCallLength / 60 * 100).toFixed(2)  // Example conversion to a percentage
        },
        {
            id: 2,
            h6: 'No of Calls per period',
            h3: numCallsPerPeriod,
            mins: '',
            bar: 'false'
        },
        {
            id: 3,
            h6: 'Calls completed by AI',
            h3: callsCompletedByAI,
            mins: '',
            bar: 'true',
            per: data && data.length > 0 ? ((callsCompletedByAI / data.length) * 100).toFixed(2) : 0  // Percentage of AI completed calls
        },
 
        {
            id: 4,
            h6: 'Call Transferred Rate',
            h3: TransferCall.length > 0 ? TransferCall.length : 0 ,
            mins: '',
            bar: 'true',
            per: callTransferredRate || 0 
        }
    ];


    useEffect(() => {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL2}/customer/GetChatsCommonlyAskedQustion`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
      
        axios
          .request(config)
          .then((response) => {
            console.log("Response data GetChatsCommonlyAskedQustion:", response.data.data);
            // Access the first element in data array and then the response field
            setQuestionAnswer(response.data.data[0].response);
            setLoading(false); // Stop loading
          })
          .catch((error) => {
            console.log("Error fetching data:", error);
            setLoading(false);
            if (error.response?.data?.paymentIssue === true) {
              console.log('Payment issue detected, redirecting to settings...');
              navigate('/settings'); // Ensure you're using string URL correctly
            }
          });
      }, []);
      


    // const Question = [
    //     {
    //         id: 1,
    //         question: "What's the most adventurous thing you've ever done?"
    //     },
    //     {
    //         id: 2,
    //         question: "What's your favorite book/movie/song and why?"
    //     },
    //     {
    //         id: 3,
    //         question: "What's one thing on your bucket list?"
    //     },
    //     {
    //         id: 4,
    //         question: "What's the most interesting dream you've ever had?"
    //     },
    //     {
    //         id: 5,
    //         question: "If you could live in any time period, past or future, when would it be?"
    //     }
    // ];




    if (loading) {
        return (
            <div className='Analytics-main-container'>
            <div className='Analytics-inner-container' style={{display:"flex"}}>
            <div className="loading-spinner">
            </div>
            </div>
            </div>
        );
    }

    return (
        <div className='Analytics-main-container'>
            <div className='Analytics-inner-container'>
                <h5>Analytics</h5>
                <div className='Analytics-stats-container'>
                    {AnalyticsData.map((item, index) => (
                        <div className='Analytics-stats-box' key={index}>
                            <h6>{item.h6}</h6>
                            <span className='progresss-bar flex justify-between items-end'>
                                <div className='percentage' style={{ width: 70, height: 70 }}>
                                    {item.bar === 'true' ? (
                                        <CircularProgressbar
                                            value={item.per}
                                            text={`${item.per % 1 === 0 ? item.per : item.per.toFixed(1)}%`}
                                            strokeWidth={12}
                                            styles={buildStyles({
                                                rotation: 0.25,
                                                // strokeLinecap: 'butt',
                                                textSize: '24px',
                                                pathTransitionDuration: 1,
                                                pathColor: '#A890D3',
                                                textColor: '#2B2B2B',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#3e98c7',
                                                trailWidth: 20,
                                                fill: '#F4F3FC',
                                                // boxShadow: "-0.838px 0.838px 3.354px 0px #A890D3 inset",
                                            })}
                                        />
                                    ) : (
                                        <div className='timePeried-div'>
                                            <h4>Period time</h4>
                                            <p>{totalCallDurationToday} <span>sec</span></p>
                                        </div>
                                    )}
                                </div>
                                <h3>{item.h3}<span>{item.mins}</span></h3>
                            </span>
                        </div>
                    ))}
                </div>

                <h5 className='common-heading'>Commonly Asked Questions</h5>
                <div className='Analytics-askedQuestion-container'>
              


                    {QuestionAnswer && QuestionAnswer.map((item, index) => (
                        <div className='Analytics-askedQuestion-box' key={index}>
                          {/* Question */}
                          <div
                            className='question'
                            onClick={() => handleQuestionClick(index)}
                            style={{ cursor: 'pointer', fontWeight: 'bold' }}
                          >
                            {item.Question}
                          </div>
                
                          {/* Show the answer only if this question is active */}
                          {activeIndex === index && (
                            <div className='answer' style={{ marginTop: '10px' }}>
                              {item.Answer}
                            </div>
                          )}
                        </div>
                      ))}
                </div>

            </div>
        </div>
    );
};

export default Analytics;



