import React, { useEffect, useState } from "react";
import "./FaqsTable.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setTags } from "../../../../../redux/action";
import { useNavigate } from "react-router-dom";

const FaqsTable = () => {
  const disptach = useDispatch()
  const tags_Faqs = useSelector((state) => state.tags_Faqs);
  const know_Id = useSelector((state) => state.know_Id);
  const [data, setData] = useState();

  const navigate = useNavigate()

  useEffect(() => {
    setData(tags_Faqs)
  }, [tags_Faqs]);


  const deletedata = (id) => {


    // console.log(localStorage.getItem('token'));
    // console.log(id, "deleted");
    axios.delete(`${process.env.REACT_APP_API_URL2}/customer/deletedrycleaningFaqs`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: {
          "ID": know_Id,
          "faqsID": id
        }
      },

    ).then((response) => {
      // console.log("Data retrieved:", response);
      disptach(setTags(null))
      fetchData()
    }).catch((error) => {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    })
  }

  const fetchData = async () => {
    // console.log(know_Id, "know_Id");
    await axios.post(
      `${process.env.REACT_APP_API_URL2}/customer/getAllKnowledges`,
      {
        AgentID: know_Id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then(response => {

      // console.log("data id given ", response.data);
      setData(response.data);
    }).catch((error) => {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    });
    // console.log("fetchData", data);
  }; // Call fetchData function

  useEffect(()=>{
    // console.log("fetchData faqs");
    fetchData()
  },[])

  return (
    <div className="table-div tableSmrt-div relative overflow-x-auto sm:rounded-lg">
      <div className="TabelWidth">
        {Array.isArray(data?.DryCleaningFAQ) &&
          data.DryCleaningFAQ.length > 0 ? (
          <table className="w-full text-sm text-left rtl:text-right">
            <thead className="uppercase">
              <tr className="border-b">
                <th scope="col" className="px-6 py-3">
                  QUestion 
                </th>
                <th scope="col" className="py-3">
                Answer
              </th>
                <th scope="col" className="py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 flex justify-end"></th>
              </tr>
            </thead>
            <tbody>
              {data.DryCleaningFAQ.map((faq, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {faq.Question}
                  </th>
                  <th
                  scope="row"
                  className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {faq.Answer}
                </th>
                  <td className="py-4">
                    <div className="flex gap-4">
                      {/* Assuming you want to display tags */}
                      {faq.Tags.map((tag, tagIndex) => (
                        <span
                          key={tagIndex}
                          className="table-pillbg-white"
                        >
                          {tag.Tag}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <span className="flex justify-end gap-2">
                      <img
                        className="cursor-pointer"
                        style={{ width: "20px", height: "20px" }}
                        src="../../../../assets/icons/material-symbols_delete-outline.png"
                        alt=""
                        onClick={() => deletedata(faq._id)}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No Dry Cleaning FAQs available</p>
        )}
      </div>
    </div>
  );
};

export default FaqsTable;
