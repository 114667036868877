import React, { useEffect, useState } from "react";
import "./Table.scss";
import { Pagination } from "antd";
import axios from "axios";
import { refresh } from "aos";
import { Modal ,Tooltip} from "antd";
import { useNavigate } from "react-router-dom";

const Table = ({ tabledata, onDelete }) => {
  // console.log(tabledata);
  const navigate = useNavigate();
  const [ID, setID] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const totalRows = 5; // Total number of rows per page

  const data =
    tabledata && Array.isArray(tabledata)
      ? tabledata.map((item, index) => ({
          id: index + 1, // Generate sequential IDs for simplicity
          name: item.Name,
          agents: item.AgentID.length.toString(),
          date: new Date(item.createdAt).toLocaleString(),
          id: item._id,
        }))
      : [];

  const handleChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  // Calculate total pages based on total items and rows per page
  const totalItems = data?.length;
  const totalPages = Math.ceil(totalItems / totalRows);

  // Calculate start and end indexes for current page
  const startIndex = (currentPage - 1) * totalRows;
  const endIndex = Math.min(startIndex + totalRows, totalItems);

  // Slice data for current page
  const currentPageData = data?.slice(startIndex, endIndex);

  const deletedata = async (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/Deleteagentflow/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        onDelete();
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  const open = (ID) => {
    setIsModalOpenDelete(true);
    setID(ID);
  };
  const closeModal = () => {
    setIsModalOpenDelete(false);
  };

  const confirmDelete = (id) => {
    deletedata(id);
    setIsModalOpenDelete(false);
  };

  return (
    <>
      <div className="table-div relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="uppercase">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Agents
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3 flex justify-end">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPageData?.map((item) => (
              <tr
                key={item.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.name}
                </th>

                <td className="px-6 py-4">{item.agents}</td>
                <td className="px-6 py-4">{item.date}</td>
                <td className="px-6 py-4">
                  <span className="flex justify-end gap-2">
                  <Tooltip title="Edit">
                    <img
                      className="cursor-pointer"
                      src="../../../../assets/icons/tabler_edit.png"
                      alt=""
                      onClick={()=> navigate(`/dashboard/flowchart/${item.id}`)}
                    />
                    </Tooltip>
                    <Tooltip title="Delete">
                    <img
                      className="cursor-pointer"
                      src="../../../../assets/icons/material-symbols_delete-outline.png"
                      alt=""
                      onClick={() => delete open(item.id)}
                    />
                    </Tooltip>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          open={isModalOpenDelete}
          onCancel={closeModal}
          centered
          footer={null}
        >
          <div className="delete-modal v-center flex-column ">
            <p className="text-center">
              Are you sure you want to delete this item?
            </p>
            <div className="d-flex gap-4 mt-5">
              <button
                onClick={closeModal}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  background: "#ccc",
                  color: "#000",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => confirmDelete(ID)}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  background: "#a890d3",
                  color: "#fff",
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      </div>

      {/* Pagination */}
      <Pagination
        current={currentPage}
        onChange={handleChange}
        total={totalPages} // Total number of pages
        pageSize={1} // Display only 1 page at a time in the pagination
        className="mt-5"
      />
    </>
  );
};

export default Table;
