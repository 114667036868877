import React, { useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, getStraightPath } from 'reactflow';

// CustomEdge component to render a custom edge with editable label
const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  label,
  data
}) => {
  const [isEditing, setIsEditing] = useState(false); // State to manage label editing

  // Generate the straight path for the edge
  const edgePath = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  // Log the edge properties for debugging
  // console.log(sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style, label, data, "edge");

  // Handle label change
  const handleLabelChange = (e) => {
    data.onLabelChange(id, e.target.value); // Call the onLabelChange function passed via data
  };

  // Handle click on the label to start editing
  const handleClick = () => {
    setIsEditing(true); // Set editing state to true
  };

  // Handle blur event to stop editing
  const handleBlur = () => {
    setIsEditing(false); // Set editing state to false
  };

  return (
    <>
      {/* Render the base edge */}
      <path
      id={id}
      d={`M${sourceX},${sourceY} L${targetX},${targetY}`}
      style={{ ...style, stroke: 'gray' }} // Custom style for the edge
    //   d={edgePath} // Path for the edge
    />
      {/* Render the edge label */}
      <EdgeLabelRenderer>
       
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
