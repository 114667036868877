import React from "react";
import "./TalkSection.scss";
import KeyFeatures from "../KeyFeatures/KeyFeatures";
// import talkImg from ''

const TalkSection = () => {
  return (
    <div className="talk-main-container">
      <div className="talk-centered-container d-flex v-center">
        <div className="talk-left-container">
          <h2>
            Empower Your <strong>Customer </strong>
            <strong>Service</strong> with Cutting-Edge AI
          </h2>
          <p>
            Transform your customer interactions with our state-of-the-art AI
            voice chat application. Designed for businesses of all sizes, our
            platform offers an intuitive, powerful solution to meet your
            customer service needs efficiently.{" "}
          </p>

          <p>
            Experience seamless integration, real-time analytics, and an
            intelligent, user-friendly interface that elevates your customer
            support to the next level. Discover how our key features can benefit
            your business today
          </p>
          <br />
          {/* <ul>
            <li>Intuitive Voice Chat Interface</li>
            <p className="pb-3">
              Our AI-driven interface makes customer interaction smooth and
              natural, ensuring your customers always feel understood and
              valued.
            </p>
         
            <li>Seamless Integration</li>
            <p  className="pb-3">
              With easy integration into your current systems, our application
              streamlines your customer service process, enhancing efficiency
              and satisfaction.
            </p>
         
            <li>Real-Time Analytics</li>
            <p>
              Gain valuable insights into customer interactions with our
              comprehensive analytics, helping you make informed decisions to
              improve service quality continuously.
            </p>
          </ul> */}
        </div>
        <div className="talk-right-container">
          <img src="../../../../../assets/images/talkImg.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default TalkSection;
