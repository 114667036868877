import React from 'react';
import "./functionality.scss";
import img1 from '../../../images/package_31987581.webp'
import img2 from '../../../images/software_125380831.webp'
import img3 from '../../../images/info_31632351.webp'
import img4 from '../../../images/urine_analysis_94671971.webp'
import img5 from '../../../images/price_121732571.webp'
import img6 from '../../../images/mobile_receipt_113384721.webp'

const Functionality = () => {
    return (
        <div className="fuctionalityWrapper">
            <h3>Functionalities</h3>
            <div className="listItem">
                <div className="order">
                    <img src={img1} alt='' />
                    <p>Place Order</p>
                </div>

                <div className="order">
                    <img src={img2} alt='' />
                    <p>Update Account Information</p>
                </div>

                <div className="order">
                    <img src={img3} alt='' />
                    <p>Item and Order Inquiries</p>
                </div>


                <div className="order">
                    <img src={img4} alt='' />
                    <p>Issue Intake</p>
                </div>


                <div className="order">
                    <img src={img5} alt='' />
                    <p>Pricing</p>
                </div>


                <div className="order">
                    <img src={img6} alt='' />
                    <p>Billing and Payment</p>
                </div>
            </div>
        </div>
    )
}

export default Functionality;