import React, { useEffect } from "react";
import "./Pricing.scss";
import Navbar from "../../components/Navbar/Navbar";
import PricingCard from "./PricingCard/PricingCard";
import Footer from "../../components/Footer/Footer";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />

      <div className="pricing-main-container">
        <div className="subscribe-main-container d-flex flex-column">
          <h1>Subscribe us</h1>
          <div className="PricingCard-container">
            <PricingCard
              pill="Lite"
              h5="$7"
              p="Just using this for yourself? Lite is the way to go for the lites platform."
              li1="One person team"
              li2="Exports to files for easy client viewing"
              li3="2TB of data for your account"
              btn="Select Lite"
              pillcolor1="pillcolor1"
            />
            <PricingCard
              pillcolor2="pillcolor2"
              className="pro-card"
              pill="Pro"
              h5="$19"
              p="Just using this for yourself? Lite is the way to go for the lites platform."
              li1="Two person team"
              li2="Exports to files for easy client viewing"
              li3="2TB of data for your account"
              btn="Select Pro"
              bgshadow="bgshadow"
            />
            <PricingCard
              pillcolor3="pillcolor3"
              pill="Team"
              h5="$31"
              p="Just using this for yourself? Lite is the way to go for the lites platform."
              li1="One person team"
              li2="Exports to files for easy client viewing"
              li3="2TB of data for your account"
              btn="Select Pro"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
