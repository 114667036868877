import React, { useEffect, useState } from "react";
import "./DashNavbar.scss";
import { Icon } from "@iconify/react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

const DashNavbar = ({ toggleSidebarLeft }) => {
  const navigate = useNavigate();
const [Data , setData] = useState()
useEffect(() => {
  const fetchData = async () => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL2}/customer/getCustomerById`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await axios.request(config);
      console.log("First Name:", response.data.firstName); // Log the first name
      setData(response.data); // Set the fetched data in state
    } catch (error) {
      console.error("Error fetching data:", error); 
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }// Handle errors
    }
  };

  fetchData();
}, []);
  return (
    <>
      <div className="DashNavbar d-flex space-between v-center gap-2">
        <h4>Dashboard</h4>
        <div className="right-container v-center">
          <div
            className="profile-div v-center gap-4 "
            onClick={() => navigate("/accountsettings")}
          >
            <img
              src="../../../../assets/images/DashboardImages/icons/Group 39488 (1).png"
              alt=""
            />
            <span className="v-center flex-column ">
              <h6>{Data?.firstName || "Fabricare"}</h6>
              {/* <p>New User</p> */}
            </span>
          </div>

          <Icon
            icon="iconamoon:menu-burger-horizontal"
            color="#000"
            className="burger-Icon"
            onClick={(event) => toggleSidebarLeft(event)}
          />
        </div>
      </div>
    </>
  );
};

export default DashNavbar;
