import React from "react";
import "./Fabicare.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DryCleaning from "./DryCleaning/DryCleaning";
import AutomatedResponses from "./AutomatedResponses/AutomatedResponses";
import ProvideFeatures from "./ProvideFeatures/ProvideFeatures";
import WhyFabriCare from "./WhyFabriCare/WhyFabriCare";
import RequestDemo from "../Home/HomeSections/RequestDemo/RequestDemo";
import { NavLink } from "react-router-dom";
import imgFrame from "../../../src/images/esmepics1.webp";
import Video from "../../components/video/video";
import Functionality from "./functionalities/functionality";
import DemoPage from "../Home/HomeSections/demoPage";
import Statistics from "../Home/HomeSections/Statistics/Statistics";
import Enhancing from "../Home/HomeSections/Enhancing/Enhancing";
import ByTeam from "../../components/ByTeam/ByTeam";
import Availability from "../Home/HomeSections/Availability/Availability";
import img from "../../images/image1021.webp";

const Fabicare = () => {
  return (
    <div className="black overflow-x-hidden">
      <Navbar />
      <DryCleaning
        setpadding="setpadding"
        setfont="setfont"
        img={imgFrame}
        h5="Dry Cleaning"
        p="Grow your Dry Cleaning business with AI delivering your goals beyond expectations .Say goodbye to wrinkles in your customer interactions and hello to seamless solutions! Our AI-powered web application is tailor-made for the dry-cleaning industry, bringing a touch of innovation to every crease and fold. "
      />
      {/* <div className="line2"></div> */}
      <div className="effortlessCustomerWrapper">
        <div>
          <h5>Watch a Demo</h5>
          <h3>
            Experience Effortless Customer Interaction Without Lifting a Finger
          </h3>
        </div>
        <Video hide="hide" />
      </div>
      {/* <AutomatedResponses
        img="../../../assets/images/fa0b75a037053e4773f591bf3defdc79.webp"
        h2="Automated Responses"
        p1="We ensure that every potential customer becomes an existing one. Automating responses for every customer, whether they want to schedule an appointment or solve a query."
        p2="By leveraging the power of automation, we are able to engage with customers more effectively, increase customer satisfaction, and ultimately, drive more conversions. Our system is continuously learning and improving, ensuring that our responses are always up-to-date and relevant to our customers' needs."
      />
      <AutomatedResponses
        img="../../../assets/images/9878cf8d42563af16706f06de626c26c.webp"
        h2="Inquiry Analysis"
        p1="Identifying patterns and growing up on experience, our AI services ensure growth at an unprecedented level for both your business and your customer base."
        p2="As your business evolves, our AI services evolve with it, providing you with the agility and insight needed to stay ahead in today's dynamic market. With our AI services, you can unlock new opportunities, streamline operations, and achieve growth at a level that was once considered unattainable."
      />
      <AutomatedResponses
        img="../../../assets/images/dc51a23ba60e3a485a2cf2b262655c7f.webp"
        h2="Task Performance"
        p1="Be it scheduling an appointment for dry cleaning, or asking for technical assistance. Our AI chatbots are advanced enough to assist the customers in their queries."
        p2="Our AI chatbots excel in assisting customers with various needs, from scheduling appointments for dry cleaning to providing technical assistance. With their advanced capabilities, our chatbots ensure that every customer query is addressed promptly and accurately, enhancing the overall customer experience."
      /> */}
      <div className="purpleShade"></div>
      <Functionality />
      <Statistics
        h5="Features"
        text1="Provide consistent"
        text2="customer service"
        text3="everytime"
      />
      <div className="" style={{ backgroundColor: "#fff" }}>
        <Enhancing padding="padding" />
      </div>
      <ByTeam />
      <div className="" style={{ backgroundColor: "#fff" }}>
        <Availability />
      </div>

      {/* <ProvideFeatures /> */}
      {/* <WhyFabriCare /> */}
      {/* <div className="Fabicare-RequestDemo"> */}
      {/* <RequestDemo
          btnshadow="topminusdiv"
          btntxt="Schedule"
          topminusdiv="topminusdiv"
        /> */}
      {/* <DemoPage
          btnshadow="topminusdiv"
          btntxt="Schedule"
          topminusdiv="topminusdiv"
        /> */}
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default Fabicare;
