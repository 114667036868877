import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const ProgressBar = ( {filteredCalls, totalCalls}  ) => {
    const rawPercentage = totalCalls > 0 ? (filteredCalls / totalCalls) * 100 : 0;
    const percentage = rawPercentage > 0 && rawPercentage < 0.001 
        ? "<0.001" 
        : parseFloat(rawPercentage.toFixed(3));

    const AnalyticsData = [
        {
            id: 1,
            h6: 'Average Call Length',
            h3: '07',
            mins: 'mins',
            bar: 'true',
            per: percentage
        },


    ];
    return (
        <div>

            <div className='Analytics-stats-container bar-content'>
                {AnalyticsData.map((item, index) => (
                    <div className='Analytics-stats-box' key={index}>
                        {/* <h6>{item.h6}</h6> */}
                        <span className='progresss-bar flex justify-between items-end'>
                            <div className='percentage' style={{ width: 70, height: 70 }}>
                                {item.bar === 'true' ? (
                                    <CircularProgressbar
                                        value={percentage}
                                        text={`${item.per % 1 === 0 ? item.per : item.per.toFixed(1)}%`}
                                        strokeWidth={12}
                                        styles={buildStyles({
                                            rotation: 0.25,
                                            // strokeLinecap: 'butt',
                                            textSize: '24px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: '#A890D3',
                                            textColor: '#2B2B2B',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#3e98c7',
                                            trailWidth: 20,
                                            fill: '#F4F3FC',
                                            // boxShadow: "-0.838px 0.838px 3.354px 0px #A890D3 inset",
                                        })}
                                    />
                                ) : (
                                    <div className='timePeried-div'>
                                        <h4>Period time</h4>
                                        <p>05 <span>mins</span></p>
                                    </div>
                                )}
                            </div>
                            {/* <h3>{item.h3}<span>{item.mins}</span></h3> */}
                        </span>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default ProgressBar