import React, { useState } from "react";
import "./RequestDemo.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

const RequestDemo = ({
  topminusdiv,
  btntxt,
  topminus,
  shadow,
  marginminus,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    company: "",
    title: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    fname: "",
    lname: "",
    company: "",
    title: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Check for empty fields
    // const emptyFields = Object.keys(formData).filter(
    //   (key) => !formData[key].trim()
    // );
    // if (emptyFields.length > 0) {
    //   toast.error("Please fill in all required fields");
    //   return;
    // }

    // const requiredFields = ["fname", "lname", "phone", "email", "description"];
    // const emptyFields = requiredFields.filter((field) => !formData[field].trim());
    // if (emptyFields.length > 0) {
    //   toast.error("Please fill in all required fields");
    //   return;
    // }

    // Check for empty fields
    const requiredFields = [
      "fname",
      "lname",
      "company",
      "title",
      "email",
      "phone",
      "message",
    ];
    const emptyFields = requiredFields.filter(
      (field) => !formData[field].trim()
    );
    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields");
      return;
    }

    if (formData.phone.length !== 11) {
      newErrors.phone = "Number must have 11 digits in it";
    }
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (Object.keys(newErrors).length > 0) {
      // Display error toasts and update state for highlighting
      Object.keys(newErrors).forEach((key) => {
        toast.error(newErrors[key]);
      });
      setFormErrors(newErrors);
      return;
    }
    toast.success("Form submitted successfully!");

    // Reset form data
    setFormData({
      fname: "",
      lname: "",
      company: "",
      title: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className="requestDemo-main-container">
      <div
        className={`requestDemo-centered-container ${topminusdiv} ${topminus} ${shadow} ${marginminus}`}
      >
        {/* <h2>Request a Demo</h2> */}
        <div className="requestDemo-flex-container">
          <div className="requestDemo-flex-box-left">
            <h5>Discover the Power of AI in Customer Support</h5>
            <button
              className="theme-btn-light d-flex gap-2 mt-4"
              style={{
                color: "#fff",
                backgroundColor: "#7D5BA6",
                fontSize: "18px",
              }}
              onClick={() => navigate("/demo")}
            >
              <Icon
                color="#FFF"
                icon="ph:play-circle-light"
                width={25}
                height={25}
              />
              Request a Demo
            </button>
          </div>
          <div className="requestDemo-flex-box-right">
            <div className="requestDemo-twoInputbox">
              <input
                type="text"
                name="fname"
                placeholder="First Name*"
                value={formData.fname}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lname"
                placeholder="Last Name*"
                value={formData.lname}
                onChange={handleChange}
              />
            </div>
            <div className="requestDemo-twoInputbox">
              <input
                type="text"
                name="company"
                placeholder="Company*"
                value={formData.company}
                onChange={handleChange}
              />
              <input
                type="text"
                name="title"
                placeholder="Job Title*"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Work Email*"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="number"
              name="phone"
              placeholder="Phone Number*"
              value={formData.phone}
              onChange={handleChange}
            />
            <textarea
              placeholder="Your message..."
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button onClick={handleSubmit} className="send-request-btn">
              {" "}
              {btntxt}
            </button>
          </div>

          <ToastContainer />
        </div>
        <div className="whatSeeHeading">
          <h6>What You'll See</h6>
          <p className="purplePara mt-4">
            Our interactive 30 minute demo offers a firsthand look at how our
            AI-driven platform can transform your customer service experience.
            From seamless integration to real-time problem solving, see how
            we're setting new standards in customer satisfaction.
          </p>
        </div>

        <div className="responseDiv">
          <strong>Instantaneous Response: </strong>
          <span className="purplePara">
            Witness the speed at which our AI handles inquiries, reducing wait
            times to mere seconds.
          </span>
        </div>

        <div className="responseDiv">
          <strong>Intelligent Interaction: </strong>
          <span className="purplePara">
            Observe the AI's ability to understand and respond to complex
            queries with precision.
          </span>
        </div>

        <div className="responseDiv">
          <strong>Seamless Integration: </strong>
          <span className="purplePara">
            See how our platform integrates with existing systems to provide a
            holistic service experience.
          </span>
        </div>

        <div className="responseDiv">
          <strong>Real-Time Analytics: </strong>
          <span className="purplePara">
            Get a glimpse into the future with advanced analytics that track
            performance and customer satisfaction in real-time.
          </span>
        </div>

        <div className="responseDiv">
          <strong>Natural Conversations: </strong>
          <span className="purplePara">
            Experience the sophistication of our AI's conversational
            capabilities, making interactions as natural and engaging as talking
            to a human.
          </span>
        </div>
      </div>
    </div>
  );
};

export default RequestDemo;
