import React from "react";
import "./ConnectAI.scss";
import Video from "../../../../components/video/video";

const ConnectAI = () => {
  return (
    <div className="connect-ai-container">
      <div className="connect-container-content h-center flex-column">
        {/* <div className="connect-customer-container  v-center flex-column h-center w-100">
          <button className="features-btn">Features</button>
          <h1>
            Connect with your customers with <span>Ai</span>
          </h1>
        </div> */}
        <div className="video-conatiner-content v-center justify-between">
          <Video />
          {/* <div className="right-section d-flex v-center gap-5">
            <img
              src="../../../assets/images/dotted-lines.png"
              alt="dotted-lines"
              className="dotted-lines"
            />
            <div className="circular-div-list d-flex h-center flex-column">
              <span>Automated</span>
              <span>Smart</span>
              <span>Adaptive</span>
              <span>Insights</span>
              <span>Customizable</span>
              <span>AI Powered</span>
              <span>Integrable</span>
            </div>
          </div> */}
          <div className="text-conatiner-content">
            <h5>Simplify with Automation</h5>
            <h3>Delegate the ordinary, focus on the priority</h3>
            <button className="theme-btn-light mt-8" style={{ color: '#fff', backgroundColor:'#7D5BA6' }}>Book a Demo</button>
          </div>
        </div>
        {/* <div className="bottom-content v-center flex-column h-center w-100 gap-80">
          <p>
            Provide personalized real-time support and invite your customers to
            chat based on the time spent on your site, location, and visited
            pages.
          </p>
          <div className="bottom-btns d-flex gap-80">
            <div className="light-btn">Advanced Ai</div>
            <div className="light-btn">Multi-purpose communication</div>
            <div className="light-btn">Advanced security</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ConnectAI;
