import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Logsdetailtable from "./logstables/logsdetailtable";
import './logs.scss'

const LogsDetails = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="logs-details-container ">
        <div className="calls-logs  d-flex flex-column ">
          <h5 className="heading-5 text-center">Logs Details</h5>
          <Logsdetailtable />
          <button
            className="getback-btn"
            onClick={() => {
              navigate("/callLogs");
            }}
          >
            Get Back
          </button>
        </div>
      </div>
    </>
  );
};

export default LogsDetails;
