import React from "react";
import "./Availability.scss";
import { useNavigate } from "react-router-dom";

const Availability = ({ removepadding }) => {
  const navigate = useNavigate();
  return (
    <div className={`Availability-container ${removepadding} `}>
      <h2>Request a Demo</h2>
      <h6>Unlock 24/7 Availability</h6>
      <h5>Transform Waiting into Resolution with Our AI</h5>
      <div className="flex justify-center">
        <button
          className="theme-btn-light"
          style={{
            color: "#fff",
            backgroundColor: "#7D5BA6",
            fontSize: "18px",
          }}
          onClick={() => navigate("/demo")}
        >
          Request a Demo
        </button>
      </div>
    </div>
  );
};

export default Availability;
