import React from "react";
import "./platform.scss";
import PlatformOverview from "./platformOverview";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { NavLink } from "react-router-dom";
import DryCleaning from "../Fabicare/DryCleaning/DryCleaning";
import img from "../../images/cbb65fb7fc5f1d215d060e812c861bd2.png";
import Enhancing from "../Home/HomeSections/Enhancing/Enhancing";
import ByTeam from "../../components/ByTeam/ByTeam";
import Availability from "../Home/HomeSections/Availability/Availability";

const Platform = () => {
  return (
    <div className="" style={{ backgroundColor: "#000" }}>
      <Navbar />
      <DryCleaning
        setpadding="setpadding"
        img={img}
        h5="Platform Overview"
        h3="Elevate Your Customer Support Experience"
        p="Learn how our cutting-edge automation technology can elevate your businesss customer service capabilities."
      />
      <div style={{ backgroundColor: "#fff" }}>
        <Enhancing padding="padding" />
      </div>
      <ByTeam />
      <div style={{ backgroundColor: "#fff" }}>
        <Availability />
      </div>
      {/* <PlatformOverview /> */}
      <Footer />
    </div>
  );
};

export default Platform;
