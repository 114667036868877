import React, { useState, useEffect } from "react";
import "./Twilio.scss";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SetAgentkeys } from "../../../redux/action";
import { Dropdown, Space, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Twilio = () => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("Select agent to chat");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2((prevState) => !prevState);
  };


  const handleMenuClick = (e) => {
    setSelectedValue(e.key);
  };

  const handleDropdownVisibleChange = (flag) => {
    setDropdownOpen(flag);
  };

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key="Agent 1">Agent 1</Menu.Item>
  //     <Menu.Item key="Agent 2">Agent 2</Menu.Item>
  //     <Menu.Item key="Agent 3">Agent 3</Menu.Item>
  //     <Menu.Item key="Agent 4">Agent 4</Menu.Item>
  //   </Menu>
  // );

  const [SSID, setSSID] = useState();
  const [Authtoken, setAuthToken] = useState();
  //Redux
  const LLM = useSelector((state) => state.Agentkeys);
  const disptach = useDispatch();

  const handleSSID = (event) => {
    setSSID(event.target.value);
  };

  const handleAuthtoken = (event) => {
    setAuthToken(event.target.value);
  };

  useEffect(() => {
    if(!LLM)
    {
      navigate("/Integration")
    }
    setSSID(LLM?.accountSID);
    setAuthToken(LLM?.authtoken);
  }, []);

  useEffect(() => {}, [Authtoken, SSID]);

  const handleSubmit = async () => {
    
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
        {
          accountSID: SSID,
          authtoken: Authtoken,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("data id given ", response.data);
        disptach(SetAgentkeys(response.data.Response));
        console.log("data", response.data);
        navigate("/Integration/ElevenLabs");
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  return (
    <div className="Twilio-main-container">
      <h5>Twilio</h5>
      <div className="mt-5">
        <label>Default SID</label>
        <div className="twillo-input-box">
        <input
        type={showPassword ? "text" : "password"}
        placeholder="•••••••••••••••••••••••"
        className="input-dash"
        alt=""
        value={SSID}
        onChange={handleSSID}
        />
        
           {
             showPassword ?
             <Icon  icon="mdi:eye-off" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
             :
             <Icon icon="mdi:eye" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
             }
             </div>
      </div>
      
      
      <div className="mt-5">
        <label>Default Auth Token</label>
        <div className="twillo-input-box">
        <input
        type={showPassword2 ? "text" : "password"}
        placeholder="•••••••••••••••••••••••"
        className="input-dash"
          alt=""
          value={Authtoken}
          onchange={handleAuthtoken}
        />
    
        
           {
             showPassword ?
             <Icon  icon="mdi:eye-off" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility2} />
             :
             <Icon icon="mdi:eye" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility2} />
             }
             </div>
      </div>

      <div className="flex justify-end mt-5">
        <button
          className="theme-btn-light darkerrrr"
          onClick={() => handleSubmit()}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default Twilio;
