import React from 'react'
import LiveChatHero from './LiveChatHero/LiveChatHero'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import './LiveChatPlatform.scss'
import LiveChatBenefits from './LiveChatBenefits/LiveChatBenefits'

const LiveChatPlatform = () => {
    return (
        <div className='LiveChatPlatform-main-container'>
            <Navbar />
            <LiveChatHero />
            <div className='line2'></div>
            <LiveChatBenefits />
            <Footer />
        </div>
    )
}

export default LiveChatPlatform