
import React, { useEffect, useState } from "react";
import "./FetchVoice.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const FetchVoice = ({ data,selectedVoice,phonenumber }) => {

  const navigate = useNavigate()

  // REDUX state
  const agentId = useSelector((state) => state.agentId);

  const fetchData = async (data) => {
    const id = localStorage.getItem("agentID");
    // console.log("Fetching voice", agentId, id);
    if (!agentId && !id) {
      return toast.warning("Create an agent first");
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/UpdateTheLiveTransfer`,
        {
          agentID: agentId || id,
          audioId: data,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log("data id given ", response.data);
        toast.success("Voice selected successfully!",{toastId:1});
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to select voice. Please try again.",{toastId:2});
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
      console.log("Phone Number:", phonenumber);
      localStorage.setItem("phonenumberlive", phonenumber);
      window.location.reload();
  };


  console.log("selected",selectedVoice,"voiceID", data.voice_id);


  return (
    <div className="fetchVoices-box"  style={{ backgroundColor: selectedVoice === data.voice_id ? "#a890d3" : "" }}>
      <div className="fetchVoices-username-box flex justify-between pb-2">
        <h5>{data.name}</h5>
        <p>{data.labels.description}</p>
      </div>
      <div className="fetchVoices-userInfo-box flex justify-between pb-2">
        <span>
          <h6>
            <strong>Use Case:</strong> {data.labels["use case"]}
          </h6>
          <h6>
            <strong>Age:</strong> {data.labels.age}
          </h6>
        </span>
        <span>
          <h6>
            <strong>Accent:</strong> {data.labels.accent}
          </h6>
          <h6>
            <strong>Gender:</strong> {data.labels.gender}
          </h6>
        </span>
      </div>

      <audio controls autoplay>
        <source src="audio.ogg" type="audio/ogg" />
        <source src={data.preview_url} type="audio/mpeg" />
      </audio>

      <div className="fetchVoices-select-box flex justify-between items-end pt-4">
        <span>
          <h6>{data.category}</h6>
          <p>by EtevenLcbs</p>
        </span>
        <button
        className="theme-btn-light"
        style={{ backgroundColor: selectedVoice === data.voice_id ? "white" : "" }}
        onClick={() => fetchData(data.voice_id)}
        disabled={selectedVoice === data.voice_id}
      >
        {selectedVoice === data.voice_id ? "Selected" : "Select"}
      </button>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default FetchVoice;
