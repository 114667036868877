import React from "react";
import { Icon } from "@iconify/react";
import { Outlet, useLocation } from "react-router-dom";
import "./logs.scss";

const Logs = () => {
  return (
    <>
      <div className="logs-main-container d-flex flex-column">
        <div className="logs-inner d-flex flex-column">
          <div className="top-bar v-center">
            <img
              src=".../../../assets/icons/agentsicon.webp"
              alt="agentsIcon"
              className="mx-2"
            />
            <h5 className="heading-5">Agent</h5>
            <Icon
              color="#343434"
              width={25}
              height={25}
              icon="solar:alt-arrow-right-line-duotone"
              className="mx-3"
            />
            <h5 className="light-color ">AI Sale Assistant</h5>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Logs;
