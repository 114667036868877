
import React, { useState, useEffect, useMemo } from "react";
import "./DashboardMain.scss";
import { useNavigate } from "react-router-dom";
import LinkPill from "../components/LinkPill/LinkPill";
import DashStatsCard from "../components/DashStatsCard/DashStatsCard";
import Graph1 from "../components/Graph1.js/Graph1";
import ProgressBar from "./progressBar";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DashboardMain = () => {
  const navigate = useNavigate();

  // Set initial dates to the last 30 days
  const initialEndDate = new Date();
  const initialStartDate = new Date();
  initialStartDate.setDate(initialEndDate.getDate() - 30);

  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("all"); // Default to "all"
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transferCall, setTransferCall] = useState([]);
  const [chats, setChats] = useState([]);
  const [startDate, setStartDate] = useState(initialStartDate); // Last 30 days by default
  const [endDate, setEndDate] = useState(initialEndDate); // Current date by default
  const [selectedDateRange, setSelectedDateRange] = useState(30); // Default selected value

  const handleAgentSelection = (agent) => {
    setSelectedAgent(agent._id === "all" ? "all" : agent); // If "all" is selected, set to "all"
  };

  const fetchData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const [
        callsResponse,
        transferCallsResponse,
        chatsResponse,
        agentsResponse,
      ] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URL2}/customer/TwilloCalllogs`,
          config
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL2}/customer/Gettransfercalls`,
          config
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL2}/customer/GetChats`,
          config
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL2}/customer/getAllAgent`,
          config
        ),
      ]);

      // console.log("Calls Response: ", callsResponse.data);
      // console.log("Transfer Calls Response: ", transferCallsResponse.data);
      console.log("Chats Response: ", chatsResponse.data.data);
      // console.log("Agents Response: ", agentsResponse.data);

      setData(callsResponse.data.calls || []);
      setTransferCall(transferCallsResponse.data.data || []);
      setChats(chatsResponse.data.data || []);
      setAgents([{ _id: "all", AgentName: "All" }, ...agentsResponse.data.response || []]); // Add "All" option
      setLoading(false);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isDateInRange = (dateString) => {
    const date = new Date(dateString);
    return date >= startDate && date <= endDate;
  };

  const handleDateRangeChange = (days) => {
    const newEndDate = new Date();
    const newStartDate = new Date();
    newStartDate.setDate(newEndDate.getDate() - days);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setSelectedDateRange(days); // Set the selected date range
  };

  // Count how many agents have chatWidgets
  const chatWidgetsCount = useMemo(() => {
    return agents.filter((agent) => agent.chatWidgets).length;
  }, [agents]);

  // Count how many agents have inboundCall
  const inboundCallCount = useMemo(() => {
    return agents.filter((agent) => agent.inboundCall).length;
  }, [agents]);

  const {
    matchingCalls,
    matchingChats,
    totalActivity,
    filteredTransferCalls,
    totalDuration,
    averageDuration,
    dailyCallsData,
    categories,
  } = useMemo(() => {
    const filteredData = data.filter(
      (call) => call.startTime && isDateInRange(call.startTime)
    );



    const filteredChats = chats.filter(
      (chat) => chat.updated_at && isDateInRange(chat.updated_at)
    );

    console.log(filteredChats)
    // Check if "all" is selected
    if (selectedAgent === "all") {
      const totalActivity = filteredData.length + filteredChats.length;
      const totalDuration = filteredData.reduce(
        (sum, call) => sum + (Number(call.duration) || 0),
        0
      );

      const averageDuration = (
        filteredData.length > 0 && totalDuration > 0
          ? totalDuration / filteredData.length
          : 0
      ).toFixed(2);

      const callsPerDay = filteredData.reduce((acc, call) => {
        const date = call.startTime
          ? call.startTime.split("T")[0]
          : "Unknown Date";
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      const sortedDates = Object.keys(callsPerDay).sort();

      return {
        matchingCalls: filteredData,
        matchingChats: filteredChats,
        totalActivity,
        filteredTransferCalls: transferCall,
        totalDuration,
        averageDuration,
        dailyCallsData: sortedDates.map((date) => callsPerDay[date]),
        categories: sortedDates,
      };
    } else if (
      selectedAgent &&
      Array.isArray(filteredData) &&
      Array.isArray(filteredChats)
    ) {
      const matchingCalls = filteredData.filter(
        (call) => call.from === selectedAgent.phoneNumber
      );
      const matchingChats = filteredChats.filter(
        (chat) => chat.agent_id === selectedAgent._id
      );

      const totalActivity = matchingCalls.length + matchingChats.length;
      const matchingTransferCalls = transferCall.filter(
        (transfer) => transfer.from === selectedAgent.phoneNumber
      );

      const totalDuration = matchingCalls.reduce(
        (sum, call) => sum + (Number(call.duration) || 0),
        0
      );

      const averageDuration = (
        matchingCalls.length > 0 && totalDuration > 0
          ? totalDuration / matchingCalls.length
          : 0
      ).toFixed(2);

      const callsPerDay = matchingCalls.reduce((acc, call) => {
        const date = call.startTime
          ? call.startTime.split("T")[0]
          : "Unknown Date";
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      const sortedDates = Object.keys(callsPerDay).sort();

      return {
        matchingCalls,
        matchingChats,
        totalActivity,
        filteredTransferCalls: matchingTransferCalls,
        totalDuration,
        averageDuration,
        dailyCallsData: sortedDates.map((date) => callsPerDay[date]),
        categories: sortedDates,
      };
    } else {
      return {
        matchingCalls: [],
        matchingChats: [],
        totalActivity: 0,
        filteredTransferCalls: [],
        totalDuration: 0,
        averageDuration: 0,
        dailyCallsData: [],
        categories: [],
      };
    }
  }, [selectedAgent, data, chats, transferCall, startDate, endDate]);


  console.log("--------------------------------",matchingChats)
  if (loading) {
    return (
      <div className="dashboard-main">
        <div className="dashboard-inner-container">
          <div className="dashboard-inner-box">
            <div className="spinner"></div> {/* Add spinner here */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-main">
      <div className="dashboard-inner-container">
        <div className="dashboard-inner-box">
          <div className="dashboardMain-head-box">
            <LinkPill
              img="../../../assets/images/DashboardImages/icons/call.png"
              p="Create Agent"
              link="/dashboard/CreateAgentBrief/AgentInfo"
            />
            <div className="flex gap-4 flexcol">
              <LinkPill
                img="../../../assets/images/DashboardImages/icons/call.png"
                p="Agents"
                arrow={true}
                link="/dashboard"
                selected={true}
                onSelectAgent={handleAgentSelection}
              />
              <select
                className="custom-select"
                value={selectedDateRange} // Set the default selected value to 30 days
                onChange={(e) =>
                  handleDateRangeChange(parseInt(e.target.value))
                }
              >
                <option value="7">Last 7 Days</option>
                <option value="14">Last 14 Days</option>
                <option value="30">Last 30 Days</option>
              </select>
            </div>
          </div>

          <div className="dashboardMain-stats-container">
            <DashStatsCard
              total="Total Activity"
              totalNum={totalActivity || 0}
              callNum={matchingCalls.length || 0}
              chatNum={matchingChats.length || 0}
            />

            {selectedAgent === "all" && (
              <DashStatsCard
                total="Automations"
                totalNum={inboundCallCount + chatWidgetsCount}
                callNum={inboundCallCount}
                chatNum={chatWidgetsCount}
                progressbar={<ProgressBar    filteredCalls={inboundCallCount}
                totalCalls={chatWidgetsCount}/>}
              />
            )}

            <DashStatsCard
              total="Transfers"
              totalNum={filteredTransferCalls.length}
              callNum={matchingCalls.length || 0}
              progressbar={
                <ProgressBar
                  filteredCalls={filteredTransferCalls.length}
                  totalCalls={matchingCalls.length}
                />
              }
            />
            <DashStatsCard
              total="Average Time"
              totalNum={averageDuration || 0}
              callNum={totalDuration || 0}
              span="sec"
            />
          </div>

          <div className="dashboardMain-graph-container">
            <Graph1 dailyCallsData={dailyCallsData} categories={categories} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
