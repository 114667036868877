import React from 'react';
import "./KeyFeatures.scss";
import KeyFeaturesBox from './KeyFeaturesBox/KeyFeaturesBox';

const KeyFeatures = () => {
    const data = [
        {
            key: 1,
            h5: 'Intuitive Voice Chat Interface',
            p: 'Our AI-driven interface makes customer interaction smooth and natural, ensuring your customers always feel understood and valued.',
        },
        {
            key: 2,
            h5: 'Seamless Integration',
            p: 'With easy integration into your current systems, our application streamlines your customer service process, enhancing efficiency and satisfaction.',
        },
        {
            key: 3,
            h5: 'Support Customers at Scale',
            p: 'Gain valuable insights into customer interactions with our comprehensive analytics, helping you make informed decisions to improve service quality continuously.',
        },
    ];

    return (
        <div className='KeyFeatures-container'>
            <div className='KeyFeatures-centered-container'>
                <h4>Key Features</h4>
                <h3>Transform Customer Engagement with Leading AI Technology</h3>
                <div className='KeyFeatures-inner-container'>
                    {data.map(feature => (
                        <KeyFeaturesBox key={feature.key} title={feature.h5} description={feature.p} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default KeyFeatures;
