import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "./Assistants.scss";
import { Modal, Popconfirm, Table, Select, Switch, Tooltip } from "antd";
import axios from "axios";
import Chatbot from "../../components/chatbot/chatbotcopy";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import plusIcon from "@iconify/icons-mdi/plus";
import AssistantsCards from "./AssistantsCards.css";
import EditFAQ from "../components/Table/EditFAQ";

const AssistantCard = ({ Data, Reload, key, selectedValue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [Numbers, setNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [selected, setSelected] = useState();
  const [selectedTags, setSelectedTags] = useState({});
  const navigate = useNavigate();
  const [selectedKnowledgeId, setSelectedKnowledgeId] = useState(null);
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [Edit, setEdit] = useState("");
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [knowID, setKnowID] = useState("");

  const { Option } = Select;
  const location = useLocation();

  useEffect(() => {
    setSelectedKnowledgeId(Data._id);
    getNumbers();
  }, [Data._id]);

  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue]);

  const showModal = () => {
    setIsModalOpen(true);
    fetchKnowledgeData();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (id) => {
    setIsModalOpenDelete(true);
  };

  const handleCloseisModalVisibleEdit = () => {
    setIsModalVisibleEdit(false);
    setIsModalOpen(true);
    fetchKnowledgeData();
  };

  const deleteAgent = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL2}/customer/deleteAgent/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setIsModalOpenDelete(false);
      toast.success("Agent Deleted successfully");
      Reload();
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete");
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');

        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };

  const closeModal = () => {
    setIsModalOpenDelete(false);
  };

  const confirmDelete = (id) => {
    deleteAgent(id);
  };

  const handleChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  const changeActive = async () => {
    const data = JSON.stringify({
      Id: Data._id,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/activateAgent`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
    };

    try {
      await axios.request(config);
      toast.success("changed successfully");
      Reload();
    } catch (error) {
      console.log(error);
      toast.error("Please try again");
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log("Payment issue detected, redirecting to settings...");
        navigate("/settings"); // Ensure you're using string URL correctly
      }
    }
  };

  const getNumbers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/getagentNumbers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setNumbers(response.data.Response);
    } catch (error) {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log("Payment issue detected, redirecting to settings...");
        
        navigate("/settings"); // Ensure you're using string URL correctly
      }
    }
  };

  const onChange = (checked) => {
    setSelected(checked);
    if (checked) {
      changeActive();
    }
  };

  const handleTagChange = (value, knowledgeId) => {
    setSelectedTags((prevTags) => ({ ...prevTags, [knowledgeId]: value }));
  };

  const getUniqueTags = (faqData) => {
    if (!faqData) return [];
    return [
      ...new Set(faqData.flatMap((faq) => faq.Tags?.map((tag) => tag.Tag))),
    ];
  };

  const filterDataByTag = (data, knowledgeId) => {
    if (!data) return [];
    const selectedTag = selectedTags[knowledgeId];
    return selectedTag
      ? data.filter((faq) => faq.Tags?.some((tag) => tag.Tag === selectedTag))
      : data;
  };

  const handleEdit = (record, type, item) => {
    setKnowID(item);
    setEdit({
      ...record,
      type,
    });
    setIsModalOpen(false);
    setIsModalVisibleEdit(true);
  };

  const handleDeleteKnowledegebase = async (type, index, KnowledgeID) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL2}/customer/deletefromArrayKnowledge`,
        {
          id: KnowledgeID,
          arrayField: type,
          index: index,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      fetchKnowledgeData();
    } catch (error) {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log("Payment issue detected, redirecting to settings...");
        navigate("/settings"); // Ensure you're using string URL correctly
      }
    }
  };

  const columnsFaqs = (type, item) => [
    {
      title: "Question",
      dataIndex: "Question",
      key: "Question",
    },
    {
      title: "Answer",
      dataIndex: "Answer",
      key: "Answer",
    },
    {
      title: "Tags",
      dataIndex: "Tags",
      key: "Tags",
      render: (tags) => (
        <>
          {tags?.map((tagObject, index) => (
            <span
              key={index}
              style={{
                display: "inline-block",
                margin: "0 8px 8px 0",
                padding: "5px 10px",
                background: "#f0f0f0",
                borderRadius: "10px",
              }}
            >
              {tagObject.Tag}
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <img
            className="cursor-pointer"
            src="../../../../assets/icons/tabler_edit.png"
            alt=""
            onClick={() => handleEdit(record, type, item)}
          />
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => handleDeleteKnowledegebase(type, record._id, item)}
            okText="Yes"
            cancelText="No"
            overlayClassName="custom-popconfirm"
          >
            <img
              className="cursor-pointer"
              src="../../../../assets/icons/material-symbols_delete-outline.png"
              alt=""
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const fetchKnowledgeData = async () => {
    if (!selectedKnowledgeId) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/findKnowledgeWithMultipleAgents/${selectedKnowledgeId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setKnowledgeData(response.data);
      console.log(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.log(error.message);
       if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
      if (error.response?.data?.paymentIssue === true) {
        console.log("Payment issue detected, redirecting to settings...");
        navigate("/settings"); // Ensure you're using string URL correctly
      }
    }
  };

  return (
    <div key={key}>
      <div className="assistant-card d-flex flex-column">
        <div className="card-top v-center space-between">
          <h5 className="heading-small ">{Data?.AgentName}</h5>
          <div className="v-center gap-2 d-flex flex-column">
            <div className="d-flex">
              <Tooltip title="Delete">
                <Icon
                  color="#FF4242"
                  icon="material-symbols:delete-outline"
                  width={24}
                  height={24}
                  onClick={() => handleDelete(Data?._id)}
                />
              </Tooltip>

              <Tooltip title="Edit">
                <Icon
                  color="#000"
                  icon="tabler:edit"
                  width={24}
                  onClick={() => {
                    navigate(`/updateAgentBrief/AgentInfo?${Data?._id}`);
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <div className="custom-switch">
                <Switch
                  value={selected}
                  onChange={onChange}
                  style={{
                    width: "50px",
                    borderColor: "#a890d3",
                    backgroundColor: selected ? "#a890d3" : "transparent",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <p>{Data?.CompanyBusiness}</p>
        <div
          className="active-users d-flex"
          type="button"
          data-modal-target="popup-modal"
          data-modal-toggle="popup-modal"
        >
          <span onClick={showModal}>Test Agent</span>
        </div>

        {/* -------------Modal-------------- */}
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          centered
          className="chat-agent-modal"
          width="1200px"
        >
          <div className="modal-knowledge-base">
            <div className="knowledge-container">
              {knowledgeData && knowledgeData.length > 0 ? (
                <div className="knowledge-content">
                  {knowledgeData.map((item) => {
                    const uniqueTags = getUniqueTags([
                      ...(item.FAQS || []),
                      ...(item.DryCleaningFAQ || []),
                      ...(item.PDfFAQ || []),
                      ...(item.recordingFAQ || []),
                    ]);

                    return (
                      <div key={item._id} className="knowledge-item">
                        <div>
                          <h2 style={{ fontSize: "25px" }}>{item.Name}</h2>
                        </div>
                        <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                          Filter FAQs by Tag
                        </h3>
                        <Select
                          placeholder="Select a tag to filter FAQs"
                          style={{ width: 200, marginBottom: 16 }}
                          onChange={(value) => handleTagChange(value, item._id)}
                          allowClear
                        >
                          {uniqueTags.map((tag) => (
                            <Option key={tag} value={tag}>
                              {tag}
                            </Option>
                          ))}
                        </Select>
                        {item.FAQS && item.FAQS.length > 0 && (
                          <>
                            <h3
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                            >
                              FAQs
                            </h3>
                            <Table
                              dataSource={filterDataByTag(item.FAQS, item._id)}
                              columns={columnsFaqs("FAQS", item._id)}
                            />
                          </>
                        )}
                        {item.DryCleaningFAQ &&
                          item.DryCleaningFAQ.length > 0 && (
                            <>
                              <h3
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                Dry Cleaning FAQs
                              </h3>
                              <Table
                                dataSource={filterDataByTag(
                                  item.DryCleaningFAQ,
                                  item._id
                                )}
                                columns={columnsFaqs(
                                  "DryCleaningFAQ",
                                  item._id
                                )}
                              />
                            </>
                          )}
                        {item.PDfFAQ && item.PDfFAQ.length > 0 && (
                          <>
                            <h3
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                            >
                              File FAQs
                            </h3>
                            <Table
                              dataSource={filterDataByTag(
                                item.PDfFAQ,
                                item._id
                              )}
                              columns={columnsFaqs("PDfFAQ", item._id)}
                            />
                          </>
                        )}
                        {item.recordingFAQ && item.recordingFAQ.length > 0 && (
                          <>
                            <h3
                              style={{ fontSize: "15px", fontWeight: "bold" }}
                            >
                              Recording FAQs
                            </h3>
                            <Table
                              dataSource={filterDataByTag(
                                item.recordingFAQ,
                                item._id
                              )}
                              columns={columnsFaqs("recordingFAQ", item._id)}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>No data available</p>
              )}
            </div>
            <div className="chatbot-container">
              <Chatbot Agent_id={Data?._id} onclose={handleCancel} />
            </div>
          </div>
        </Modal>

        {/*------------- Edit Modal --------------*/}
        <Modal
          visible={isModalVisibleEdit}
          onCancel={handleCloseisModalVisibleEdit}
          footer={null}
          width={500}
        >
          <EditFAQ
            linkBack={handleCloseisModalVisibleEdit}
            Edit={Edit}
            knowId={knowID}
          />
        </Modal>

        {/* -------------Delete Confirmation Modal----------- */}
        <Modal
          open={isModalOpenDelete}
          onCancel={closeModal}
          centered
          footer={null}
        >
          <div className="delete-modal v-center flex-column ">
            <p className="text-center">Are you sure you want to delete ?</p>
            <div className="d-flex gap-4 mt-5">
              <button
                onClick={closeModal}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  background: "#ccc",
                  color: "#000",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => confirmDelete(Data?._id)}
                style={{
                  padding: "10px 20px",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  background: "#a890d3",
                  color: "#fff",
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AssistantCard;
