import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "./Enhancing.scss";
import EnhanceBox from "./EnhanceBox";
import img from "../../../../images/7933061e5a6db06906640d1ec6e805b0.jpg";
import img1 from "../../../../images/6b92e364cd3d896375a9543213d25cad.jpg";
import img2 from "../../../../images/fd3b90448406ce9878b0f06650e2e85b.png";
import img3 from "../../../../images/56778c96a1d0e963b6ca3fd3696a4079.png";
import img4 from "../../../../images/c2af1155b00ca71fdf6465e031e2276e.jpg";

const Enhancing = ({ padding }) => {
  const data = [
    {
      key: 1,
      img: img,
      h5: "Enhancing conversations",
      h3: "Empower Every Call with Precision and Care",
      p: "In the realm of customer service, your business deserves more than just answers—it demands solutions. At Fabricare Center, we dont just deflect; we delve deep to resolve your customer issues with a blend of sophistication, empathy, and efficiency.",
    },
    {
      key: 2,
      img: img1,
      h5: "Unmatched Savings",
      h3: "Reduce Costs on Labor and Telephone Expenses",
      p: "Our AI-powered voice chat platform is designed not just to streamline operations but to significantly reduce overhead costs. By automating routine inquiries and directing complex issues to the right channels, we ensure your resources are optimized, translating into substantial savings without compromising on service quality.",
    },
    {
      key: 3,
      img: img2,
      h5: "Exceptional Call Resolution Larger",
      h3: "Where AI Meets Empathy",
      p: "With our state-of-the-art AI technology, we guarantee a higher rate of first-call resolutions. Our system understands the nuances of customer queries, ensuring they receive tailored, comprehensive answers. This not only boosts customer satisfaction but also frees up your staff to handle more complex issues, enhancing overall efficiency. Up to 50% of customer service interactions resolved through automation",
    },
    {
      key: 4,
      img: img3,
      h5: "Elevated Customer Satisfaction",
      h3: "The Ultimate Goal",
      p: "At the heart of our mission is the commitment to elevating customer satisfaction. By providing quick, accurate, and empathetic responses to their queries, we ensure a seamless customer experience. Our platforms sophisticated analytics also offer insights into customer preferences and behavior, allowing for continuous improvement and personalization of services.",
    },
    {
      key: 5,
      img: img4,
      h5: "Choose Fabricare Center",
      h3: "Where Innovation Meets Compassion",
      p: "Embrace the future of customer service with Fabricare Center. Our AI-powered solutions offer the perfect blend of technological innovation and human compassion, setting new standards in customer care. Join us in transforming your customer service operations and experience unparalleled satisfaction and savings.",
    },
  ];
  const slider = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const ele = slider.current;
    if (window.innerWidth >= 600 && ele) {
      gsap.registerPlugin(ScrollTrigger);
      const rightSections = gsap.utils.toArray(".service-slider__item");
      const pin = gsap.to(rightSections, {
        yPercent: -100 * (rightSections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ele,
          pin: true,
          invalidateOnRefresh: true,
          start: "center center-=100",
          scrub: 1,
          end: () => "+=" + (slider.current?.offsetHeight || 0),
          markers: false,
        },
      });
      return () => {
        pin.kill();
      };
    }
  }, []);

  return (
    <div className={`roadmap-sec ${padding} `}>
      <div className="service-slider_wrapper" ref={slider}>
        <div className="service-slider d-flex align-items-center">
          <div className="service-slider__item">
            {data.map((item) => (
              <EnhanceBox
                key={item.key}
                h5={item.h5}
                img={item.img}
                h3={item.h3}
                p={item.p}
              />
            ))}
          </div>
        </div>
        <div className="service-slider d-flex align-items-center ">
          <div className="service-slider__item"></div>
        </div>
      </div>
    </div>
  );
};
export default Enhancing;
