import React from 'react';
import Chart from 'react-apexcharts';

const Graph1 = ({ dailyCallsData, categories }) => {
    // Log to verify that data is being received correctly
    console.log("Daily Calls Data:", dailyCallsData);
    console.log("Categories:", categories);

    const options = {
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            categories: categories || [], // Use provided categories or fallback to empty array
            title: {
                text: 'Date'
            }
        },
        yaxis: {
            title: {
                text: 'Number of Calls'
            }
        },
        colors: ['#4B2E83'] // Keep consistent color scheme
    };

    const chartSeries = [
        {
            name: 'Calls Per Day',
            data: dailyCallsData || [], // Use provided data or fallback to empty array
            color: '#4B2E83'
        }
    ];

    return (
        <div>
            <h1>Calls Per Day</h1>
            <Chart options={options} series={chartSeries} type="line" height={350} />
        </div>
    );
};

export default Graph1;
