import React, { useState, useEffect } from "react";
import "./ElevenLabs.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Icon } from "@iconify/react";
import { SetAgentkeys } from "../../../redux/action";
import { useNavigate } from "react-router-dom";
const ElevenLabs = () => {
  const [ElevenLabs, setelevanLab] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const LLM = useSelector((state) => state.Agentkeys?.ElevenLabs);
  const disptach = useDispatch();

  useEffect(() => {
    if(LLM)
    {
      setelevanLab(LLM);
    }else{
      navigate("/Integration")
    }
  },[]);


  const handaleEleven = (event) => {
    setelevanLab(event.target.value);
  };
  const handleSubmit = async () => {
    console.log("clicked submit");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
        {
          ElevenLabs: ElevenLabs,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("data id given ", response.data);
        disptach(SetAgentkeys(response.data.Response));
        console.log("data", response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };
  return (
    <div className="ElevenLabs-main-container">
      <h5>ElevenLabs API Key</h5>
      <p>
        Custom voices are available only on paid plans. To use this feature,
        consider upgrading.
      </p>
    <div className="eleven-input-box " >
    <input
    type={showPassword ? "text" : "password"}
        placeholder="•••••••••••••••••••••••"
    className="input-dash"
    alt=""
    value={ElevenLabs}
    onChange={handaleEleven}
    />
    {
        showPassword ?
        <Icon  icon="mdi:eye-off" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
    :
    <Icon icon="mdi:eye" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
    }
    </div>
    <div className="flex justify-end mt-5">
        <button
          className="theme-btn-light darkerrrr"
          onClick={() => handleSubmit()}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default ElevenLabs;
