import React from 'react'
import './LoaderFaqs.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import FaqsTable from './FaqsTable/FaqsTable';
import { Icon } from '@iconify/react';

const LoaderFaqs = () => {
    return (
        <div className='LoaderFaqs-container'>
            <h5 className='pb-3'>Dry Cleaning FAQ’s</h5>
            <Outlet />
        </div>
    )
}

export default LoaderFaqs