
// import React, { useContext, useEffect, useRef } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { ModalOpen } from "../../../redux/action";
// import "./Modal.scss";
// import { Outlet } from "react-router-dom";
// import { ModelContext } from "../../../context/ModelContext";
// import { setload } from "../../../redux/action";
// // import { Icon } from "@iconify/react"; // Import the Icon component

// const Modal = ({ onClose }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const modalRef = useRef(); // Reference to the modal content
//   const { isOpen } = useContext(ModelContext);


//   console.log("modal",location.pathname)

//   // Effect to handle clicks outside the modal content
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         onClose(); // Call the onClose prop which should handle the closing of the modal
//         dispatch(ModalOpen(false)); // Dispatch Redux action to update state
//         dispatch(setload(null)); // Dispatch Redux action to update state
//         window.location.reload();
//       }
//     };

//     // Add event listener when the modal is open
//     if (isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     }

//     // Cleanup event listener
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen, onClose, dispatch]); // Ensure dependencies are listed correctly

//   const getActiveClass = (path) => {
//     console.log("hi",location.pathname)
//     return location.pathname === path ? "modal-active" : "";
//   };

//   return (
//     <div>
//       {isOpen && (
//         <div className="modal-backdrop" onClick={(e) => e.stopPropagation()}>
//           <div
//             className="modal"
//             ref={modalRef}
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div className="modal-header">
//               <div
//                 className={`modal-header-opt-box ${getActiveClass(
//                   "/dashboard/CreateAgentBrief/KnowledgeLibrary/NameBox"
//                 )}`}
//               >
//                 <span>01</span>
//                 <p>Name</p>
//               </div>
//               <div className="modal-header-opt-box-line"></div>
//               <div
//                 className={`modal-header-opt-box ${getActiveClass(
//                   "/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader"
//                 )}`}
//               >
//                 <span>02</span>
//                 <p>Data Loader</p>
//               </div>
//               <div className="modal-header-opt-box-line mr-3"></div>
//               <div
//                 className={`modal-header-opt-box ml-3 ${getActiveClass(
//                   "/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs"
//                 )}`}
//               >
//                 <span>03</span>
//                 <p>FAQ’s</p>
//               </div>
//             </div>
//             <div className="modal-content dry-background mt-8">
//               {/* Assuming Outlet is used correctly to render nested routes */}
//               <Outlet />
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Modal;



import React, { useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ModalOpen, setload } from "../../../redux/action";
import "./Modal.scss";
import { Outlet } from "react-router-dom";
import { ModelContext } from "../../../context/ModelContext";

const Modal = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const modalRef = useRef(); // Reference to the modal content
  const { isOpen } = useContext(ModelContext);

  console.log("Modal component loaded, path:", location.pathname);
  console.log("Modal open state:", isOpen);

  // Effect to handle clicks outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        console.log("Clicked outside modal, closing modal.");
        onClose(); // Call the onClose prop which should handle the closing of the modal
        dispatch(ModalOpen(false)); // Dispatch Redux action to update state
        dispatch(setload(null)); 
        const AITraining = [
         "/KnowledgeLibrary/NameBox",
        "/KnowledgeLibrary/Dataloader",
        "/KnowledgeLibrary/Loaderfaqs"
        ]
        const knowledge = [
          "/dashboard/CreateAgentBrief/KnowledgeLibrary/NameBox",
         "/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader",
         "/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs"
         ]

        if (AITraining.includes(location.pathname)) {
          console.log("knowledge");
          navigate("/KnowledgeLibrary"); // or another appropriate path
        } else if (knowledge.includes(location.pathname)) {
          console.log("Ai");
          navigate("/dashboard/CreateAgentBrief/KnowledgeLibrary"); // or another appropriate path
        }

   // Dispatch Redux action to update state
        window.location.reload();
      }
    };

    // Add event listener when the modal is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose, dispatch]); // Ensure dependencies are listed correctly

  const getActiveClass = (paths) => {
    const isActive = paths.includes(location.pathname);
    console.log(`Checking active class for paths: ${paths}, active: ${isActive}`);
    return isActive ? "modal-active" : "";
  };

  return (
    <div>
      {isOpen && (
        <div className="modal-backdrop" onClick={(e) => e.stopPropagation()}>
          <div
            className="modal"
            ref={modalRef}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <div
                className={`modal-header-opt-box ${getActiveClass([
                  "/dashboard/CreateAgentBrief/KnowledgeLibrary/NameBox",
                  "/KnowledgeLibrary/NameBox"
                ])}`}
              >
                <span>01</span>
                <p>Name</p>
              </div>
              <div className="modal-header-opt-box-line"></div>
              <div
                className={`modal-header-opt-box ${getActiveClass([
                  "/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader",
                  "/KnowledgeLibrary/Dataloader"
                ])}`}
              >
                <span>02</span>
                <p>Data Loader</p>
              </div>
              <div className="modal-header-opt-box-line mr-3"></div>
              <div
                className={`modal-header-opt-box ml-3 ${getActiveClass([
                  "/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs",
                  "/KnowledgeLibrary/Loaderfaqs"
                ])}`}
              >
                <span>03</span>
                <p>FAQ’s</p>
              </div>
            </div>
            <div className="modal-content dry-background mt-8">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
