import React,{useEffect} from "react";
import "./Integration.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Integration = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  
 


  return (
    <div className='CreateAgentBrief-main-container Integration-main-container'>
      {/* <h5>Integrations</h5> */}
      <div className='integration-tabs-container'>
        <div className="nav-switch">
          <button
            className={pathname === "/Integration" ? "active" : ""}
            onClick={() => { navigate('/Integration') }}
          >
            SMRT
          </button>
          <button
            className={pathname === "/Integration/LLM" ? "active" : ""}
            onClick={() => { navigate('/Integration/LLM') }}
          >
            LLM
          </button>
          <button
            className={pathname === "/Integration/Twilio" ? "active" : ""}
            onClick={() => { navigate('/Integration/Twilio') }}
          >
            Twilio
          </button>

          <button
            className={pathname === "/Integration/ElevenLabs" ? "active" : ""}
            onClick={() => {
              navigate("/Integration/ElevenLabs");
            }}
          >
            ElevenLabs
          </button>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Integration;
