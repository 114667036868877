import React, { useEffect, useState } from "react";
import "./ScriptTabe.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Tooltip } from "antd";

const ScriptTable = ({ Edit, searchTerm }) => {
  const [data, setData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(""); // State to track the selected row
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [ID, setID] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);


  const navigate = useNavigate()

  // Redux state
  let agentId = useSelector((state) => state.agentId);
  if (!agentId) {
    agentId = localStorage.getItem("agentID");
  }

  const handleRowClick = (index, id) => {
    setSelectedIndex(id); // Update the selected index
    setSelectedRow(index);
  };

  const AddAgent = (id) => {
    console.log("AddAgent", selectedIndex, agentId);

    if (!agentId || agentId === null || agentId === undefined) {
      return toast.warning(`Create an agent First`, { toastId: 2 });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/AgentFlowAddAgent`,
          {
            id: id,
            agentID: agentId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          toast.success("Agent flow has been successfully assigned to the agent.", { toastId: 1 });
          fetchData();
        })
        .catch((err) => {
          toast.error("Failed to add agent. Please create an agent first.", {
            toastId: 2,
          });
          console.log(err);
          if (err.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        });
    }
  };

  const fetchData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL2}/customer/getAllagentflow`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data.Response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("i am here ");
    getagentsMutipletofilter();
  });

  const getagentsMutipletofilter = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL2}/customer/findKnowledgeWithMultipleAgentsflow/${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setSelectedRowIndex(response.data.Response);
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  const handleDeleteAgentID = async (id) => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL2}/customer/updateEditAgentFlow`,
          {
            id: id,
            agentID: agentId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          toast.success("Agent flow has been removed successfully!");
          fetchData();
          setIsModalOpenDelete(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response?.data?.paymentIssue === true) {
            console.log('Payment issue detected, redirecting to settings...');
            navigate('/settings'); // Ensure you're using string URL correctly
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteData = async (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL2}/customer/Deleteagentflow/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        fetchData();
        toast.success("Agent flow has been deleted successfully.", { toastId: 1 });
      })
      .catch((error) => {
        toast.error("Failed to delete Agentflow", { toastId: 1 });
        console.log(error);
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
      });
  };

  const open = (ID) => {
    setIsModalOpenDelete(true);
    setID(ID);
  };
  const closeModal = () => {
    setIsModalOpenDelete(false);
  };

  const confirmDelete = (id) => {
    deleteData(id);
    setIsModalOpenDelete(false);
  };
  const containerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: "900px",
    padding: "20px",
    borderRadius: "8px",
    margin: "0 auto",
  };

  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#a890d3",
    color: "black",
    border: "none",
    borderRadius: "500px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = "#00000";
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = "#a890d3";
  };

  return (
    <div>
      <div className="table-div relative overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="uppercase">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Agents
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3 flex justify-end">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                console.log("item.AgentID:", item.AgentID);
                console.log("AgentID:",agentId )
                console.log("agentId:", item.AgentID.find(agent => agent.id === agentId));
                const isSelected = selectedIndex === index || item.AgentID.find(agent => agent.id === agentId);
                return(
                <tr
                  key={index}
                  className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}
                  onClick={() => handleRowClick(index, item._id)}
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    style={{
                      backgroundColor:
                      isSelected ? "#e2e8f0" : "initial",
                    }}
                  >
                    {item.Name}
                  </th>
                  <td
                    className="px-6 py-4"
                    style={{
                      backgroundColor:
                      isSelected? "#e2e8f0" : "initial",
                    }}
                  >
                    {item.AgentID.length}
                  </td>
                  <td
                    className="px-6 py-4"
                    style={{
                      backgroundColor:
                      isSelected? "#e2e8f0" : "initial",
                    }}
                  >
                    {item.updatedAt}
                  </td>
                  <td
                    className="px-6 py-4"
                    style={{
                      backgroundColor:
                      isSelected ? "#e2e8f0" : "initial",
                    }}
                  >
                    <span className="flex justify-end gap-2">
                    {isSelected?(
                      <Tooltip title="Remove to Agent">
                      <button
                          className="cursor-pointer"
                          style={{
                            color: "black",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          on
                          onClick={() => handleDeleteAgentID(item._id)}
                        >
                         -
                        </button>
                        </Tooltip>
                    ):(
                      <Tooltip title="Add to Agent">
                      <button
                      className="cursor-pointer"
                      style={{
                        color: "black",
                        fontSize: "25px",
                        cursor: "pointer",
                        }}
                        on
                        onClick={() => AddAgent(item._id)}
                        >
                        +
                        </button>
                        </Tooltip>
                    ) }
                    <Tooltip title="Edit">
                      <img
                        className="cursor-pointer"
                        src="../../../../assets/icons/tabler_edit.png"
                        alt="Edit"
                        onClick={() => Edit(item._id)}
                      />
                      </Tooltip>
                      <Tooltip title="Delete">
                      <img
                        className="cursor-pointer"
                        src="../../../../assets/icons/material-symbols_delete-outline.png"
                        onClick={(e) => {
                          e.stopPropagation();
                          open(item._id);
                        }}
                        alt="Delete"
                      />
                      </Tooltip>
                    </span>
                  </td>
                </tr>
              )})}
          </tbody>
        </table>
      </div>

      <Modal
        open={isModalOpenDelete}
        onCancel={closeModal}
        centered
        footer={null}
      >
        <div className="delete-modal v-center flex-column ">
          <p className="text-center">
            Are you sure you want to delete ?
          </p>
          <div className="d-flex gap-4 mt-5">
            <button
              onClick={closeModal}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                background: "#ccc",
                color: "#000",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => confirmDelete(ID)}
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                background: "#a890d3",
                color: "#fff",
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default ScriptTable;
