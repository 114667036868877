import React, { useEffect, useRef, useState } from "react";
import { Handle, Position } from "reactflow";

const CustomNode = ({ id, data, isConnectable }) => {
  const textRef = useRef(null);
  const [label, setLabel] = useState(data.label);
  const [nodeWidth, setNodeWidth] = useState(200);
  const [nodeHeight, setNodeHeight] = useState(50);

  const handleChange = (e) => {
    setLabel(e.target.value);
    data.onLabelChange(id, e.target.value);
  };

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, scrollHeight } = textRef.current;
      setNodeWidth(scrollWidth + 20);
      setNodeHeight(scrollHeight + 20);
    }
  }, [label]);

  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid black",
        borderRadius: "5px",
        backgroundColor: "black",
        display: "inline-block",
        width: nodeWidth,
        height: nodeHeight,
        wordWrap: "break-word",
      }}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <input
        ref={textRef}
        type="text"
        value={label}
        onChange={handleChange}
        style={{
          width: "100%",
          border: "none",
          backgroundColor: "transparent",
          textAlign: "center",
          color: "white",
        }}
        placeholder={data.label}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default CustomNode;


