import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./components/Sidebar/Sidebar";
import DashNavbar from "./components/DashNavbar/DashNavbar";
import { Outlet } from "react-router-dom";
import "./Dashboard.scss";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebarLeft = (event) => {
    // console.log("clicked");
    event.stopPropagation();
    setIsSidebarOpen((prev) => !prev);
  };

  /* SideBar Closed Click start */
  const dropdownRefSideBar = useRef(null);
  const handleClickOutsideBar = (event) => {
    if (
      dropdownRefSideBar.current &&
      !dropdownRefSideBar.current.contains(event.target)
    ) {
      setIsSidebarOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideBar);
    return () => {
      document.removeEventListener("click", handleClickOutsideBar);
    };
  }, []);
  /* SideBar Closed Click End */

  return (
    <div className="Dashboard-main-container flex overflow-x-hidden">
      <Sidebar isSidebarOpen={isSidebarOpen} dropdownRefSideBar={dropdownRefSideBar} handleClickOutsideBar={handleClickOutsideBar} />
      <div className={`right_content ${isSidebarOpen}  md:p-0 w-full md:w-[87%]`}>
        <DashNavbar toggleSidebarLeft={toggleSidebarLeft} />
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
