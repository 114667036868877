import React, { useState, useEffect } from "react";
import AssistantCard from "./assistantCard";
import "./Assistants.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
// import Item from "antd/es/list/Item";
const Assistants = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [Numbers, setNumbers] = useState([]);

  const handleReload = async () => {
    console.log("Reloading");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/customer/getAllAgent`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ); 
      setData(response.data.response);
    } catch (error) {
      console.log(error);
      if (error.response?.data?.success === false) {
        // Redirect to login page
        navigate('/login');
      }
      if (error.response?.data?.paymentIssue === true) {
        console.log('Payment issue detected, redirecting to settings...');
        navigate('/settings'); // Ensure you're using string URL correctly
      }
    }
  };
  useState(() => {
    handleReload();
  }, []);

  useEffect(() => {
    console.log(("Reloading data changed...", Data));
  }, [Data]);

  return (
    <>
      <div className="assistant-main-container d-flex flex-column ">
        <div className="assistant-container d-flex flex-column ">
          <div className="flex justify-between items-end flexcol">
            <span>
              <h4 className="heading-4">Agents</h4>
              <p className="para-med mt-1">
                Manage, train and assign datasets for your agents.
              </p>
            </span>
            <button
              className="theme-btn-light gap-2"
              onClick={() => navigate("/dashboard/CreateAgentBrief/AgentInfo")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.77649 3.92676C10.2244 3.92676 10.5875 4.28985 10.5875 4.73775V16.0916C10.5875 16.5395 10.2244 16.9026 9.77649 16.9026C9.3286 16.9026 8.9655 16.5395 8.9655 16.0916V4.73775C8.9655 4.28985 9.3286 3.92676 9.77649 3.92676Z"
                  fill="#111827"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.28857 10.4147C3.28857 9.96678 3.65167 9.60369 4.09956 9.60369H15.4534C15.9013 9.60369 16.2644 9.96678 16.2644 10.4147C16.2644 10.8626 15.9013 11.2257 15.4534 11.2257H4.09956C3.65167 11.2257 3.28857 10.8626 3.28857 10.4147Z"
                  fill="#111827"
                />
              </svg>
              Create New
            </button>
          </div>
          <div className="assistant-card-wrapper d-flex flex-column mt-5">
            <div className="assistant-wrapper d-flex flex-column">
              <p className="para-med">Voice Assistant</p>
              <div className="card-wrapper">
                {Data && Data.length > 0 ? (
                  Data.map((item, index) => (
                    <AssistantCard
                      key={item.id}
                      Data={item}
                      SETNumbers={Numbers}
                      Reload={handleReload}
                      selectedValue={item.ChatOn}
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assistants;
