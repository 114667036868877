import React from 'react'
import "./ByTeam.scss";
import img from '../../images/image992.webp'

const ByTeam = () => {
    return (
        <div className='ByTeam-container'>
            <div className='ByTeam-centered-container'>
                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus neque tincidunt nulla porttitor ultricies. Sed dapibus, elit quis bibendum suscipit, ligula justo ullamcorper ligula, in malesuada ante mauris ut purus. Aliquam ac tortor tincidunt, varius nisl vitae, volutpat risus. Donec imperdiet tortor non erat efficitur, vitae scelerisque diam congue. Curabitur mi sapien, pellentesque eget volutpat eu, feugiat ut ipsum. Etiam scelerisque, justo eu maximus tempus, lectus orci feugiat sapien, dapibus luctus est dolor eget arcu. Vivamus sed quam pharetra, egestas turpis non, laoreet nisl.”</p>
                <div className='ByTeam-img-container'>
                    <img src={img} alt='' />
                    <h5>“The Quote”</h5>
                    <h6>By The quote team</h6>
                </div>
            </div>
        </div>
    )
}

export default ByTeam