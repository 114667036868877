import React, { useEffect } from "react";
import "./LiveChatHero.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const LiveChatHero = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div className="LiveChatHero-main-container">
      <div className="LiveChatHero-text-centered-container">
        <div className="LiveChatHero-left-container">
          <h1>Live Chat Platform</h1>
          <p>
            Instant realtime communication, providing both visual connection and
            immediate answers to customers inquires.
          </p>
        </div>
        <div className="LiveChatHero-right-container">
          <div className="img-wrapper">
            <img
              src="../../../../assets/images/live-hero1.webp"
              alt="heroimg"
              data-aos="zoom-in"
              data-aos-duration="2000"
            />
            <div className="inner-child1">
              <img
                src="../../../../assets/images/live-hero2.png"
                alt="heroimg"
                data-aos="zoom-in"
                data-aos-duration="2000"
                data-aos-delay="1000"
              />
            </div>
            <div className="inner-child2">
              <img
                src="../../../../assets/images/live-hero3.png"
                alt="heroimg"
                data-aos="zoom-in"
                data-aos-duration="2000"
                data-aos-delay="1000"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveChatHero;
