import React from "react";
import "./AIAssist.scss";
import AIAssistCard from "../AIAssistCard/AIAssistCard";
import card1 from "../../../../src/images/aiAgent/agentCard1.webp";
import card2 from "../../../../src/images/aiAgent/agentCard2.webp";
import card3 from "../../../../src/images/aiAgent/agentCard3.webp";
import card4 from "../../../../src/images/aiAgent/agentCard4.webp";
import card5 from "../../../../src/images/aiAgent/agentCard5.webp";
import card6 from "../../../../src/images/aiAgent/agentCard6.webp";

const AIAssist = () => {
  return (
    <div className="aIAssist-main-container">
      <h2>Let’s tell you about Agent Assist</h2>
      <div className="aIAssist-centered-container">
        <AIAssistCard
          ul="true"
          img1={card1}
          img2={card2}
          h3="Workflows and Decision Tree"
          p="Based on the conversation with the customer, the bot"
          li1="Suggest intent-driven answer."
          li2="Triggers appropriate workflows."
          li3="Enables decision tree."
          h3t="Optimize Knowledgebase"
          pt="The bot has Cognitive QnA capabilities - It performs QnA on unstructured KB, past cases, PDF, and website content to resolve user queries in less than 5 seconds."
          li4="Self-learning NLP."
          li5="Powered by Advance NLU, Semantic Search, and Fuzzy Matching."
          li6="Seamless integration with third-party Knowledge-base."
        />
        <AIAssistCard
          ul="false"
          img1={card3}
          img2={card4}
          h3="Get Real-time Suggestions/Nudges"
          p="The Agent Assist tool listens to calls (and monitors chat), understands context and intents, and provides real-time suggestions/nudges when the agent is talk (or chatting) to the customer. The bot is specifically trained for pre-sales, and sales conversations."
          li1=""
          li2=""
          li3=""
          h3t="Real-time User Sentiment Analysis"
          pt="Agent Assist detects user sentiments and guides agents to take appropriate actions - it makes customers feel you care about them and helps you win their trust and loyalty."
          li4=""
          li5=""
          li6=""
        />
        <AIAssistCard
          ul="false"
          img1={card5}
          img2={card6}
          h3="Real-Time Agent Feedback"
          p="The bot ensures that agents are performing optimally. It regularly looks for the scope for improvement and guides agents accordingly."
          li1=""
          li2=""
          li3=""
          h3t="Compliance"
          pt="The bot helps ensure your human agents adhere to rules and regulations and flags any inappropriate behaviors."
          li4=""
          li5=""
          li6=""
        />
      </div>
    </div>
  );
};

export default AIAssist;
