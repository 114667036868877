import React from "react";
import "./Statistics.scss";

const Statistics = ({ h5, text1, text2, text3 }) => {
  return (
    <div className="statistics-main-container">
      <div className="statistics-centered-container">
        <h5>{h5}</h5>
        <h3>{text1}</h3>
        <h3>{text2}</h3>
        <h3>{text3}</h3>
      </div>
      {/* <div className="statistics-centered-container d-flex flex-column">
        <h2>Why Choose Us</h2>
        <h3>Answer Every Call. Delivering Availability That Delivers Results</h3>
        <div className="statistics-card-container">
          <div className="statistics-card-box">
            <img
              src="../../../../../assets/images/conversations_5881308.png"
              alt=""
            />
            <h4>Empower Every Call with Precision and Care</h4>
            <p>
              In the realm of customer service, your business deserves more than
              just answers—it demands solutions. At Fabricare Center, we don't
              just deflect; we delve deep to resolve your customer issues with a
              blend of sophistication, empathy, and efficiency.
            </p>
          </div>
          <div className="statistics-card-box">
            <img
              src="../../../../../assets/images/circular-arrow_12220378 2.png"
              alt=""
            />
            <h4>Unmatched Savings: Efficiency Meets Economy</h4>
            <p>
              Our AI-powered voice chat platform is designed not just to
              streamline operations but to significantly reduce overhead costs.
              By automating routine inquiries and directing complex issues to
              the right channels, we ensure your resources are optimized,
              translating into substantial savings without compromising on
              service quality.
            </p>
          </div>
          <div className="statistics-card-box">
            <img
              src="../../../../../assets/images/card3Img.png"
              alt=""
            />
            <h4>Elevated Customer Satisfaction: The Ultimate Goal</h4>
            <p>
              At the heart of our mission is the commitment to elevating
              customer satisfaction. By providing quick, accurate, and
              empathetic responses to their queries, we ensure a seamless
              customer experience. Our platform's sophisticated analytics also
              offer insights into customer preferences and behavior, allowing
              for continuous improvement and personalization of services.
            </p>
          </div>
          <div className="statistics-card-box">
            <img
              src="../../../../../assets/images/Frame 427318244.png"
              alt=""
            />
            <h4>Exceptional Call Resolution: Where AI Meets Empathy</h4>
            <p>
              With our state-of-the-art AI technology, we guarantee a higher
              rate of first-call resolutions. Our system understands the nuances
              of customer queries, ensuring they receive tailored, comprehensive
              answers. This not only boosts customer satisfaction but also frees
              up your staff to handle more complex issues, enhancing overall
              efficiency.
            </p>
          </div>

          <div className="statistics-card-box">
            <img
              src="../../../../../assets/images/card5Img.png"
              alt=""
            />
            <h4>Choose Fabricare Center: Where Innovation Meets Compassion</h4>
            <p>
              Embrace the future of customer service with Fabricare Center. Our
              AI-powered solutions offer the perfect blend of technological
              innovation and human compassion, setting new standards in customer
              care. Join us in transforming your customer service operations and
              experience unparalleled satisfaction and savings.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Statistics;
