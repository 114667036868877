import React, { useContext } from 'react'
import FaqsTable from './FaqsTable/FaqsTable'
import { Icon } from '@iconify/react'
import { useNavigate,useLocation } from 'react-router-dom';
import { ModelContext } from '../../../../context/ModelContext';

const LoaderFAQ1 = ({ linkBack, linkNext }) => {
    const navigate = useNavigate();
const {setIsOpen} = useContext(ModelContext)
const location = useLocation();
const AITraining = [
    "/KnowledgeLibrary/NameBox",
   "/KnowledgeLibrary/Dataloader",
   "/KnowledgeLibrary/Loaderfaqs"
   ]
   const knowledge = [
     "/dashboard/CreateAgentBrief/KnowledgeLibrary/NameBox",
    "/dashboard/CreateAgentBrief/KnowledgeLibrary/Dataloader",
    "/dashboard/CreateAgentBrief/KnowledgeLibrary/Loaderfaqs"
    ]
    return (
        <>
            <div className='flex justify-between items-center gap-3 mb-4 flexCol'>
                <div className='KnowledgeLibraryTab-search-box'>
                    <input type='text' placeholder='Search' alt='' />
                    <Icon icon="octicon:search-16" className='search-icon' style={{ color: '#868687' }} />
                </div>
                <span className='flex items-center gap-3 widthFull'>
                    <button className='theme-btn-tran' onClick={() => navigate(`${linkNext}`)}>Add FAQ</button> 
                </span>
            </div>
            <FaqsTable />

            <div className='flex items-center gap-2'>
             
                <button className='theme-btn-light' onClick={() => {
                    
                    setIsOpen(false);
                    if (AITraining.includes(location.pathname)) {
                        console.log("knowledge");
                        navigate("/KnowledgeLibrary"); // or another appropriate path
                      } else if (knowledge.includes(location.pathname)) {
                        console.log("Ai");
                        navigate("/dashboard/CreateAgentBrief/KnowledgeLibrary"); // or another appropriate path
                      }
                    window.location.reload();
                    
                }}>Continue</button>
            </div>
        </>
    )
}

export default LoaderFAQ1