import React, { useEffect, useState } from "react";
import "./LLM.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SetAgentkeys } from "../../../redux/action";
import { Icon } from "@iconify/react/dist/iconify.js";
// import img from '../../../../public/assets/icons/tabler_edit.png'
// import img from '../../../../public/assets/icons/Layer_1.png'

const LLM = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [openAI, setOpenAi] = useState("1234");
  //Redux State
  const LLM = useSelector((state) => state.Agentkeys?.openAI);
    const disptach = useDispatch()
    
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  console.log(openAI)

  const handleOpenAi = (event) => {
    setOpenAi(event.target.value);
  };
  
  useEffect(() => {
    if(LLM)
    {
      setOpenAi(LLM);
    }else{
      navigate("/Integration")
    }
  },[]);

  const handleSubmit = async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL2}/customer/agentKeys`,
      {
        openAI: openAI,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((response)=>{
        console.log("data id given ", response.data);
        disptach(SetAgentkeys(response.data.Response));
        console.log("data", response.data);
        navigate("/Integration/Twilio")
    }).catch((error) => {
        console.log(error);
        if (error.response?.data?.success === false) {
          // Redirect to login page
          navigate('/login');
        }
        if (error.response?.data?.paymentIssue === true) {
          console.log('Payment issue detected, redirecting to settings...');
          navigate('/settings'); // Ensure you're using string URL correctly
        }
    });
  };
  return (
    <div className="LLM-main-container">
      <h5>LLM API Key</h5>

      <div className="LLM-inner-container">
        <div className="LLM-openAiHead-container">
          <span className="flex items-center gap-4 ">
            <img src="../../../../assets/icons/Layer_1.png" alt="" />
            Open AI
          </span>
          <span className="flex items-center gap-4">
            <button className="dark-pill">Enabled</button>
            <img
              className="cursor-pointer"
              src="../../../../assets/icons/tabler_edit.png"
              alt=""
            />
          </span>
        </div>

        <div className="flex justify-between items-center mt-5 mb-3">
          <h6>API Key</h6>
          {/* <p>Remove key</p> */}
        </div>

        <div className="LLM-input-box">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="•••••••••••••••••••••••"
            value={openAI}
            onChange={handleOpenAi}
          />
          {
            showPassword ?
          <Icon  icon="mdi:eye-off" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
        :
          <Icon icon="mdi:eye" width="24" height="24"  style={{color: "#A890D3"}}   onClick={togglePasswordVisibility} />
        }
          
        </div>

        <h5>
          Note: Please keep your API token secure, as it aives you access to
          your account and the associated resources. If you believe your token
          has been Compromised, please reset it.
        </h5>
        <button
          className="theme-btn-light"
          onClick={() => {
        handleSubmit()
            // navigate("/Integration/Twilio")
        }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default LLM;
